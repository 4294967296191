import JsonWebTokenService from '../JsonWebTokenService';
import { ISessionToken, JsonWebToken } from '../JsonWebTokenService/types';
import HttpService from '../HttpService';
import { LoginResponse, SignUpProfilePayload } from './types';
import { isAxiosError } from 'axios';
import { HttpStatus } from '../HttpService/HttpStatus';

const login = async (
  email: string,
  password: string,
): Promise<ISessionToken> => {
  try {
    const {
      data: { token: sessionTokenString, user },
    } = await HttpService.post<LoginResponse>(
      'user/login',
      { email, password },
    );
    return { ...JsonWebTokenService.parse<JsonWebToken>(sessionTokenString), user };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === HttpStatus.BAD_REQUEST) {
      throw new Error(error.response.data.message);
    }
    throw error;
  }
};

const signUp = async (
  payload: SignUpProfilePayload,
) => {
  try {
    const {
      data: { token: sessionTokenString, user },
    } = await HttpService.post<LoginResponse>('user/signup', payload);
    return { ...JsonWebTokenService.parse<JsonWebToken>(sessionTokenString), user };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === HttpStatus.BAD_REQUEST) {
      throw error.response.data;
    }
  }
};

const SessionService = {
  login,
  signUp,
};

export default SessionService;
