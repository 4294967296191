import { isAxiosError } from 'axios';
import HttpService from '../HttpService';
import {
  IEvaluateVDPReportRequest,
  IEvaluateVDPReportResponse,
  IGetAllReports, IGetVDPReportsByEventResponse, IGetVDPReportsByIdResponse,
  IVDPReportHighlights, VDPReportStatus,
} from './types';

const getVDPReportsByEvent = async (eventId: string, sessionToken?: string) => {
  try {
    const { data: { VDPReports } } = await HttpService.get<IGetVDPReportsByEventResponse>(
      `report/vdp/eventId/${eventId}`,
      sessionToken,
    );
    return VDPReports;
  } catch (error) {
    throw error;
  }
};

const getVDPReportHighlightsByEvent = async (eventId: string, sessionToken?: string) => {
  try {
    const { data } = await HttpService.get<IVDPReportHighlights>(
      `report/vdp/dashboard/eventId/${eventId}`,
      sessionToken,
    );
    return data;
  } catch (error) {
    throw error;
  }
};

const getVDPReportById = async (eventId: string, vdpReportId: string, sessionToken?: string) => {
  try {
    const { data: { vdpReport } } = await HttpService.get<IGetVDPReportsByIdResponse>(
      `report/vdp/eventId/${eventId}/vdpReportId/${vdpReportId}`,
      sessionToken,
    );
    return vdpReport;
  } catch (error) {
    throw error;
  }
};

const submitReport = async (payload: any, sessionToken?: string) => {
  try {
    const { data } = await HttpService.post<any>(
      'report/vdp/save',
      payload,
      sessionToken,
      undefined,
      { 'content-type': 'multipart/form-data' },
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

const getAllVDPReports = async (
  queryParams: {page: number, limit: number, status: VDPReportStatus},
  sessionToken?: string,
) => {
  try {
    const { data } = await HttpService.get<IGetAllReports>(
      'report/vdp/admin/get-all-reports',
      sessionToken,
      queryParams,
    );
    return data;
  } catch (error) {
    throw error;
  }
};

const evaluateVDPReport = async (payload: IEvaluateVDPReportRequest, sessionToken?: string) => {
  try {
    const { data } = await HttpService.post<IEvaluateVDPReportResponse>(
      'report/vdp/update',
      payload,
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

const VDPReportService = {
  getVDPReportsByEvent,
  getVDPReportHighlightsByEvent,
  getVDPReportById,
  submitReport,
  getAllVDPReports,
  evaluateVDPReport,
};

export default VDPReportService;
