// @ts-nocheck
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import TextField from '../../../../components/TextField';
import SelectBox from '../../../../components/SelectBox';
import Button from '../../../../components/Button';
import DynamicTable from '../../../../components/DynamicTable';
import moment from 'moment';
import viewIcon from '../../../../assets/icons/adminScreen/viewIcon.svg';
import editIcon from '../../../../assets/icons/adminScreen/editIcon.svg';
import deleteIcon from '../../../../assets/icons/adminScreen/deleteIcon.svg';
import ViewUserDetails from './ViewUser';
import DeletePop from '../../CommonPopup/DeleteDataPopup';
import EditUser from '../../CommonPopup/EditUser';
import GenerateInviteCode from './GenerateInviteCode';
import InviteUser from './InviteUser';
import { useUser } from '../../../../hooks/useUser';
import { useAsync, useAsyncRetry } from 'react-use';
import CONSTANTS from '../../../../constants';
import { useEvent } from '../../../../hooks/useEvent';

const UserComponent = () => {
  const [viewUser, setViewUser] = useState(null);
  const [deleteData, setDeleteData] = useState(null);
  const [editUser, setEditUser] = useState(null);
  const [generateInvite, setGenerateInvite] = useState(false);
  const [inviteUser, setInviteUser] = useState(false);
  const [page, setPage] = useState(1);

  const { getAllUsers } = useUser();
  const { getAllEvents } = useEvent();

  const { value: eventsListData } = useAsync(() => getAllEvents(), []);

  const {
    control,
    watch,
    formState: { errors },
  } = useForm();

  const [userName, userEmail, selectEvent] = watch(['userName', 'userEmail', 'selectEvent']);

  const { value: userData, retry } = useAsyncRetry(() => getAllUsers({
    userName, userEmail, eventId: selectEvent?._id, limit: CONSTANTS.PAGE_LIMIT, page,
  }), [userName, userEmail, selectEvent, page]);

  useEffect(() => {
    setPage(1);
  }, [userName, userEmail, selectEvent]);

  const columns = [
    {
      title: 'S. No',
      dataIndex: '_id',
      key: '_id',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'User Name',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: 'Email Id',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Primary Event',
      dataIndex: 'events',
      key: 'events',
      render: (events) => events?.length > 0 && events[0]?.name,
    },
    {
      title: 'Registered On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => moment(createdAt).format('DD MMM, YYYY'),
    },
    {

      title: '',
      dataIndex: '',
      key: '',
      render: (data) => (
        <div className="justify-start items-center gap-4 flex">
          <div
            className="rounded justify-center items-center gap-1.5 flex cursor-pointer"
            onClick={() => { setViewUser(data); }}
          >
            <div className="w-4 h-4 relative">
              <img src={viewIcon} alt="icon" />
            </div>
            <div className="text-sky-600 text-sm font-medium font-['Manrope'] leading-normal">View</div>
          </div>
          <div
            className="justify-center items-center gap-1.5 flex cursor-pointer"
            onClick={() => { setEditUser(data); }}
          >
            <div className="w-4 h-4 relative">
              <img src={editIcon} alt="icon" />
            </div>
            <div className="text-sky-600 text-sm font-medium font-['Manrope'] leading-normal">Edit</div>
          </div>
          <div
            className="justify-start items-center gap-1.5 flex cursor-pointer"
            onClick={() => { setDeleteData(data); }}
          >
            <div className="w-4 h-4 relative">
              <img src={deleteIcon} alt="icon" />
            </div>
            <div className="text-sky-600 text-sm font-medium font-['Manrope'] leading-normal">Delete</div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full h-auto flex-col justify-start items-start gap-8 inline-flex">
      {viewUser && (
        <ViewUserDetails
          viewUser={viewUser}
          setViewUser={setViewUser}
          getUsers={retry}
        />
      )}
      {deleteData && (
        <DeletePop
          deleteData={deleteData}
          setDeleteData={setDeleteData}
          getUsers={retry}
        />
      )}
      {editUser && (
        <EditUser
          setEditUser={setEditUser}
          editUser={editUser}
        />
      )}
      {generateInvite && (
        <GenerateInviteCode
          setGenerateInvite={setGenerateInvite}
        />
      )}
      {inviteUser && (
        <InviteUser
          setInviteUser={setInviteUser}
        />
      )}
      <div className="self-stretch justify-start items-start gap-8 inline-flex">
        <div
          className="grow shrink basis-0 self-stretch p-6 bg-white rounded-lg flex-col
            justify-center items-start gap-4 inline-flex"
        >
          <form autoComplete="off" className="w-full">
            <div className="grid grid-cols-12 gap-6 w-full">
              <div className="col-span-4">
                <TextField
                  name="userName"
                  placeholder="Search by User Name"
                  label="User Name"
                  control={control}
                  errors={errors}
                  customHeight="40px"
                  customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] pb-2"
                />
              </div>
              <div className="col-span-4">
                <TextField
                  name="userEmail"
                  placeholder="Search by User Email"
                  label="User Email"
                  control={control}
                  errors={errors}
                  customHeight="40px"
                  customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] pb-2"
                />
              </div>
              <div className="col-span-4">
                <SelectBox
                  name="selectEvent"
                  placeholder="Event Name"
                  label="Select Event"
                  options={eventsListData?.events || []}
                  control={control}
                  errors={errors}
                  customLabel="name"
                  customValue="name"
                  customHeight="40px"
                  isClearable
                  customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                />
              </div>
            </div>
          </form>
        </div>
        <div className="p-6 bg-white rounded-lg flex-col justify-center items-start gap-3 inline-flex">
          <div
            className="self-stretch text-black text-opacity-60 text-sm font-normal
              font-['Manrope'] tracking-tight"
          >
            Invite User
          </div>
          <div className="self-stretch justify-start items-center gap-4 inline-flex">
            <Button
              customClass=""
              variant="primary-outline"
              onClick={() => { setGenerateInvite(true); }}
            >
              <div className="text-indigo-600 text-base font-bold font-['Manrope']">Generate Invite Code</div>
            </Button>
            <Button
              customClass=""
              variant="primary-outline"
              onClick={() => { setInviteUser(true); }}
            >
              <div className="text-indigo-600 text-base font-bold font-['Manrope']">Add User To Event</div>
            </Button>
          </div>
        </div>
      </div>
      <div className="self-stretch h-auto w-full p-6 bg-white rounded-lg flex-col justify-start items-start flex">
        <DynamicTable
          dataList={userData?.users}
          columns={columns}
          pageSize={CONSTANTS.PAGE_LIMIT}
          totalCount={userData?.totalUser}
          currentPage={page}
          onPageChange={setPage}
        />
      </div>
    </div>
  );
};

export default UserComponent;
