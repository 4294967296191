import { useAuthentication } from '../contexts/AuthContext';
import ChallengeService from '../services/ChallengeService';
import {
  CreateChallengeRequest,
  EditChallengeRequest,
  GetAllChallengesResponse,
  IChallengeSolutionUserChallenge,
  IChallengeWithCategory,
  IEvaluateSolutionRequest,
  IEvaluateSolutionResponse,
  IGetAllSolutionsResponse,
  IOnGoingChallengesWithEventSolution,
  IResetChallengeRequest,
  SolutionStatus,
} from '../services/ChallengeService/types';
import { useCallbackWithLoading } from './useCallbackWithLoading';

interface IUseChallenge {
  getUserSingleChallengeSolution:
    (eventId: string, challengeId: string) => Promise<IOnGoingChallengesWithEventSolution>;
  getUserChallengesByEvent: (eventId: string) => Promise<{
    onGoingChallenges: IOnGoingChallengesWithEventSolution[];
    availableChallenges: IChallengeWithCategory[];
    completedChallenges: IOnGoingChallengesWithEventSolution[];
    expiredChallenges: IOnGoingChallengesWithEventSolution[];
}>;
  setChallengeExpiredStatus: (challengeId: string, solutionId: string) => Promise<{ message: string }>;
  setSolutionHintSeen: (solutionId: string) => Promise<void>;
  startEventChallengeSolution:
    (eventId: string, challengeId: string) => Promise<IChallengeSolutionUserChallenge>;
  submitSolution: (payload: any) => Promise<any>;
  getAllSolutions: (queryParams: {
      page: number;
      limit: number;
      status?: SolutionStatus | undefined;
      eventId?: string | undefined;
      userId?: string;
    }) => Promise<IGetAllSolutionsResponse>;
  getAllChallenges: (queryParams?: {
      page: number;
      limit: number;
      searchTerm?: string;
    }) => Promise<GetAllChallengesResponse>;
  createChallenge: (payload: CreateChallengeRequest) => Promise<{message: string}>;
  editChallenge: (payload: EditChallengeRequest) => Promise<{message: string}>;
  deleteChallenge: (payload: {id: string}) => Promise<{message: string}>;
  evaluateSolution: (payload: IEvaluateSolutionRequest) => Promise<IEvaluateSolutionResponse>;
  resetChallenge: (payload: IResetChallengeRequest) => Promise<{message: string}>;
}

export const useChallenge = (): IUseChallenge => {
  const { sessionToken } = useAuthentication();

  const getUserSingleChallengeSolution = useCallbackWithLoading(async (eventId, challengeId) => {
    const userChallengeSolution = await ChallengeService.getUserSingleChallengeSolution(
      eventId, challengeId, sessionToken?.rawValue,
    );
    return userChallengeSolution;
  }, [sessionToken?.rawValue]);

  const getUserChallengesByEvent = useCallbackWithLoading(async (eventId) => {
    const challengesDistinguished = await ChallengeService.getUserChallengesByEvent(eventId, sessionToken?.rawValue);
    return challengesDistinguished;
  }, [sessionToken?.rawValue]);

  const setChallengeExpiredStatus = useCallbackWithLoading(async (challengeId, solutionId) => {
    const response = await ChallengeService.setChallengeExpiredStatus(
      challengeId, solutionId, sessionToken?.rawValue,
    );
    return response;
  }, [sessionToken?.rawValue]);

  const setSolutionHintSeen = useCallbackWithLoading(async (solutionId) => {
    await ChallengeService.setSolutionHintSeen(
      solutionId, sessionToken?.rawValue,
    );
  }, [sessionToken?.rawValue]);

  const startEventChallengeSolution = useCallbackWithLoading(async (eventId, challengeId) => {
    const userChallengeWithSolution = await ChallengeService.startEventChallengeSolution(
      eventId, challengeId, sessionToken?.rawValue,
    );
    return userChallengeWithSolution;
  }, [sessionToken?.rawValue]);

  const submitSolution = useCallbackWithLoading(async (payload) => {
    const solutionResponse = await ChallengeService.submitSolution(
      payload, sessionToken?.rawValue,
    );
    return solutionResponse;
  }, [sessionToken?.rawValue]);

  const getAllSolutions = useCallbackWithLoading(async (queryParams: {
    page: number;
    limit: number;
    status?: SolutionStatus | undefined;
    eventId?: string | null;
    userId?: string | null;
}) => {
    const solutionResponse = await ChallengeService.getAllSolutions(
      queryParams, sessionToken?.rawValue,
    );
    return solutionResponse;
  }, [sessionToken?.rawValue]);

  const getAllChallenges = useCallbackWithLoading(async (queryParams?: {
      page: number;
      limit: number;
      searchTerm?: string;
    }) => {
    const challengesResponse = await ChallengeService.getAllChallenges(
      queryParams, sessionToken?.rawValue,
    );
    return challengesResponse;
  }, [sessionToken?.rawValue]);

  const createChallenge = useCallbackWithLoading(async (payload: CreateChallengeRequest) => {
    const response = await ChallengeService.createChallenge(payload, sessionToken?.rawValue);
    return response;
  }, [sessionToken?.rawValue]);

  const editChallenge = useCallbackWithLoading(async (payload: EditChallengeRequest) => {
    const response = await ChallengeService.editChallenge(payload, sessionToken?.rawValue);
    return response;
  }, [sessionToken?.rawValue]);

  const deleteChallenge = useCallbackWithLoading(async (payload: { id: string }) => {
    const response = await ChallengeService.deleteChallenge(payload, sessionToken?.rawValue);
    return response;
  }, [sessionToken?.rawValue]);

  const evaluateSolution = useCallbackWithLoading(async (payload: IEvaluateSolutionRequest) => {
    const response = await ChallengeService.evaluateSolution(payload, sessionToken?.rawValue);
    return response;
  }, [sessionToken?.rawValue]);

  const resetChallenge = useCallbackWithLoading(async (payload: IResetChallengeRequest) => {
    const response = await ChallengeService.resetChallenge(payload, sessionToken?.rawValue);
    return response;
  }, [sessionToken?.rawValue]);

  return {
    getUserSingleChallengeSolution,
    getUserChallengesByEvent,
    setChallengeExpiredStatus,
    setSolutionHintSeen,
    startEventChallengeSolution,
    submitSolution,
    getAllSolutions,
    getAllChallenges,
    createChallenge,
    editChallenge,
    deleteChallenge,
    evaluateSolution,
    resetChallenge,
  };
};
