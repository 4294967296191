import { Controller } from 'react-hook-form';

function Checkbox({ ...props }) {
  const {
    name,
    label,
    errors,
    customClass,
    disabled,
    control,
    customlabelclass,
    id,
    customStyle,
    readOnly,
    defaultValue,
  } = props;

  const checkboxCustomStyle = {
    width: '1.15em',
    height: '1.15em',
    border: '0.15em solid gray',
    cursor: disabled && 'not-allowed',
    accentColor: '#3B4DBC',
  };

  const contCustomStyle = {
    display: 'flex',
    alignItems: 'center',
    cursor: disabled && 'not-allowed',
    // paddingBottom: '10px'
  };

  return (
    <>
      <div style={contCustomStyle}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <>
              <input
                {...field}
                {...props}
                style={customStyle || checkboxCustomStyle}
                className={'form-control' || customClass}
                id={id || name}
                name={name}
                type="checkbox"
                readOnly={readOnly}
                checked={field.value}
                defaultValue={defaultValue}
              />
              {label && <span className={customlabelclass || ''} style={{ paddingLeft: '15px' }}>{label}</span>}
            </>
          )}
        />
      </div>
      {errors && errors[name]?.message && (
        <p className="text-left p-[5px]" style={{ color: 'red' }}>{errors[name].message}</p>
      )}
    </>
  );
}

export default Checkbox;
