/* eslint-disable max-len */
// @ts-nocheck
import moment from 'moment';
import chatIcon from '../../../../assets/icons/adminScreen/chatIcon.svg';
import { Pagination, ConfigProvider } from 'antd';
import { useComment } from '../../../../hooks/useComment';
import CONSTANTS from '../../../../constants';
import { useState } from 'react';
import { useAsync } from 'react-use';
import profileIcon from '../../../../assets/icons/dashboardScreen/profileIcon.svg';

const UserComments = () => {
  const [page, setPage] = useState(1);
  const { getAllComments } = useComment();

  const { value: commentsList } = useAsync(() =>
    getAllComments({ page, limit: CONSTANTS.ADMIN_DASHBOARD.COMMENTS_LIMIT, role: 'user' }), [page]);

  const handleCommentDetails = (commentData) => {
    let commentDetails = '';
    // `The ${commentData?.user?.userName} posted a comment saying
    // ${commentData?.commentMessage} on ${commentData?.challenge ? 'challenge' : 'report'}
    if (commentData?.challenge?.title) {
      commentDetails = `${commentData?.challenge?.title} for the ${(commentData?.event?.name)}`;
    } else if (commentData?.vdpReport?.title) {
      commentDetails = `${commentData?.vdpReport?.title} for the ${(commentData?.event?.name)}`;
    } else commentDetails = commentData?.event?.name;
    return commentDetails;
  };

  const onChange = (data) => {
    setPage(data);
  };

  return (
    <div className="w-full h-[426px] p-6 mt-8 bg-white rounded-lg flex-col justify-start items-start gap-6 inline-flex">
      <div className="justify-start items-center gap-2 inline-flex">
        <div className="w-5 h-5 relative">
          <img src={chatIcon} alt="icon" />
        </div>
        <div className="text-indigo-600 text-base font-medium font-['Manrope']">Latest User Comments</div>
      </div>
      <div className="self-stretch h-auto flex-col justify-start items-start gap-4 flex overflow-y-scroll overflow-x-hidden">
        {commentsList?.comments?.map((item, index) => (
          <div key={index} className="self-stretch justify-start items-start gap-2 inline-flex">
            <img alt="" className="w-10 h-10 rounded-full" src={profileIcon} />
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
              <div className="self-stretch h-auto flex-col justify-center items-start gap-2 flex">
                <div
                  className="self-stretch text-black text-base font-semibold font-['Manrope']"
                >
                  {item?.user?.userName}
                </div>
                <div
                  className="self-stretch text-black text-opacity-20 text-base font-normal font-['Manrope']"
                >
                  {item?.commentMessage}
                </div>
              </div>
              <div
                className="self-stretch h-auto pb-2 border-b border-neutral-200 flex-col
                justify-center items-start gap-2 flex"
              >
                <div
                  className="self-stretch text-zinc-700 text-sm font-normal font-['Manrope']"
                >
                  {handleCommentDetails(item)}
                </div>
                <div
                  className="self-stretch text-black text-opacity-20 text-xs font-normal font-['Manrope']"
                >
                  {moment(item?.createdAt).format('hh:mm A, DD MMM YYYY')}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="justify-start items-start gap-0.5 inline-flex">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#4158DB',
              colorText: '#818EDD',
              fontFamily: 'Manrope',
            },
          }}
        >
          <Pagination
            defaultCurrent={1}
            onChange={onChange}
            total={commentsList?.totalComments}
            size="small"
            responsive
            showSizeChanger={false}
            pageSize={CONSTANTS.ADMIN_DASHBOARD.COMMENTS_LIMIT}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default UserComments;
