// @ts-nocheck
/* eslint-disable */
import { useForm } from 'react-hook-form';
import TextField from '../../../components/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '../../../components/Button';
import { useUser } from '../../../hooks/useUser';
import { useAuthentication } from '../../../contexts/AuthContext';
import { notification } from 'antd';

const ChangePasswordForm = () => {

  const { updateUserPassword } = useUser();
  const { sessionToken } = useAuthentication();

  const schema = yup.object({
    oldPassword: yup.string().required('Old Password is required field').nullable(),
    newPassword: yup.string().required('New Password is required field').nullable(),
    confirmPassword: yup.string().required('Password is required field')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data) => {
    try {
      const payload = {
        password: data.newPassword,
        oldPassword: data.oldPassword,
        userName: sessionToken?.user.userName,
        id: sessionToken?.user._id,
      }
      await updateUserPassword(payload);
      notification.success({message: 'Successfully updated your password', duration: 3});
    } catch (error) {
      notification.error({message: error?.message || 'Failed to change password', duration: 3});
    }
  };
 
  return (
    <div className="w-[70%] 2xl:w-[60%] h-auto p-6 bg-white rounded-lg flex-col justify-start items-start gap-6 inline-flex z-10">
      <div className="flex-col justify-start items-start gap-4 flex">
        <div className="w-full opacity-80 text-black text-xl font-bold font-['Manrope'] tracking-tight">Change Password</div>
      </div>
      <form className='h-auto w-full' onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="h-auto w-full flex-col justify-start items-start gap-8 flex">
          <TextField
            name="oldPassword"
            type="password"
            label="Old Password"
            control={control}
            errors={errors}
            customHeight="56px"
            customlabelclass={`!text-zinc-700 !text-base !font-medium !font-['Manrope'] !leading-normal !pb-2`}
          />
          <TextField
            name="newPassword"
            type="password"
            label="New Password"
            control={control}
            errors={errors}
            customHeight="56px"
            customlabelclass={`!text-zinc-700 !text-base !font-medium !font-['Manrope'] !leading-normal !pb-2`}
          />
          <TextField
            name="confirmPassword"
            type="password"
            label="Confirm Password"
            control={control}
            errors={errors}
            customHeight="56px"
            customlabelclass={`!text-zinc-700 !text-base !font-medium !font-['Manrope'] !leading-normal !pb-2`}
          />
          <Button
            type='submit'
            variant='primary'
            customClass='w-[121px] h-[46px]'
          >
            <div className="text-white text-base font-bold font-['Manrope']">
              Update
            </div>
          </Button>
        </div>
      </form>
    </div>
  )
}

export default ChangePasswordForm