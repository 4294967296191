// @ts-nocheck
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import TextField from '../../../components/TextField';
import Button from '../../../components/Button';
import DynamicTable from '../../../components/DynamicTable';
import moment from 'moment';
import editIcon from '../../../assets/icons/adminScreen/editIcon.svg';
import deleteIcon from '../../../assets/icons/adminScreen/deleteIcon.svg';
import cn from 'classnames';
import EventForm from './EventForm';
import { useEvent } from '../../../hooks/useEvent';
import CONSTANTS from '../../../constants';
import { useAsyncRetry } from 'react-use';
import DeleteEventPopup from './DeleteEventPopup';
import DeactivateEventPopup from './DeactivateEventPopup';

const EventComponent = () => {
  const [eventForm, setEventForm] = useState({ openPopup: false, eventFormData: null });
  const [deleteData, setDeleteData] = useState(null);
  const [deactivateData, setDeactivateData] = useState();
  const [page, setPage] = useState(1);
  const { getAllEvents } = useEvent();

  const {
    control,
    watch,
    formState: { errors },
  } = useForm();

  const searchTerm = watch('searchTerm');

  useEffect(() => { setPage(1); }, [searchTerm]);

  const { value: allEvents, retry: refreshEvents } = useAsyncRetry(() =>
    getAllEvents({ page, searchTerm, limit: CONSTANTS.PAGE_LIMIT }), [searchTerm, page]);

  const columns = [
    {
      title: 'S. No',
      dataIndex: '_id',
      key: '_id',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Title',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Students',
      dataIndex: 'users',
      key: 'users',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => (amount ? `$${amount}` : 'Free'),
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => moment(createdAt).format('DD MMM, YYYY'),
    },
    {
      title: 'Status',
      dataIndex: '',
      key: '',
      render: (data) => (
        <div className="justify-start items-center gap-4 flex">
          <div
            className={cn('w-20 h-[27px] px-3 py-1 rounded-[32px] border cursor-pointer',
              'justify-center items-center gap-2.5 inline-flex',
              data?.active ? 'bg-emerald-50 border-emerald-800' : 'bg-red-50 border-red-600',
            )}
            onClick={() => setDeactivateData(data)}
          >
            <div
              className={cn('text-center text-sm font-medium font-[\'Manrope\'] tracking-tight',
                data?.active ? 'text-emerald-800' : 'text-red-600',
              )}
            >
              {data?.active ? 'Active' : 'Inactive'}
            </div>
          </div>
          <div
            className="justify-center items-center gap-1.5 flex cursor-pointer"
            onClick={() => { setEventForm({ eventFormData: data, openPopup: true }); }}
          >
            <div className="w-4 h-4 relative">
              <img src={editIcon} alt="icon" />
            </div>
            <div className="text-sky-600 text-sm font-medium font-['Manrope'] leading-normal">Edit</div>
          </div>
          <div
            className="justify-start items-center gap-1.5 flex cursor-pointer"
            onClick={() => { setDeleteData(data); }}
          >
            <div className="w-4 h-4 relative">
              <img src={deleteIcon} alt="icon" />
            </div>
            <div className="text-sky-600 text-sm font-medium font-['Manrope'] leading-normal">Delete</div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full h-auto flex-col justify-start items-start gap-8 inline-flex p-8">
      {eventForm?.openPopup && (
        <EventForm
          eventForm={eventForm}
          setEventForm={setEventForm}
          refreshEvents={refreshEvents}
        />
      )}
      {deleteData && (
        <DeleteEventPopup
          deleteData={deleteData}
          setDeleteData={setDeleteData}
          refreshEvents={refreshEvents}
        />
      )}
      {deactivateData && (
        <DeactivateEventPopup
          setDeactivateData={setDeactivateData}
          deactivateData={deactivateData}
          refreshEvents={refreshEvents}
        />
      )}
      <div className="self-stretch justify-start items-start gap-8 grid grid-cols-12">
        <div
          className="col-span-8 grow shrink basis-0 self-stretch p-6 bg-white rounded-lg flex-col
            justify-center items-start gap-4 inline-flex"
        >
          <form autoComplete="off" className="w-full">
            <div className="grid grid-cols-12 gap-6 w-full">
              <div className="col-span-12">
                <TextField
                  name="searchTerm"
                  placeholder="Search by Title"
                  label="Title"
                  control={control}
                  errors={errors}
                  customHeight="40px"
                  customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] pb-2"
                  isSearchBtn
                />
              </div>
            </div>
          </form>
        </div>
        <div className="col-span-4 p-6 bg-white rounded-lg flex-col justify-center items-start gap-3 inline-flex">
          <div
            className="self-stretch text-black text-opacity-60 text-sm font-normal
              font-['Manrope'] tracking-tight"
          >
            New Event
          </div>
          <div className="self-stretch justify-start items-center gap-4 inline-flex">
            <Button
              customClass="w-full"
              variant="primary-outline"
              onClick={() => { setEventForm({ eventFormData: null, openPopup: true }); }}
            >
              <div className="text-indigo-600 text-base font-bold font-['Manrope']">Create New Event</div>
            </Button>
          </div>
        </div>
      </div>
      <div className="self-stretch h-auto w-full p-6 bg-white rounded-lg flex-col justify-start items-start flex">
        <DynamicTable
          dataList={allEvents?.events}
          columns={columns}
          pageSize={CONSTANTS.PAGE_LIMIT}
          totalCount={allEvents?.totalEvent}
          onPageChange={setPage}
          currentPage={page}
        />
      </div>
    </div>
  );
};

export default EventComponent;
