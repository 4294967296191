/* eslint-disable max-len */
// @ts-nocheck
import closeIcon from '../../../../../assets/icons/adminScreen/closeIcon.svg';
import { useForm } from 'react-hook-form';
import TextField from '../../../../../components/TextField';
import Button from '../../../../../components/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ModalPopup from '../../../../../components/Modal';
import { useAdmin } from '../../../../../hooks/useAdmin';
import { notification } from 'antd';

const CreateAdmin = (props) => {
  const { setCreateAdmin, getAdmins } = props;
  const { createAdmin } = useAdmin();
  const schema = yup.object({
    userName: yup.string().required('Admin Name is required').nullable(),
    email: yup.string().required('Email Id is required').email('Enter valid email')
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        'Enter valid email',
      )
      .nullable(),
    password: yup.string().required('Password is required')
      .matches(
        /^(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[A-Z]).{6,}$/,
        'Password must contain minimum 6 characters with at least one number, one special character, and an uppercase letter',
      ).nullable(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      await createAdmin(data);
      await getAdmins();
      notification.success({ message: 'Successfully created admin', duration: 3 });
      setCreateAdmin(false);
    } catch (error) {
      notification.error({ message: typeof error === 'string' ? error : 'Failed to create admin', duration: 3 });
    }
  };

  return (
    <ModalPopup
      fullScreen
    >
      <div className="w-full h-screen pt-8 pb-12 bg-purple-50 flex-col justify-start items-start gap-8 inline-flex">
        <div className="self-stretch px-12 justify-between items-center inline-flex">
          <div className="text-indigo-600 text-xl font-semibold font-['Manrope']">Create Admin</div>
          <div
            className="w-8 h-8 relative cursor-pointer"
            onClick={() => setCreateAdmin(false)}
          >
            <img src={closeIcon} alt="icon" />
          </div>
        </div>
        <div className="self-stretch px-12 justify-start items-start gap-[26px] inline-flex">
          <div
            className="w-[800px] self-stretch p-6 bg-white rounded-lg flex-col
              justify-center items-start gap-4 inline-flex"
          >
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="w-full">
              <div className="grid grid-cols-12 gap-6 w-full">
                <div className="col-span-12">
                  <TextField
                    name="userName"
                    type="text"
                    label="Admin Name"
                    control={control}
                    errors={errors}
                    customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                  />
                </div>
                <div className="col-span-12">
                  <TextField
                    name="email"
                    type="email"
                    label="Email"
                    control={control}
                    errors={errors}
                    customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                  />
                </div>
                <div className="col-span-12">
                  <TextField
                    name="password"
                    type="password"
                    label="Password"
                    control={control}
                    errors={errors}
                    customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                  />
                </div>
                <div className="col-span-12 flex justify-start">
                  <Button
                    customClass=""
                    variant="primary"
                    type="submit"
                  >
                    <div className="text-white text-base font-bold font-['Manrope']">Create</div>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};

export default CreateAdmin;
