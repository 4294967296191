// @ts-nocheck
/* eslint-disable */
import dashboardCardTopRightGradient from '../../../assets/images/dashboardCardTopRightGradient.svg';
import dashboardCardBottomLeftGradient from '../../../assets/images/dashboardCardBottomLeftGradient.svg';
import selectedProfileIcon from '../../../assets/icons/selectedProfileIcon.svg';
import unSelectedProfileIcon from '../../../assets/icons/unSelectedProfileIcon.svg';
import selectedChangePasswordIcon from '../../../assets/icons/selectedChangePasswordIcon.svg';
import unSelectedChangePasswordIcon from '../../../assets/icons/unSelectedChangePasswordIcon.svg';
import HeaderComp from '../HeaderComp';
import FooterComp from '../FooterComp';
import backIcon from '../../../assets/icons/backIcon.svg';
import { Outlet, useNavigate, useLocation } from 'react-router';
import cn from 'classnames';

const ProfileSettings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const profileOption = [
    {
      selectedIcon: selectedProfileIcon,
      unSelectedIcon: unSelectedProfileIcon,
      label: 'My Profile',
      value: 'my-profile',
      path: '/user/profile/my-profile'
    },
    {
      selectedIcon: selectedChangePasswordIcon,
      unSelectedIcon: unSelectedChangePasswordIcon,
      label: 'Change Password',
      value: 'change-password',
      path: '/user/profile/change-password'
    }
  ];

  return (
    <>
      <HeaderComp showEventOptions={false} />
      <div className="relative w-full h-[80vh] px-12 justify-between items-start inline-flex py-[32px] bg-[#F7F7FF] z-10">
        <div className="absolute w-[347px] h-[300px] bottom-[32px] right-0 bg-no-repeat bg-[url('assets/images/dashboardCardBgImage.svg')] bg-start" />
        <div className='gap-auto w-full'>
          <div className='flex items-center gap-3 justify-start pb-8 cursor-pointer w-[200px]' onClick={() => navigate('/user/event-overview')}>
            <img src={backIcon} alt='icon' width='24px' height='24px' />
            <div className="text-indigo-600 text-left text-base font-bold font-['Manrope']">
              Return to dashboard
            </div>
          </div>
          <div className='grid grid-cols-12 gap-12 w-full'>
            <div className='col-span-3'>
              <div className="w-full h-[210px] p-6 bg-white rounded-lg flex-col justify-start items-start gap-8 inline-flex">
                <div className="w-full h-[22px] flex-col justify-start items-start gap-2 flex">
                  <div className="w-full grow shrink basis-0 text-black text-xl text-left font-semibold font-['Manrope']">Settings</div>
                </div>
                <div className="flex-col justify-start items-start gap-4 flex">
                  {profileOption?.map((item) => (
                    <div
                      onClick={() => navigate(item?.path)}
                      className={cn("cursor-pointer p-3 justify-start items-start gap-2 flex",
                        location?.pathname?.includes(item?.value) && 'bg-gradient-to-b from-blue-200 to-indigo-300 rounded-[32px]')}>
                      <div className="w-5 h-5 relative">
                        <img src={location?.pathname?.includes(item?.value) ? item?.selectedIcon : item?.unSelectedIcon} alt='icon' />
                      </div>
                      <div className={cn("text-base font-semibold font-['Manrope']",
                        location?.pathname?.includes(item?.value) && 'text-indigo-600 ')}>{item.label}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className={cn('col-span-9 p-6 relative bg-white w-auto rounded-lg flex-col justify-start items-start gap-6 inline-flex z-10')}>
              <img src={dashboardCardTopRightGradient} alt='img' className='absolute right-0 top-0'/>
              <img src={dashboardCardBottomLeftGradient} alt='img' className='absolute left-0 bottom-0'/>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <FooterComp />
    </>
  )
}

export default ProfileSettings;