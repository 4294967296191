// @ts-nocheck
import ModalPopup from '../../../../components/Modal';
import closeIcon from '../../../../assets/icons/adminScreen/closeIcon.svg';
import downloadIcon from '../../../../assets/icons/downloadIcon.svg';
import { useForm, useWatch } from 'react-hook-form';
import TextField from '../../../../components/TextField';
import SelectBox from '../../../../components/SelectBox';
import Checkbox from '../../../../components/CheckBox';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '../../../../components/Button';
import TextEditor from '../../../../components/TextEditor';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useEvent } from '../../../../hooks/useEvent';
import { useHelperApi } from '../../../../hooks/useHelperApi';
import { notification } from 'antd';
import { useChallenge } from '../../../../hooks/useChallenge';
import { useAsync } from 'react-use';
import { useEventType } from '../../../../hooks/useEventType';

const EventForm = (props) => {
  const inputFileRef = useRef(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [generatedUrl, setGeneratedUrl] = useState();
  const { createEvent, editEvent } = useEvent();
  const { getEventTypes } = useEventType();
  const { getAllChallenges } = useChallenge();
  const { generateFileUrl } = useHelperApi();
  const {
    eventForm,
    setEventForm,
    refreshEvents,
  } = props;
  const schema = yup.object({
    name: yup.string().required('Event Name is required field').nullable(),
    eventType: yup.object().required('Event Type is required field').nullable(),
    challenges: yup.array().nullable(),
    longDescription: yup.string().required('Event Description is required field').nonNullable(),
    metaData: yup.string().required('Event Details is required field').nullable(),
  });

  const { value: challenges } = useAsync(() => getAllChallenges(), []);
  const { value: eventTypes } = useAsync(() => getEventTypes(), []);

  const handleChallengesList = () => challenges?.challenge?.filter((item) => (
    eventForm?.eventFormData?.challenges?.find(
      (challengeId) => challengeId === item?._id,
    )
  ));

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...eventForm?.eventFormData,
      challenges: handleChallengesList(),
      eventType: eventForm?.eventFormData?.eventType[0],
      subType: { name: eventForm?.eventFormData?.subType },
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (challenges) {
      setValue('challenges', handleChallengesList());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challenges]);

  const watch = useWatch({ control });

  const onSubmit = async (data) => {
    try {
      const request = {
        ...data,
        challenges: data.challenges?.map((x) => x._id),
        id: eventForm?.eventFormData?._id,
        type: data.eventType,
      };

      if (data.eventType.type === 'VULNERABILITY_DISCLOSURE_EVENT') {
        request.subType = data.subType.name;
      } else {
        delete request.subType;
      }
      let response;

      if (eventForm.eventFormData) {
        response = await editEvent(request);
      } else {
        response = await createEvent(request);
      }
      notification.success({
        message: response?.message || `Successfully ${eventForm.eventFormData ? 'updated' : 'created'} event`,
        duration: 3,
      });
      if (refreshEvents) {
        refreshEvents();
      }
      setEventForm({ eventFormData: null, openPopup: false });
    } catch (error) {
      notification.error({
        message: error?.message || `Failed to ${eventForm.eventFormData ? 'edit' : 'create'} event`,
        duration: 3,
      });
    }
  };

  const onFileChange = (e) => {
    e.preventDefault();

    if (e?.target?.files && e?.target?.files?.length > 0) {
      const uploadedFileList = Array.from(e?.target?.files);
      const uniqueFileUploadedList = uploadedFileList.filter((item) =>
        [
          'jpg', 'jpeg', 'png', 'svg', 'pdf', 'txt', 'mp4', 'wmv',
          'docx', 'doc', 'xls', 'xlsx', 'ppt', 'pptx', 'csv'
        ].includes(item?.name?.split('.')[1]?.toLowerCase()),
      );
      // Use the spread operator to create a new array with the updated files
      const tempUploadedFiles = [...uniqueFileUploadedList];
      setUploadedFiles(tempUploadedFiles);
    }
  };

  const onDropHandler = (e) => {
    e.preventDefault();

    if (e?.dataTransfer?.files && e?.dataTransfer?.files?.length > 0) {
      const uploadedFileList = Array.from(e?.dataTransfer?.files);
      const uniqueFileUploadedList = uploadedFileList.filter((item) =>
        [
          'jpg', 'jpeg', 'png', 'svg', 'pdf', 'txt', 'mp4', 'wmv',
          'docx', 'doc', 'xls', 'xlsx', 'ppt', 'pptx', 'csv'
        ].includes(item?.name?.split('.')[1]?.toLowerCase()),
      );

      // Use the spread operator to create a new array with the updated files
      const tempUploadedFiles = [...uniqueFileUploadedList];
      setUploadedFiles(tempUploadedFiles);
    }
  };

  const generateUrl = useCallback(async () => {
    try {
      const formData = new FormData();
      formData.append('images', uploadedFiles[0]);
      const response = await generateFileUrl(formData);
      notification.success({ message: 'Successfully generated url' });
      setGeneratedUrl(response.url);
      setUploadedFiles([]);
    } catch (error) {
      notification.error({ message: error?.message || 'Failed to generate URL' });
    }
  }, [generateFileUrl, uploadedFiles]);

  return (
    <ModalPopup fullScreen>
      <div
        className="w-full h-full pt-8 pb-12 bg-purple-50 flex-col justify-start
          items-start gap-8 inline-flex overflow-auto"
      >
        <div className="self-stretch px-12 justify-between items-center inline-flex">
          <div
            className="text-indigo-600 text-xl font-semibold font-['Manrope']"
          >
            {eventForm?.eventFormData ? 'Edit Event' : 'New Event'}
          </div>
          <div
            className="w-8 h-8 relative cursor-pointer"
            onClick={() => setEventForm({ eventFormData: null, openPopup: false })}
          >
            <img src={closeIcon} alt="icon" />
          </div>
        </div>
        <div className="self-stretch h-full px-12 flex-col justify-start items-start gap-[26px] flex">
          <div
            className="self-stretch h-full p-6 bg-white rounded-lg flex-col justify-center items-start gap-4 flex"
          >
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="w-full gap-6">
              <div className="grid grid-cols-12 gap-6 w-full">
                <div className="col-span-12">
                  <TextField
                    name="name"
                    type="text"
                    label="Event Name"
                    control={control}
                    errors={errors}
                    customHeight="40px"
                    customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                  />
                </div>
                <div className="col-span-12">
                  <SelectBox
                    name="eventType"
                    placeholder="Select Event"
                    label="Event Type"
                    options={eventTypes || []}
                    control={control}
                    errors={errors}
                    customLabel="name"
                    customValue="type"
                    customHeight="40px"
                    isClearable
                    customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                  />
                </div>
                {watch?.eventType
                  && watch?.eventType?.type !== 'VULNERABILITY_DISCLOSURE_EVENT'
                  && (
                    <>
                      <div className="col-span-12">
                        <Checkbox
                          name="isPaid"
                          label="Is Paid Event?"
                          control={control}
                          errors={errors}
                          defaultValue={watch?.isPaid}
                          customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope']"
                        />
                      </div>
                      {watch?.isPaid && (
                        <div className="col-span-12">
                          <TextField
                            name="amount"
                            type="number"
                            label="Amount in $"
                            control={control}
                            errors={errors}
                            customHeight="40px"
                            customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                          />
                        </div>
                      )}
                    </>
                  )}
                {watch?.eventType
                  && watch?.eventType?.type === 'VULNERABILITY_DISCLOSURE_EVENT'
                  && (
                    <>
                      <div className="col-span-12">
                        <SelectBox
                          name="subType"
                          placeholder="Select VDP Event Type"
                          label="Vulnerability Disclosure Programs (VDP) Type"
                          options={[
                            { name: 'Responsible Disclosure' },
                            { name: 'Bug Bounty' },
                          ]}
                          control={control}
                          errors={errors}
                          customLabel="name"
                          customValue="type"
                          customHeight="40px"
                          isClearable
                          customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                        />
                      </div>
                      <div className="col-span-12 self-stretch h-auto flex-col justify-end items-start gap-6 flex">
                        <div className="self-stretch h-auto flex-col justify-center items-start gap-2 flex">
                          <div className="text-zinc-700 text-sm text-left font-normal font-['Manrope']">
                            Sample Report Format (VDP)
                          </div>
                          <div className="w-full">
                            <TextEditor
                              control={control}
                              errors={errors}
                              name="sampleVDPReportFormat"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                <div className="col-span-12 self-stretch h-auto flex-col justify-end items-start gap-6 flex">
                  <div className="self-stretch h-auto flex-col justify-center items-start gap-2 flex">
                    <div className="text-zinc-700 text-sm font-normal font-['Manrope']">Event Description</div>
                    <div className="w-full">
                      <TextEditor
                        control={control}
                        errors={errors}
                        name="longDescription"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-12 self-stretch h-auto flex-col justify-end items-start gap-6 flex">
                  <div className="self-stretch h-auto flex-col justify-center items-start gap-2 flex">
                    <div className="text-zinc-700 text-sm font-normal font-['Manrope']">Other Details</div>
                    <div className="w-full">
                      <TextEditor
                        control={control}
                        errors={errors}
                        name="curiculam"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-12 self-stretch h-auto flex-col justify-end items-start gap-6 flex">
                  <div className="self-stretch h-auto flex-col justify-center items-start gap-2 flex">
                    <div className="text-zinc-700 text-sm font-normal font-['Manrope']">Event Details (Public)</div>
                    <div className="w-full">
                      <TextEditor
                        control={control}
                        errors={errors}
                        name="metaData"
                      />
                    </div>
                  </div>
                </div>
                {watch?.eventType
                  && watch?.eventType?.type !== 'VULNERABILITY_DISCLOSURE_EVENT'
                  && (
                    <>
                      <div className="col-span-12">
                        <SelectBox
                          name="challenges"
                          placeholder="Select Challenges"
                          label="Add Challenges"
                          options={challenges?.challenge || []}
                          control={control}
                          errors={errors}
                          customLabel="title"
                          customValue="_id"
                          customHeight="56px"
                          isClearable
                          isMulti
                          customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                        />
                      </div>
                    </>
                  )}
                <div className="col-span-12">
                  <TextField
                    name="url"
                    type="text"
                    label="Event Image URL"
                    control={control}
                    errors={errors}
                    customHeight="40px"
                    customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                  />
                </div>
                <div className="col-span-12 flex justify-start">
                  <Button
                    type="submit"
                  >
                    <div className="text-white text-base font-bold font-['Manrope']">Create</div>
                  </Button>
                </div>
              </div>
            </form>
          </div>
          <div
            className="self-stretch p-6 bg-white rounded-lg flex-col justify-center items-start gap-4 flex"
          >
            <div className="self-stretch h-[186px] flex-col justify-end items-start gap-6 flex">
              <div
                className="text-zinc-700 text-base font-semibold font-['Manrope']"
              >
                Generate Image URL
              </div>
              <div
                className="w-full h-[140px] relative flex justify-center items-center cursor-pointer"
                onDrop={onDropHandler}
                onClick={() => { inputFileRef.current.click(); }}
                onDragOver={(e) => e.preventDefault()}
              >
                <div
                  className="w-full h-[140px] left-0 top-0 absolute rounded-lg border border-dashed
                    border-black border-opacity-20"
                />
                <div className="w-full top-[23.50px] absolute flex-col justify-start items-center gap-2 inline-flex">
                  <div className="w-8 h-8 relative">
                    <img src={downloadIcon} alt="icon" />
                  </div>
                  <div className="">
                    <span className="text-zinc-700 text-base font-normal font-['Manrope'] break-words">
                      Drop your files here, or
                      {' '}

                    </span>
                    <span
                      className="text-indigo-600 text-base font-normal font-['Manrope'] cursor-pointer"
                    >
                      browse
                    </span>
                  </div>
                  <div className="text-black text-opacity-20 text-sm font-normal font-['Manrope']">
                    Supports PNG, JPG, SVG, TXT, PDF, MP4, WMV, DOC, XLS
                  </div>
                  <input
                    type="file"
                    onChange={onFileChange}
                    onDrop={onFileChange}
                    ref={inputFileRef}
                    name="files"
                    id="file"
                    className="hidden"
                    accept=".jpg, .jpeg, .png, .svg, .pdf, .txt, .mp4, .wmv, .docx, .doc, .xls, .xlsx, .ppt, .pptx, .csv"
                  />
                </div>
              </div>
            </div>
            {uploadedFiles?.length > 0 && uploadedFiles?.map((item, index) => (
              <div key={index} className="w-full my-2 p-3 gap-5 rounded-md border-[2px] border-solid border-black">
                <div className="grid grid-cols-6 gap-5">
                  <span className="col-span-12">
                    {item?.name}
                  </span>
                </div>
              </div>
            ),
            )}
            <Button
              disabled={uploadedFiles?.length <= 0}
              type="button"
              variant="primary-outline"
              onClick={() => generateUrl()}
            >
              <div className="text-indigo-600 text-base font-bold font-['Manrope']">Generate URL</div>
            </Button>
            {generatedUrl && (
            <div className="text-indigo-600 rounded-md border-[2px] mb-11 p-[20px]">
              <span><b>Generated Url:</b></span>
              {' '}
              {generatedUrl}
            </div>
            )}
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};

export default EventForm;
