import { useAuthentication } from '../contexts/AuthContext';
import CategoryService from '../services/CategoryService';
import {
  ICreateCategoryRequest, IEditCategoryRequest, IGetAllCategoriesResponse,
} from '../services/CategoryService/types';
import { useCallbackWithLoading } from './useCallbackWithLoading';

interface IUseChallenge {
  getAllCategories: (queryParams?: {
      page: number;
      limit: number;
      searchTerm?: string;
    }) => Promise<IGetAllCategoriesResponse>;
  createCategory: (payload: ICreateCategoryRequest) => Promise<{message: string}>;
  editCategory: (payload: IEditCategoryRequest) => Promise<{message: string}>;
  deleteCategory: (id: string) => Promise<{message: string}>;
}

export const useCategories = (): IUseChallenge => {
  const { sessionToken } = useAuthentication();

  const getAllCategories = useCallbackWithLoading(async (queryParams?: {
    page: number;
    limit: number;
    searchTerm?: string;
  }) => {
    const categoriesResponse = await CategoryService.getAllCategories(
      queryParams, sessionToken?.rawValue,
    );
    return categoriesResponse;
  }, [sessionToken?.rawValue]);

  const createCategory = useCallbackWithLoading(async (payload: ICreateCategoryRequest) => {
    const response = await CategoryService.createCategory(
      payload, sessionToken?.rawValue,
    );
    return response;
  }, [sessionToken?.rawValue]);

  const editCategory = useCallbackWithLoading(async (payload: IEditCategoryRequest) => {
    const response = await CategoryService.editCategory(
      payload, sessionToken?.rawValue,
    );
    return response;
  }, [sessionToken?.rawValue]);

  const deleteCategory = useCallbackWithLoading(async (id: string) => {
    const response = await CategoryService.deleteCategory(
      id, sessionToken?.rawValue,
    );
    return response;
  }, [sessionToken?.rawValue]);

  return {
    getAllCategories,
    createCategory,
    editCategory,
    deleteCategory,
  };
};
