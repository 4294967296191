// @ts-nocheck

import liveFeedIcon from '../../../../assets/icons/adminScreen/liveFeedIcon.svg';
import profileIcon from '../../../../assets/icons/dashboardScreen/profileIcon.svg';

const LiveFeed = ({ solutions }) => {
  const handleLiveFeedData = (liveFeedData) => {
    let feedData = '';
    if (liveFeedData?.challenge?.title && liveFeedData?.status === 'pending') {
      feedData = `${liveFeedData?.user?.userName} 
        completed challenge ${liveFeedData?.challenge?.title} in event ${liveFeedData?.event.name}`;
    } else if (liveFeedData?.status === 'Pending') {
      feedData = `${liveFeedData?.user?.userName} submitted report to event ${liveFeedData?.event?.name}`;
    }
    return feedData;
  };

  return (
    <div className="w-full h-[374px] p-6 bg-white rounded-lg flex-col justify-start items-start gap-6 inline-flex">
      <div className="justify-start items-center gap-2 inline-flex">
        <div className="w-5 h-5 relative">
          <img src={liveFeedIcon} alt="icon" />
        </div>
        <div className="text-indigo-600 text-base font-medium font-['Manrope']">Live Feed</div>
      </div>
      <div className="overflow-x-hidden overflow-y-scroll h-auto">
        {solutions?.map((item, index) => (
          <div key={index} className="grid grid-cols-12 gap-2 pb-4">
            <div className="md:col-span-3 xl:col-span-2">
              <img className="w-10 h-10 rounded-full" src={profileIcon} alt="" />
            </div>
            <div className="md:col-span-9 xl:col-span-10 flex-col justify-center items-start gap-1 inline-flex
              overflow-x-auto overflow-y-hidden"
            >
              <div className="text-zinc-700 text-base font-normal font-['Manrope'] break-all">
                {handleLiveFeedData(item)}
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* <div className="justify-start items-start gap-0.5 inline-flex">
        <Pagination
          defaultCurrent={1}
          onChange={onChange}
          total={solutions?.length}
          size="small"
          responsive
          showSizeChanger={false}
        />
      </div> */}
    </div>
  );
};

export default LiveFeed;
