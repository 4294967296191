import { useAuthentication } from '../contexts/AuthContext';
import NotificationService from '../services/NotificationService';
import { INotification } from '../services/NotificationService/types';
import { useCallbackWithLoading } from './useCallbackWithLoading';

interface IUseNotification {
	getAllNotifications: (queryParams?: {
    page: number;
    limit: number;
}) => Promise<INotification[]>;
}

export const useNotification = (): IUseNotification => {
  const { sessionToken } = useAuthentication();

  const getAllNotifications = useCallbackWithLoading(async (queryParams?: {
    page: number, limit: number,
  }) => {
    const notifications = await NotificationService.getAllNotifications(queryParams, sessionToken?.rawValue);
    return notifications;
  }, [sessionToken?.rawValue]);

  return {
    getAllNotifications,
  };
};
