// @ts-nocheck
import closeIcon from '../../../../assets/icons/adminScreen/closeIcon.svg';
import { useForm } from 'react-hook-form';
import Button from '../../../../components/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TextField from '../../../../components/TextField';
import ModalPopup from '../../../../components/Modal';
import { useUser } from '../../../../hooks/useUser';
import { notification } from 'antd';

const EditUser = (props) => {
  const { setEditUser, editUser } = props;
  const schema = yup.object({
    newPassword: yup.string().required('New Password is required field').nullable(),
    confirmPassword: yup.string().required('Password is required field')
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
  });

  const { updateUserPassword } = useUser();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      await updateUserPassword({ id: editUser._id, password: data.newPassword });
      notification.success({ message: 'Successfully updated password' });
      setEditUser(null);
    } catch (error) {
      notification.error({ message: error?.message || 'Failed to change password' });
    }
  };

  return (
    <ModalPopup
      fullScreen
    >
      <div className="w-screen h-screen pt-8 pb-12 bg-purple-50 flex-col justify-start items-start gap-8 inline-flex">
        <div className="self-stretch px-12 justify-between items-center inline-flex">
          <div className="text-indigo-600 text-xl font-semibold font-['Manrope']">
            Edit User
          </div>
          <div
            className="w-8 h-8 relative cursor-pointer"
            onClick={() => setEditUser(false)}
          >
            <img src={closeIcon} alt="icon" />
          </div>
        </div>
        <div className="self-stretch px-12 justify-start items-start gap-[26px] inline-flex">
          <div
            className="w-[800px] self-stretch p-6 bg-white rounded-lg flex-col
              justify-center items-start gap-4 inline-flex"
          >
            <div className="text-black text-opacity-20 text-base font-medium font-['Manrope']">Change Password</div>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="w-full">
              <div className="grid grid-cols-12 gap-6 w-full">
                <div className="col-span-12">
                  <TextField
                    name="newPassword"
                    type="password"
                    label="New Password"
                    control={control}
                    errors={errors}
                    customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                  />
                </div>
                <div className="col-span-12">
                  <TextField
                    name="confirmPassword"
                    type="password"
                    label="Confirm Password"
                    control={control}
                    errors={errors}
                    customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                  />
                </div>
                <div className="col-span-12 flex justify-start">
                  <Button
                    customClass=""
                    variant="primary"
                    type="submit"
                  >
                    <div className="text-white text-base font-bold font-['Manrope']">Update</div>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};

export default EditUser;
