import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

type ApiResponse<T> = {
  data: T;
  headers: Record<string, string>;
  status: number;
};

const HttpService = {
  post: async <T, J extends {} = {}>(
    url: string,
    payload?: J,
    token?: string,
    queryParams?: Record<string, any>,
    requestHeaders: Record<string, string> = {},
  ): Promise<ApiResponse<T>> => {
    const authorizationHeader = token ? { Authorization: `Bearer ${token}` } : {};
    const config: AxiosRequestConfig = {
      headers: {
        ...requestHeaders,
        ...authorizationHeader,
      },
      params: queryParams,
    };

    try {
      const response: AxiosResponse<T> = await axiosInstance.post(url, payload, config);

      return {
        data: response.data,
        headers: response.headers as Record<string, string>,
        status: response.status,
      };
    } catch (error) {
      throw error;
    }
  },

  put: async <T, J extends {} = {}>(
    url: string,
    payload?: J,
    token?: string,
    queryParams?: Record<string, any>,
    requestHeaders: Record<string, string> = {},
  ): Promise<ApiResponse<T>> => {
    const authorizationHeader = token ? { Authorization: `Bearer ${token}` } : {};
    const config: AxiosRequestConfig = {
      headers: {
        ...requestHeaders,
        ...authorizationHeader,
      },
      params: queryParams,
    };

    try {
      const response: AxiosResponse<T> = await axiosInstance.put(url, payload, config);

      return {
        data: response.data,
        headers: response.headers as Record<string, string>,
        status: response.status,
      };
    } catch (error) {
      throw error;
    }
  },

  get: async <T>(url: string, token?: string, queryParams?: Record<string, any>): Promise<ApiResponse<T>> => {
    const authorizationHeader = token ? { Authorization: `Bearer ${token}` } : {};
    const config: AxiosRequestConfig = {
      headers: {
        ...authorizationHeader,
      },
      params: queryParams,
    };

    try {
      const response: AxiosResponse<T> = await axiosInstance.get(url, config);

      return {
        data: response.data,
        headers: response.headers as Record<string, string>,
        status: response.status,
      };
    } catch (error) {
      throw error;
    }
  },

  patch: async <T, J extends {} = {}>(
    url: string,
    payload?: J,
    token?: string,
  ): Promise<ApiResponse<T>> => {
    const authorizationHeader = token ? { Authorization: `Bearer ${token}` } : {};
    const config: AxiosRequestConfig = {
      headers: {
        ...authorizationHeader,
      },
    };

    try {
      const response: AxiosResponse<T> = await axiosInstance.patch(url, payload, config);

      return {
        data: response.data,
        headers: response.headers as Record<string, string>,
        status: response.status,
      };
    } catch (error) {
      throw error;
    }
  },

  delete: async <T>(url: string, token?: string, queryParams?: Record<string, any>): Promise<ApiResponse<T>> => {
    const authorizationHeader = token ? { Authorization: `Bearer ${token}` } : {};
    const config: AxiosRequestConfig = {
      headers: {
        ...authorizationHeader,
      },
      params: queryParams,
    };

    try {
      const response: AxiosResponse<T> = await axiosInstance.delete(url, config);

      return {
        data: response.data,
        headers: response.headers as Record<string, string>,
        status: response.status,
      };
    } catch (error) {
      throw error;
    }
  },
};

export default HttpService;
