import { EventMeta } from "../EventService.ts/types";
import { IUser } from "../UserService/types";

export enum AchievementType {
  CERTIFICATE = 'CERTIFICATE',
  BADGE = 'BADGE',
}

export interface IAchievement {
  name: string;
  event: EventMeta;
  type: AchievementType;
  user: IUser;
  userFullName: string;
  certificateUrl: string;
  certificateFileUrl: string;
  __v: number;
  _id: string;
  createdAt: string;
  updatedAt: string;
}

export interface IGetAllAchievementsResponse {
  achievements: IAchievement[];
  totalAchievements: number;
}

export interface ICreateAchievementRequest {
  name: string;
  event: EventMeta;
  type: AchievementType;
  user: IUser;
  userFullName: string;
  certificateUrl: string;
}

export interface IEditAchievementRequest {
  name: string;
  event: EventMeta;
  type: AchievementType;
  user: IUser;
  userFullName: string;
  certificateUrl: string;
  id: string;
}

export interface IUserWihAchievements {
  achievements: IAchievement[];
  userWithEvents: {
    events: EventMeta;
    hasPublicProfile: boolean;
    _id: string;
  }
}