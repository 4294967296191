// @ts-nocheck
/* eslint-disable */
import { useState, useRef } from 'react';
import arrowDownIcon from '../../../assets/icons/dashboardScreen/arrowDownIcon.svg';
import uploadIcon from '../../../assets/icons/dashboardScreen/uploadIcon.svg';
import downloadIcon from '../../../assets/icons/downloadIcon.svg';
import cn from 'classnames';
import TextField from '../../../components/TextField';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/Button';
import ModalPopup from '../../../components/Modal';
import closeIcon from '../../../assets/icons/closeIcon.svg';
import deleteIcon from '../../../assets/icons/deleteIcon.svg';
import { useVDPReport } from '../../../hooks/useVDPReport';
import { useAuthentication } from '../../../contexts/AuthContext';
import { notification } from 'antd';
import MarkdownView from '../../../components/MarkdownView/MarkdownView';
import TextEditor from "../../../components/TextEditor";
import './SubmitVDPReport.css';
import { useCallbackWithLoading } from '../../../hooks/useCallbackWithLoading';
import ReCAPTCHA from 'react-google-recaptcha';

const SubmitVDPReport = () => {
  const [showDetails, setShowDetails] = useState({
    viewSampleReport: true,
  });
  const [showUploadedFiles, setShowUploadedFiles] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const inputFileRef = useRef(null);
  const navigate = useNavigate();
  const toggleDetails = (field) => {
    setShowDetails((prevSortOrder) => ({
      ...prevSortOrder,
      [field]: !prevSortOrder?.[field]
    }));
  };
  const { selectedEvent, sessionToken: { user } } = useAuthentication();

  const captchaRef = useRef(null);

  const [solution, setSolution] = useState('');
  const [title, setTitle] = useState('');

  const { submitReport } = useVDPReport();

  const onFileChange = e => {
    e?.preventDefault();
    if (e?.target?.files && e?.target?.files?.length > 0) {
      const uploadedFileList = Array?.from(e?.target?.files);
      const uniqueFileUploadedList = uploadedFileList?.filter((item) =>
        [
          'jpg', 'jpeg', 'png', 'svg', 'pdf', 'txt', 'mp4', 'wmv',
          'docx', 'doc', 'xls', 'xlsx', 'ppt', 'pptx', 'csv'
        ].includes(item?.name?.split('.')[1]?.toLowerCase())
      );
      setUploadedFiles((prev) => ([ ...prev, ...uniqueFileUploadedList ]));
    };
  };

  const onDropHandler = (e) => {
    e.preventDefault();
    if (e?.dataTransfer?.files && e?.dataTransfer?.files?.length > 0) {
      const uploadedFileList = Array?.from(e?.dataTransfer?.files);
      const uniqueFileUploadedList = uploadedFileList?.filter((item) =>
        [
          'jpg', 'jpeg', 'png', 'svg', 'pdf', 'txt', 'mp4', 'wmv',
          'docx', 'doc', 'xls', 'xlsx', 'ppt', 'pptx', 'csv'
        ].includes(item?.name?.split('.')[1]?.toLowerCase())
      );
      setUploadedFiles((prev) => ([ ...prev, ...uniqueFileUploadedList ]));
    };
  };

  const schema = yup.object({
    title: yup.string().required('Title is required').max(200, 'Maximum 200 characters allowed ').nullable(),
    solutionDescription: yup.string().required('Report detail is required').nullable(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema)
  });

  const handleUploadedFileDelete = (index) => {
    const updatedFiles = [...uploadedFiles];
    updatedFiles.splice(index, 1);
    setUploadedFiles(updatedFiles);
    if (!updatedFiles?.length) {
      setShowUploadedFiles(false);
    }
  };

  const onSubmitReport = useCallbackWithLoading(async (data) => {
    try {
      const formData = new FormData();
      formData.append('eventId', selectedEvent?._id);
      formData.append('userId', user?._id);
      formData.append('solutionDescription', data.solutionDescription);
      formData.append('title', data.title);
      const captcha = captchaRef?.current?.getValue();
      formData.append('captcha', captcha);
      captchaRef.current.reset();
      for (let file of uploadedFiles) {
        if (
          [
            'jpg', 'jpeg', 'png', 'svg', 'pdf', 'txt','docx',
            'doc', 'xls', 'xlsx', 'ppt', 'pptx', 'csv'
          ].includes(file?.name?.split('.')[1]?.toLowerCase())
        ) {
          formData.append('images', file);
        } else {
          formData.append('video', file);

        }
      }
      
      await submitReport(formData);

      notification.success({
        message: 'Successfully submitted your solution',
        duration: 3
      });

      navigate('/user/submit-report')
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || error?.message,
        duration: 3
      });
    }
  }, [solution, title, uploadedFiles])
  
  return (
    <div className="w-full min-h-screen h-full pt-4 pb-12 bg-purple-50 flex-col justify-start items-start gap-8 inline-flex">
      {showUploadedFiles && (
        <ModalPopup>
          <div
            className="w-full h-full p-8 rounded-lg shadow flex-col justify-start items-start
              gap-6 inline-flex relative"
          >
            <div className="w-6 h-6 absolute right-2 top-2 cursor-pointer" onClick={() => setShowUploadedFiles(false)}>
              <img src={closeIcon} alt="icon" />
            </div>
            <div className="text-black text-xl font-medium font-['Manrope']">
              Uploaded Files
            </div>
            <div className="self-stretch text-left gap-5 overflow-auto max-h-[65vh]">
              {uploadedFiles?.map((item, index) => (
                <div className="w-full my-2 p-3 gap-5 rounded-md border-[2px] border-solid border-[#E4E4FF]">
                  <div className="grid grid-cols-12 gap-5">
                    <span className="col-span-10">
                      {item?.name}
                    </span>
                    <span
                      className="col-span-2 cursor-pointer flex justify-center items-center"
                      onClick={() => handleUploadedFileDelete(index)}
                    >
                      <img
                        className="w-[15px] h-[20px]"
                        src={deleteIcon}
                        alt='icon'
                        width="15px"
                        height="20px"
                      />
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </ModalPopup>
      )}
      <div className="self-stretch px-12 justify-between items-center inline-flex">
        <div className="w-[128.42px] h-5 relative">
          <div className="w-[128.42px] h-5 left-0 top-0 absolute">
            <div className="w-[20.18px] h-5 left-0 top-0 absolute">
            </div>
            <div className="w-[105.26px] h-[9.31px] left-[23.16px] top-[7.64px] absolute">
            </div>
          </div>
        </div>
        <div className="text-indigo-600 text-xl font-semibold font-['Manrope']">{selectedEvent?.name}</div>
        <Button
          onClick={() => navigate('/user/submit-report')}
          variant='primary-outline'
        >
          <div className="text-indigo-600 text-base font-bold font-['Manrope']">Exit</div>
        </Button>
      </div>
      <div className="self-stretch px-12 justify-start items-start gap-[26px] grid grid-cols-12 w-full h-full">
        <div className="col-span-12 lg:col-span-3 text-left w-full flex-col justify-start items-start gap-[26px] inline-flex">
          <div className="self-stretch h-auto p-6 bg-white rounded-lg flex-col justify-start items-start gap-8 flex overflow-auto">
            <div className="self-stretch h-auto flex-col justify-start items-start gap-4 flex">
              <div
                className="self-stretch pb-3 border-b border-neutral-200 justify-start items-center gap-3 inline-flex cursor-pointer"
                onClick={() => toggleDetails('viewSampleReport')}
              >
                <div
                  className={cn("w-6 h-6 relative", showDetails?.viewSampleReport && 'rotate-180')}
                >
                  <img src={arrowDownIcon} alt='icon' />
                </div>
                <div className="text-indigo-600 text-base font-medium font-['Manrope'] text-left">Sample Report Format</div>
              </div>
              {showDetails?.viewSampleReport && (
                <div className="self-stretch text-zinc-700 text-base font-medium font-['Manrope']">
                 <MarkdownView value={selectedEvent?.sampleVDPReportFormat} />
                </div>
              )}
            </div>
          </div>
        </div>
        {!!selectedEvent?.active && (
        <div className="col-span-12 lg:col-span-9 grow shrink basis-0 p-6 bg-white rounded-lg flex-col justify-start items-start gap-6 inline-flex challenge-details">
            <form onSubmit={handleSubmit(onSubmitReport)} autoComplete="off" className="w-full flex flex-col justify-center gap-6">
              <div className="px-[10px] gap-[24px]">
                <div className="">
                  <TextField
                    name="title"
                    type="text"
                    placeholder="Enter report title"
                    label="Report Title"
                    control={control}
                    errors={errors}
                    customHeight="56px"
                  />
                </div>
              </div>
            <div className="self-stretch flex-col justify-start items-start gap-4 flex px-[10px]">
            <div className="text-zinc-700 text-sm font-normal font-['Manrope']">Report Details</div>
              <div className='w-full'>
              <TextEditor
                control={control}
                errors={errors}
                name="solutionDescription"
              />
              </div>
            </div>
            <div className="w-full flex-col justify-start items-start gap-4 flex">
              <div className="text-black text-base font-normal font-['Mandali']">Upload your proof of concept</div>
              <div
                className="w-full h-[140px] relative flex justify-center items-center cursor-pointer"
                onDrop={onDropHandler}
                onClick={() => { inputFileRef.current.click(); }}
                onDragOver={(e) => e.preventDefault()}
              >
                <div className="w-full h-[140px] left-0 top-0 absolute rounded-lg border border-dashed border-black border-opacity-20" />
                <div className="w-full top-[23.50px] absolute flex-col justify-start items-center gap-2 inline-flex">
                  <div className="w-8 h-8 relative">
                    <img src={downloadIcon} alt='icon' />
                  </div>
                  <div className=''>
                    <span className="text-zinc-700 text-base font-normal font-['Manrope']">
                      Drop your files here, or </span>
                    <span
                      className="text-indigo-600 text-base font-normal font-['Manrope'] cursor-pointer"
                    >
                      browse
                    </span>
                  </div>
                  <div className="text-black text-opacity-20 text-sm font-normal font-['Manrope']">
                    Supports PNG, JPG, SVG, TXT, PDF, MP4, WMV, DOC, XLS
                  </div>
                  <input
                    type="file"
                    onChange={onFileChange}
                    onDrop={onFileChange}
                    ref={inputFileRef}
                    name="files"
                    id="file"
                    className="hidden"
                    accept=".jpg, .jpeg, .png, .svg, .pdf, .txt, .mp4, .wmv, .docx, .doc, .xls, .xlsx, .ppt, .pptx, .csv"
                    // multiple
                  />
                </div>
              </div>
              {uploadedFiles?.length > 0 && (
                <div
                  className={cn("justify-start items-center gap-2 inline-flex cursor-pointer",
                    !uploadedFiles?.length && '!cursor-not-allowed opacity-50')}
                  onClick={() => uploadedFiles?.length && setShowUploadedFiles(true)}
                >
                <div className="w-5 h-5 relative">
                  <img src={uploadIcon} alt='icon' />
                </div>
                <div className="text-sky-600 text-base font-semibold font-['Manrope']">View Uploads</div>
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '10px',
                marginTop: '30px',
              }}
            >
              <ReCAPTCHA
                sitekey="6LdZSOYiAAAAAE147AeGSfJfwE03pGz-F8WNeV3s"
                ref={captchaRef}
              />
            </div>
            <Button
              disabled={!solution}
              type='submit'
              variant='primary'
              customClass='w-[200px] m-auto'
              disabled={
                !captchaRef
                  && !captchaRef.current
                  && !captchaRef.current.getValue()
              }
            >
              <div className="text-white text-base font-bold font-['Manrope']">Submit Solution</div>
            </Button>
          </form>
        </div>
      )}
      </div>
    </div>
  )
}

export default SubmitVDPReport;
