// @ts-nocheck
import ModalPopup from '../../../../components/Modal';
import closeIcon from '../../../../assets/icons/closeIcon.svg';
import Button from '../../../../components/Button';
import { notification } from 'antd';
import { useAchievements } from '../../../../hooks/useAchievement';

const DeleteEventPopup = (props) => {
  const {
    deleteData, setDeleteData, refreshAchievements,
  } = props;
  const { deleteAchievement } = useAchievements();

  const onDelete = async () => {
    try {
      const response = await deleteAchievement(deleteData._id);
      notification.success({
        message: response?.message || 'Successfully deleted certificate',
        duration: 3,
      });
      if (refreshAchievements) {
        await refreshAchievements();
      }
      setDeleteData(null);
    } catch (error) {
      notification.error({
        message: error?.message || error?.response?.message || 'Failed to delete certificate',
        duration: 3,
      });
    }
  };

  return (
    <ModalPopup>
      <div
        className="w-full h-auto p-8 bg-white rounded-lg shadow flex-col
          justify-start items-start gap-6 inline-flex"
      >
        <div className="w-6 h-6 absolute right-2 top-2 cursor-pointer" onClick={() => setDeleteData(null)}>
          <img src={closeIcon} alt="icon" />
        </div>
        <div
          className="text-black text-xl font-bold font-['Manrope'] tracking-tight"
        >
          Are you sure you want to delete?
        </div>
        <div className="justify-start items-start gap-6 inline-flex">
          <Button onClick={() => onDelete()}>
            <div className="text-white text-base font-bold font-['Manrope']">Delete</div>
          </Button>
          <Button
            variant="primary-outline"
            onClick={() => { setDeleteData(null); }}
          >
            <div className="text-indigo-600 text-base font-bold font-['Manrope']">Cancel</div>
          </Button>
        </div>
      </div>
    </ModalPopup>
  );
};

export default DeleteEventPopup;
