// @ts-nocheck
/* eslint-disable */
import { useState } from 'react'
import vantagePointIcon from '../../../assets/logo/vantagePointIcon.svg';
import vantagePointText from '../../../assets/logo/vantageText.svg';
import uploadIcon from '../../../assets/icons/dashboardScreen/uploadIcon.svg';
import { useNavigate, useParams } from 'react-router';
import Button from '../../../components/Button';
import { useAsync } from 'react-use';
import { useVDPReport } from '../../../hooks/useVDPReport';
import { useAuthentication } from '../../../contexts/AuthContext';
import cn from 'classnames';
import ModalPopup from '../../../components/Modal';
import closeIcon from '../../../assets/icons/closeIcon.svg';
import MarkdownView from '../../../components/MarkdownView/MarkdownView';
import ChatBox from '../../ChatBox';
import { NavLink } from 'react-router-dom';

const ViewReports = () => {
  const [showUploadedFiles, setShowUploadedFiles] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const { selectedEvent } = useAuthentication();

  const { getVDPReportById } = useVDPReport();
  const { value: reportDataById } = useAsync(async () => getVDPReportById(selectedEvent?._id, params?.id), [getVDPReportById]);

  const getFileName = (fileData) => {
    const fileValue = fileData?.file.split('/');
    return fileValue[fileValue.length - 1];
  };

  return (
    <div className="w-full min-h-screen h-full pt-4 pb-12 bg-purple-50 flex-col justify-start items-start gap-8 inline-flex">
      {showUploadedFiles && (
        <ModalPopup>
          <div
            className="w-full h-full p-8 rounded-lg shadow flex-col justify-start items-start
              gap-6 inline-flex relative"
          >
            <div className="w-6 h-6 absolute right-2 top-2 cursor-pointer" onClick={() => setShowUploadedFiles(false)}>
              <img src={closeIcon} alt="icon" />
            </div>
            <div className="text-black text-xl font-medium font-['Manrope']">
              Uploaded Files
            </div>
            <div className="self-stretch text-left gap-5 overflow-auto max-h-[65vh]">
              {reportDataById?.files?.map((item, index) => (
                <div className="w-full my-2 p-3 gap-5 rounded-md border-[2px] border-solid border-[#E4E4FF]">
                  <div className="grid grid-cols-12 gap-5">
                    <span className="col-span-12">
                    <a href={item.file} target="_blank">{getFileName(item)}</a>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </ModalPopup>
      )}
      <div className="self-stretch px-12 justify-between items-center inline-flex">
        <div className="w-[128.42px] h-5 relative">
          <div
            className="w-[128.42px] h-5 left-0 top-0 absolute cursor-pointer"
          >
            <NavLink to={window.location.origin}>
            <div className="w-[20.18px] h-5 left-0 top-0 absolute">
              <img src={vantagePointIcon} alt='' />
            </div>
            <div className="w-[105.26px] h-[9.31px] left-[23.16px] top-[7.64px] absolute">
              <img src={vantagePointText} alt='' className='' />
            </div>
            </NavLink>
          </div>
        </div>
        <div className="text-indigo-600 text-xl font-semibold font-['Manrope']">
          {reportDataById?.event?.name}
        </div>
        <Button
          onClick={() => navigate('/user/submit-report')}
          variant='primary-outline'
        >
          <div className="text-indigo-600 text-base font-bold font-['Manrope']">
            Exit
          </div>
        </Button>
      </div>
      <div className="sm:grid sm:grid-cols-12 self-stretch px-12 justify-start items-start gap-[26px] lg:inline-flex">
        <div className="sm:col-span-12 lg:w-[486px] flex-col justify-start items-start gap-[26px] inline-flex">
          <div className="self-stretch h-full p-6 bg-white rounded-lg flex-col justify-start items-start gap-2 flex">
          <div className="text-indigo-600 text-base font-medium font-['Manrope']">Comments</div>
            <ChatBox
              vdpReportId={params?.id}
              commentType="VDP_REPORT"
            />
            {/* <Comments />
            <div className="self-stretch h-[273px] rounded-lg flex-col justify-start items-start gap-4 flex">
              <form onSubmit={handleSubmit(onSubmit)} className='w-full h-auto gap-[16px]'>  
                <div className="w-full h-auto text-black text-opacity-20 text-base font-normal font-['Manrope'] leading-normal">
                  <TextArea
                    name="comment"
                    label="Comment"
                    control={control}
                    placeholder='Type your comment here'
                    errors={errors}
                    rows={5}
                  />
                </div>
                <div className='flex justify-start items-start pt-4'>
                  <Button
                    type='submit'
                    variant='primary-outline'
                  >
                    <div className="text-indigo-600 text-base font-bold font-['Manrope']">Submit</div>
                  </Button>
                </div>
              </form>
            </div> */}
          </div>
        </div>
        <div className="sm:col-span-12 grow shrink basis-0 p-6 bg-white rounded-lg flex-col justify-start items-start gap-6 inline-flex">
          <div className="self-stretch h-[52px] flex-col justify-start items-start gap-2 flex">
            <div className="text-black text-base font-medium font-['Manrope']">
              Report Title
            </div>
            <div className="text-zinc-700 text-base font-normal font-['Manrope'] break-all">
              {reportDataById?.title}
            </div>
          </div>
          <div className="flex-col justify-start items-start gap-2 flex">
            <div className="text-black text-base font-medium font-['Manrope']">
              Review Status
            </div>
            <div className={cn("text-zinc-700 text-base font-medium font-['Manrope']",
              reportDataById?.status === "Accepted" && '!text-[#219653]',
              reportDataById?.status === "Not Accepted" && '!text-[#EB5757]',
            )}>
              {reportDataById?.status}
            </div>
          </div>
          <div className="self-stretch flex-col justify-start items-start gap-2 flex">
            <div className="self-stretch text-black text-base font-medium font-['Manrope']">
              Your Solution
            </div>
            <div className="self-stretch text-zinc-700 text-base font-normal font-['Manrope'] break-all">
              <MarkdownView
                linkTarget="_blank"
                value={reportDataById?.solutionDescription}
              />
            </div>
            {reportDataById?.files?.length > 0 && (
              <div
                className={cn("justify-start items-center gap-2 inline-flex cursor-pointer",
                  !reportDataById?.files?.length && '!cursor-not-allowed opacity-50')}
                onClick={() => reportDataById?.files?.length && setShowUploadedFiles(true)}
              >
                <div className="w-5 h-5 relative">
                  <img src={uploadIcon} alt='icon' />
                </div>
                <div className="text-sky-600 text-base font-semibold font-['Manrope']">
                  View Uploads
                </div>
              </div>
            )}
          </div>
          <div className="self-stretch flex-col justify-start items-start gap-2 flex">
            <div className="text-black text-base font-medium font-['Manrope']">
              Feedback
            </div>
            <div className="self-stretch text-zinc-700 text-base font-normal font-['Manrope'] break-all">
              <MarkdownView
                value={reportDataById?.feedback || 'No feedback given'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewReports;