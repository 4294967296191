// @ts-nocheck
import reportCarIcon from '../../../assets/icons/dashboardScreen/reportCardIcon.svg';
import acceptedReportIcon from '../../../assets/icons/dashboardScreen/acceptedReportIcon.svg';
import participantsIcon from '../../../assets/icons/dashboardScreen/participantsIcon.svg';
import submittedReportIcon from '../../../assets/icons/dashboardScreen/submittedReportIcon.svg';
import { useNavigate } from 'react-router';
import Button from '../../../components/Button';
import { useAsync } from 'react-use';
import { useAuthentication } from '../../../contexts/AuthContext';
import { useVDPReport } from '../../../hooks/useVDPReport';
import reportDefault from '../../../assets/images/reportDefault.svg';

const Reports = () => {
  const { selectedEvent } = useAuthentication();
  const navigate = useNavigate();
  const { getVDPReportsByEvent, getVDPReportHighlightsByEvent } = useVDPReport();
  const { value: reportsDetailData = [] } = useAsync(() => getVDPReportsByEvent(selectedEvent?._id), []);
  const { value: reportsHeighLightsData = [] } = useAsync(() => getVDPReportHighlightsByEvent(selectedEvent?._id), []);

  const acceptedReports = reportsDetailData?.filter((item) => item?.status === 'Accepted');
  const notAcceptedReports = reportsDetailData?.filter((item) => item?.status === 'Not Accepted');
  const pendingReports = reportsDetailData?.filter((item) => item?.status === 'Pending');

  return (
    <>
      <div className="w-full opacity-80 text-black text-xl text-left font-bold font-['Manrope'] tracking-tight">
        Highlights
      </div>
      <div className="grid grid-cols-12 gap-4 text-left w-full pb-[42px]">
        <div
          className="sm:col-span-6 md:col-span-6 lg:col-span-3 aspect-[1.1/1] 2xl:w-[220px] 2xl:h-[200px]
            p-6 bg-[#DCFBF8]
            rounded-2xl flex-col justify-between items-start inline-flex"
        >
          <div className="self-stretch grow shrink basis-0 flex-col justify-between items-start flex">
            <div className="w-[21px] h-8 relative">
              <img src={reportCarIcon} alt="icon" />
            </div>
            <div className="self-stretch h-[70px] flex-col justify-start items-start gap-2 flex">
              <div className="self-stretch text-indigo-600 text-[40px] font-normal font-['Poppins'] leading-[42px]">
                {reportsHeighLightsData?.totalUserReports || 0}
              </div>
              <div className="self-stretch opacity-60 text-black text-sm font-normal font-['Poppins'] leading-tight">
                Your Reports
              </div>
            </div>
          </div>
        </div>
        <div
          className="sm:col-span-6 md:col-span-6 lg:col-span-3 aspect-[1.1/1] 2xl:w-[220px]
            2xl:h-[200px] p-6 bg-[#E3E7FF]
            rounded-2xl flex-col justify-between items-start inline-flex"
        >
          <div className="self-stretch grow shrink basis-0 flex-col justify-between items-start flex">
            <div className="self-stretch grow shrink basis-0 flex-col justify-between items-start flex">
              <div className="w-8 h-8 relative">
                <img src={acceptedReportIcon} alt="icon" />
              </div>
              <div className="self-stretch h-[70px] flex-col justify-start items-start gap-2 flex">
                <div className="self-stretch text-indigo-600 text-[40px] font-normal font-['Poppins'] leading-[42px]">
                  {reportsHeighLightsData?.totalSubmissionsAccepted || 0}
                </div>
                <div className="self-stretch opacity-60 text-black text-sm font-normal font-['Poppins'] leading-tight">
                  Total Accepted Reports
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="sm:col-span-6 md:col-span-6 lg:col-span-3 aspect-[1.1/1] 2xl:w-[220px]
            2xl:h-[200px] p-6 bg-[#D9FCFE]
            rounded-2xl flex-col justify-between items-start inline-flex"
        >
          <div className="self-stretch grow shrink basis-0 flex-col justify-between items-start flex">
            <div className="self-stretch grow shrink basis-0 flex-col justify-between items-start flex">
              <div className="w-6 h-8 relative">
                <img src={submittedReportIcon} alt="icon" />
              </div>
              <div className="self-stretch h-[70px] flex-col justify-start items-start gap-2 flex">
                <div className="self-stretch text-indigo-600 text-[40px] font-normal font-['Poppins'] leading-[42px]">
                  {reportsHeighLightsData?.totalSubmissions}
                </div>
                <div className="self-stretch opacity-60 text-black text-sm font-normal font-['Poppins'] leading-tight">
                  Total Submissions
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="sm:col-span-6 md:col-span-6 lg:col-span-3 aspect-[1.1/1] 2xl:w-[220px] 2xl:h-[200px]
            p-6 bg-[#DCEDF8]
            rounded-2xl flex-col justify-between items-start inline-flex"
        >
          <div className="self-stretch grow shrink basis-0 flex-col justify-between items-start flex">
            <div className="self-stretch grow shrink basis-0 flex-col justify-between items-start flex">
              <div className="w-8 h-8 relative">
                <img src={participantsIcon} alt="icon" />
              </div>
              <div className="self-stretch h-[70px] flex-col justify-start items-start gap-2 flex">
                <div className="self-stretch text-indigo-600 text-[40px] font-normal font-['Poppins'] leading-[42px]">
                  {reportsHeighLightsData?.totalParticipants || 0}
                </div>
                <div className="self-stretch opacity-60 text-black text-sm font-normal font-['Poppins'] leading-tight">
                  Total Participants
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {acceptedReports?.length > 0 && (
        <div className="flex-cols">
          <div
            className="w-full pb-[16px] opacity-80 text-black text-xl font-bold font-['Manrope']
              tracking-tight text-left"
          >
            {`Accepted Reports (${acceptedReports?.length})`}
          </div>
          <div className="flex-1 gap-[24px]">
            {acceptedReports?.map((item, index) => (
              <div
                key={index}
                className="w-[385px] h-[233px] justify-start items-start inline-flex mr-6 mb-6"
              >
                <div
                  className="w-[385px] h-[233px] pl-8 pr-6 py-6  bg-white rounded-2xl border
                    border-indigo-100 flex-col justify-start items-start gap-6 inline-flex
                    relative border-l-[8px] border-l-[#2AF6FF]"
                >
                  <div className="self-stretch justify-start items-center gap-3 inline-flex">
                    <img
                      className="w-[96px] h-[117px] rounded-lg"
                      src={reportDefault}
                      alt="img"
                    />
                    <div className="grow shrink basis-0 text-black text-base font-medium font-['Manrope'] break-all">
                      {item?.title}
                    </div>
                  </div>
                  <div className="justify-start items-start gap-6 inline-flex">
                    <Button
                      variant="primary"
                      onClick={() => navigate(`my-reports/${item?._id}`)}
                      customClass="w-[157px] h-[46px]"
                    >
                      <div className="text-white text-base font-bold font-['Manrope']">
                        View Report
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {pendingReports?.length > 0 && (
        <div className="flex-cols">
          <div
            className="w-full pb-[16px] opacity-80 text-black text-xl font-bold font-['Manrope']
              tracking-tight text-left"
          >
            {`Pending Reports (${pendingReports?.length})`}
          </div>
          <div className="flex-1 gap-[24px]">
            {pendingReports?.map((item, index) => (
              <div
                key={index}
                className="w-[385px] h-[233px] justify-start items-start inline-flex mr-6 mb-6"
              >
                <div
                  className="w-[385px] h-[233px] pl-8 pr-6 py-6  bg-white rounded-2xl border border-indigo-100
                    flex-col justify-start items-start gap-6 inline-flex relative border-l-[8px] border-l-[#91A1FE]"
                >
                  <div className="self-stretch justify-start items-center gap-3 inline-flex">
                    <img
                      className="w-[96px] h-[117px] rounded-lg"
                      src={reportDefault}
                      alt="img"
                    />
                    <div className="grow shrink basis-0 text-black text-base font-medium font-['Manrope'] break-all">
                      {item?.title}
                    </div>
                  </div>
                  <div className="justify-start items-start gap-6 inline-flex">
                    <Button
                      variant="primary"
                      onClick={() => navigate(`my-reports/${item?._id}`)}
                      customClass="w-[157px] h-[46px]"
                    >
                      <div className="text-white text-base font-bold font-['Manrope']">
                        View Report
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {notAcceptedReports?.length > 0 && (
        <div className="flex-cols">
          <div
            className="w-full pb-[16px] opacity-80 text-black text-xl font-bold font-['Manrope']
              tracking-tight text-left"
          >
            {`Not Accepted Reports (${notAcceptedReports?.length})`}
          </div>
          <div className="flex-1 gap-[24px]">
            {notAcceptedReports?.map((item, index) => (
              <div
                key={index}
                className="w-[385px] h-[233px] justify-start items-start inline-flex mr-6 mb-6"
              >
                <div
                  className="w-[385px] h-[233px] pl-8 pr-6 py-6  bg-white rounded-2xl border
                    border-indigo-100 flex-col justify-start items-start gap-6 inline-flex
                     relative border-l-[8px] border-l-[#EE8E90]"
                >
                  <div className="self-stretch justify-start items-center gap-3 inline-flex">
                    <img
                      className="w-[96px] h-[117px] rounded-lg"
                      src={reportDefault}
                      alt="img"
                    />
                    <div className="grow shrink basis-0 text-black text-base font-medium font-['Manrope'] break-all">
                      {item?.title}
                    </div>
                  </div>
                  <div className="justify-start items-start gap-6 inline-flex">
                    <Button
                      variant="black"
                      onClick={() => navigate(`my-reports/${item?._id}`)}
                      customClass="w-[157px] h-[46px]"
                    >
                      <div className="text-white text-base font-bold font-['Manrope']">
                        View Report
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Reports;
