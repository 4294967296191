// @ts-nocheck
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../../components/Button';
import DynamicTable from '../../../components/DynamicTable';
import editIcon from '../../../assets/icons/adminScreen/editIcon.svg';
import deleteIcon from '../../../assets/icons/adminScreen/deleteIcon.svg';
import viewIcon from '../../../assets/icons/adminScreen/viewIcon.svg';
import CertificatesForm from './CertificatesForm';
import { useEvent } from '../../../hooks/useEvent';
import CONSTANTS from '../../../constants';
import { useAsyncRetry, useAsync } from 'react-use';
import DeleteCertificatesPopup from './DeleteCertificatesPopup';
import { useUser } from '../../../hooks/useUser';
import { useAchievements } from '../../../hooks/useAchievement';
import SelectBox from '../../../components/SelectBox';

const Certificates = () => {
  const [certificateForm, setCertificateForm] = useState({ openPopup: false, certificateFormData: null });
  const [deleteData, setDeleteData] = useState(null);
  const [page, setPage] = useState(1);
  const { getAllEvents } = useEvent();
  const { getAllUsers } = useUser();
  const { getAllAchievements } = useAchievements();
  const { value: usersList } = useAsync(() => getAllUsers(), []);
  const { value: allEvents } = useAsync(() => getAllEvents(), []);

  const {
    control,
    watch,
    formState: { errors },
  } = useForm();

  const { user, event } = watch({ control });

  useEffect(() => { setPage(1); }, [event, user]);

  const { value: allAchievements, retry: refreshAchievements } = useAsyncRetry(() =>
  getAllAchievements({ page, userId: user?._id, eventId: event?._id, limit: CONSTANTS.PAGE_LIMIT }), [user, event, page]);

  const columns = [
    {
      title: 'S. No',
      dataIndex: '_id',
      key: '_id',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Event',
      dataIndex: 'event',
      key: 'event',
      render: (event) => event?.name,
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (user) => user?.userName,
    },
    {
      title: 'User Full Name',
      dataIndex: 'userFullName',
      key: 'userFullName',
    },
    {
      title: 'Certificate Document Url',
      dataIndex: 'certificateFileUrl',
      key: 'certificateFileUrl',
    },
    {
      title: 'Certificate Url',
      dataIndex: 'certificateUrl',
      key: 'certificateUrl',
    },
    {
      title: 'Status',
      dataIndex: '',
      key: '',
      render: (data) => (
        <div className="justify-start items-center gap-4 flex">
          <div
            className="justify-center items-center gap-1.5 flex cursor-pointer"
            onClick={() => { setCertificateForm({ certificateFormData: data, openPopup: true }); }}
          >
            <div className="w-4 h-4 relative">
              <img src={editIcon} alt="icon" />
            </div>
            <div className="text-sky-600 text-sm font-medium font-['Manrope'] leading-normal">Edit</div>
          </div>
          <div
            className="justify-start items-center gap-1.5 flex cursor-pointer"
            onClick={() => { setDeleteData(data); }}
          >
            <div className="w-4 h-4 relative">
              <img src={deleteIcon} alt="icon" />
            </div>
            <div className="text-sky-600 text-sm font-medium font-['Manrope'] leading-normal">Delete</div>
          </div>
          <div
            className="justify-start items-center gap-1.5 flex cursor-pointer"
            onClick={() => { window.open(`${window.location.origin}/user/achievements/${data?.user?._id}`, '_blank')?.focus(); }}
          >
            <div className="w-4 h-4 relative">
              <img src={viewIcon} alt="icon" />
            </div>
            <div className="text-sky-600 text-sm font-medium font-['Manrope'] leading-normal">View</div>
          </div>
        </div>
      ),
    },
  ];

  const userListData = useMemo(() => {
    return usersList?.users.map((user) => ({
      ...user,
      events: user.events.map((event) => event._id)
    }))
  }, [usersList]);

  return (
    <div className="w-full h-auto flex-col justify-start items-start gap-8 inline-flex p-8">
      {certificateForm?.openPopup && (
        <CertificatesForm
          certificateForm={certificateForm}
          setCertificateForm={setCertificateForm}
          refreshAchievements={refreshAchievements}
          userListData={userListData}
          allEvents={allEvents?.events}
        />
      )}
      {deleteData && (
        <DeleteCertificatesPopup
          deleteData={deleteData}
          setDeleteData={setDeleteData}
          refreshAchievements={refreshAchievements}
        />
      )}
      <div className="self-stretch justify-start items-start gap-8 grid grid-cols-12">
        <div
          className="col-span-8 grow shrink basis-0 self-stretch p-6 bg-white rounded-lg flex-col
            justify-center items-start gap-4 inline-flex"
        >
          <form autoComplete="off" className="w-full">
            <div className="grid grid-cols-12 gap-6 w-full">
              <div className="col-span-6">
                <SelectBox
                  name="user"
                  placeholder="User"
                  options={usersList?.users || []}
                  label="Select User"
                  control={control}
                  errors={errors}
                  customLabel="userName"
                  customValue="_id"
                  customHeight="40px"
                  isClearable
                  customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope']"
                />
              </div>
              <div className="col-span-6">
                <SelectBox
                  name="event"
                  placeholder="Event Name"
                  options={allEvents?.events || []}
                  label="Select Event"
                  control={control}
                  errors={errors}
                  customLabel="name"
                  customValue="_id"
                  customHeight="40px"
                  isClearable
                  customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope']"
                />
              </div>
            </div>
          </form>
        </div>
        <div className="col-span-4 p-6 bg-white rounded-lg flex-col justify-center items-start gap-3 inline-flex">
          <div
            className="self-stretch text-black text-opacity-60 text-sm font-normal
              font-['Manrope'] tracking-tight"
          >
            New Certificates
          </div>
          <div className="self-stretch justify-start items-center gap-4 inline-flex">
            <Button
              customClass="w-full"
              variant="primary-outline"
              onClick={() => { setCertificateForm({ certificateFormData: null, openPopup: true }); }}
            >
              <div className="text-indigo-600 text-base font-bold font-['Manrope']">Create Certificates</div>
            </Button>
          </div>
        </div>
      </div>
      <div className="self-stretch h-auto w-full p-6 bg-white rounded-lg flex-col justify-start items-start flex">
        <DynamicTable
          dataList={allAchievements?.achievements}
          columns={columns}
          pageSize={CONSTANTS.PAGE_LIMIT}
          totalCount={allAchievements?.totalAchievements}
          onPageChange={setPage}
          currentPage={page}
        />
      </div>
    </div>
  );
};

export default Certificates;
