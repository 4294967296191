import { isAxiosError } from 'axios';
import HttpService from '../HttpService';
import { INotification } from './types';

const getAllNotifications = async (
  queryParams?: {
    page: number, limit: number,
  },
  sessionToken?: string) => {
  try {
    const { data } = await HttpService.get<{notitications: INotification[]}>(
      'notification/get-user-notifications',
      sessionToken,
      queryParams,
    );
    return data.notitications;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error?.response?.data;
    }
    throw error;
  }
};

const NotificationService = {
  getAllNotifications,
};

export default NotificationService;
