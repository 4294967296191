import { IEvent } from '../EventService.ts/types';
import { INotification } from '../NotificationService/types';
import { IUser } from '../UserService/types';

export interface IVDPReport {
  status: VDPReportStatus;
  _id: string;
  event: IEvent;
  user: string | IUser;
  solutionDescription: string;
  title: string;
  files: string[];
  createdAt: string;
  updatedAt: string;
}

export enum VDPReportStatus {
  NOT_ACCEPTED = 'Not Accepted',
  PENDING = 'Pending',
  ACCEPTED = 'Accepted',
}

export type IGetVDPReportsByEventResponse = {
  VDPReports: IVDPReport[]
}

export type IGetVDPReportsByIdResponse = {
  vdpReport: IVDPReport
}

export interface IVDPReportHighlights {
  totalSubmissions: number;
  totalSubmissionsAccepted: number;
  totalUserReports: number;
  totalParticipants: number;
}

export type IGetAllReports = {
  adminVDPReports: IVDPReport;
  totalAdminVDPReports: number;
}

export interface IEvaluateVDPReportRequest {
  bountyStatus: string;
  feedback: string;
  status: VDPReportStatus;
  vdpReportId: string;
}

export interface IEvaluateVDPReportResponse {
  message: string;
  notify: INotification;
}
