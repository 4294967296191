/* eslint-disable quotes */
// @ts-nocheck
import TextField from '../../components/TextField';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import cn from 'classnames';
import { useAuthentication } from '../../contexts/AuthContext';

const RegisterForm = (props) => {
  const { setSelectedForm } = props;
  const { signUpSession } = useAuthentication();

  const schema = yup.object({
    // email: yup.string().required('Email is required').email('Enter valid email').nullable(),
    password: yup.string().required('Password is required').nullable(),
    code: yup.string().required('Code is required').nullable(),
    userName: yup.string().required('Name is required').nullable(),
  });

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const setErrorsFromServer = (serverErrors) => {
    Object.keys(serverErrors).forEach((field) => {
      setError(field, {
        type: 'manual',
        message: serverErrors[field],
      });
    });
  };

  const onSubmit = async (data) => {
    try {
      await signUpSession(data);
    } catch (error) {
      if (error) {
        setErrorsFromServer(error);
      }
      console.log('errors ', error);
    }
  };

  return (
    <div
      className={cn('font-Manrope h-full w-full rounded-[16px] bg-cover bg-center',
        `bg-[url(assets/images/loginFormBgGradientImg.svg)]`,
      )}
    >
      <div
        className="pt-[40px] flex items-center justify-center text-[28px] font-[700] text-[#030303] text-center"
      >
        Register a new account
      </div>
      <div
        className="px-[40px] py-[24px]"
      >
        <hr className="bg-clip-border bg-[#42E8E0] border-1 border-[#3B4DBC]" />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="px-[40px] gap-[24px]">
          <div className="">
            <TextField
              name="email"
              type="email"
              fieldLabel="Enter your email address"
              label="Your Email Id*"
              control={control}
              errors={errors}
              customHeight="56px"
              customlabelclass="leading-[42px] text-[20px]"
            />
          </div>
          <div className="">
            <TextField
              name="password"
              type="password"
              fieldLabel="password"
              label="Password*"
              control={control}
              errors={errors}
              customHeight="56px"
              customlabelclass="leading-[42px] text-[20px]"
            />
          </div>
          <div className="">
            <TextField
              name="userName"
              type="text"
              fieldLabel="Enter your user name"
              label="User name*"
              control={control}
              errors={errors}
              customHeight="56px"
              customlabelclass="leading-[42px] text-[20px]"
            />
          </div>
          <div className="">
            <TextField
              name="code"
              type="text"
              fieldLabel="Enter your code"
              label="Invitation Code*"
              control={control}
              errors={errors}
              customHeight="56px"
              customlabelclass="leading-[42px] text-[20px]"
            />
          </div>
        </div>
        <div className="flex justify-center items-center pt-[32px] mx-10">
          <button
            type="submit"
            className="w-full h-[59px] px-10 py-4 bg-gradient-to-b from-indigo-600 to-indigo-800
              rounded-md justify-center items-center gap-3 inline-flex"
          >
            <span className="text-white text-[18px] font-bold font-['Manrope']">Create Account</span>
          </button>
        </div>
      </form>
      <div className="pt-8 pb-10 flex items-center justify-center">
        <span
          className="text-zinc-800 text-base font-medium font-['Manrope'] leading-normal"
        >
          Already Registered?
        </span>
        <span
          className="text-indigo-700 text-base font-semibold font-['Manrope'] leading-normal"
        >
&nbsp;
          {' '}

        </span>
        <span
          className="text-indigo-700 text-base font-semibold font-['Manrope'] underline leading-normal cursor-pointer"
          onClick={() => setSelectedForm('Login')}
        >
          Login Now
        </span>
      </div>
    </div>
  );
};

export default RegisterForm;
