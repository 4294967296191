import { isAxiosError } from 'axios';
import HttpService from '../HttpService';
import {
  ICommentsListResponse, IGetAllCommentsResponse, IGetCommentRequest, ISendCommentRequest,
} from './types';

const getUserComments = async (payload: IGetCommentRequest, sessionToken?: string) => {
  try {
    const { data: { comments } } = await HttpService.post<ICommentsListResponse>(
      'comment/get-comments',
      payload,
      sessionToken,
    );
    return comments;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

const sendComment = async (payload: ISendCommentRequest, sessionToken?: string) => {
  try {
    const { data } = await HttpService.post<{message: string}>(
      'comment/save',
      payload,
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

const editComment = async (payload: { commentId: string, commentMessage: string }, sessionToken?: string) => {
  try {
    const { data } = await HttpService.patch<{message: string}>(
      'comment/update',
      payload,
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};
const deleteComment = async (commentId: string, sessionToken?: string) => {
  try {
    const { data } = await HttpService.delete<{message: string}>(
      `comment/delete/${commentId}`,
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

const getAllComments = async (queryParams: Record<string, string | number>, sessionToken?: string) => {
  try {
    const { data } = await HttpService.get<IGetAllCommentsResponse>(
      'comment/get-all-comments',
      sessionToken,
      queryParams,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

const CommentService = {
  getUserComments,
  sendComment,
  getAllComments,
  editComment,
  deleteComment,
};

export default CommentService;
