// @ts-nocheck
import ModalPopup from '../../../../components/Modal';
import closeIcon from '../../../../assets/icons/adminScreen/closeIcon.svg';
import uploadIcon from '../../../../assets/icons/dashboardScreen/uploadIcon.svg';
import arrowDownIcon from '../../../../assets/icons/adminScreen/arrowDownIcon.svg';
import cn from 'classnames';
import { useState, useEffect } from 'react';
import MarkdownView from '../../../../components/MarkdownView/MarkdownView';
import { useForm, useWatch } from 'react-hook-form';
import TextEditor from '../../../../components/TextEditor';
import Button from '../../../../components/Button';
import SelectBox from '../../../../components/SelectBox';
import { useVDPReport } from '../../../../hooks/useVDPReport';
import { notification } from 'antd';
import ChatBox from '../../../ChatBox';
import { useSocketNotification } from '../../../../contexts/NotificationContext';

export const statusListData = [
  {
    name: 'Accept',
    value: 'Accepted',
  },
  {
    name: 'Reject',
    value: 'Not Accepted',
  },
  {
    name: 'Pending',
    value: 'Pending',
  },
];

export const bountyStatusList = [
  {
    name: 'Bounty Rewarded',
    value: 'Bounty Rewarded',
  },
  {
    name: 'No Reward',
    value: 'No Reward',
  },
];

const EvaluateChallenge = (props) => {
  const { evaluateReport, setEvaluateReport, refreshReports } = props;
  const [uploadedFileList, setUploadedFiles] = useState([]);
  const [showUploadedFiles, setShowUploadedFiles] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const { evaluateVDPReport } = useVDPReport();
  const { socket } = useSocketNotification();

  useEffect(() => {
    if (evaluateReport?.files && evaluateReport?.files?.length > 0) {
      const fileData = evaluateReport?.files?.map((item) => {
        const urlParts = item?.file?.split('/');
        const fileName = urlParts[urlParts.length - 1];
        return {
          ...item,
          name: fileName,
        };
      });
      setUploadedFiles(fileData);
    }
  }, [evaluateReport?.files]);

  const handleStatus = () => (
    statusListData?.find((item) => item?.value === evaluateReport?.status)
  );

  const handleBountyStatus = () => (
    bountyStatusList?.find((item) => item?.value === evaluateReport?.bountyStatus)
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...evaluateReport,
      status: handleStatus(),
      bountyStatus: handleBountyStatus(),
    },
  });

  const watch = useWatch({ control });
  const { status } = watch;

  const onSubmit = async (data) => {
    try {
      const response = await evaluateVDPReport({
        vdpReportId: evaluateReport?._id,
        status: data.status.value,
        feedback: data.feedback,
        bountyStatus: data.bountyStatus?.value,
      });
      // send notification
      socket.emit('send-notification', {
        to: response.notify.receiver,
        from: response.notify.sender,
        msg: response.notify,
      });
      refreshReports();
      notification.success({ message: response?.message || 'Successfully evaluated report' });
      setEvaluateReport(null);
    } catch (error) {
      notification.error({ message: error?.message || 'Failed to evaluate report' });
    }
  };
  return (
    <ModalPopup
      fullScreen
    >
      {showUploadedFiles && (
        <ModalPopup>
          <div
            className="w-full h-full p-8 rounded-lg shadow flex-col justify-start items-start
              gap-6 inline-flex relative"
          >
            <div className="w-6 h-6 absolute right-2 top-2 cursor-pointer" onClick={() => setShowUploadedFiles(false)}>
              <img src={closeIcon} alt="icon" />
            </div>
            <div className="text-black text-xl font-medium font-['Manrope']">
              Uploaded Files
            </div>
            <div className="self-stretch text-left gap-5 overflow-auto max-h-[65vh]">
              {uploadedFileList?.map((item, index) => (
                <div
                  key={index}
                  className="w-full my-2 p-5 gap-5 rounded-md border-[1px] border-solid border-black"
                >
                  <div className="grid grid-cols-12 gap-5">
                    <span className="col-span-12">
                      <a href={item.file} target="_blank" rel="noreferrer">{item.name}</a>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </ModalPopup>
      )}
      <div className="w-full h-full p-8 bg-purple-50 flex-col justify-start items-start gap-8 inline-flex">
        <div className="self-stretch justify-between items-center inline-flex">
          <div className="text-indigo-600 text-xl font-semibold font-['Manrope']">Evaluate Report</div>
          <div className="w-8 h-8 relative" onClick={() => setEvaluateReport(false)}>
            <img src={closeIcon} alt="icon" />
          </div>
        </div>
        <div className="self-stretch h-auto flex-col justify-start items-start gap-[26px] flex">
          <div className="self-stretch h-full p-6 bg-white rounded-lg flex-col justify-start items-start gap-8 flex">
            <div className="self-stretch h-full flex-col justify-start items-start gap-4 flex">
              <div
                className="self-stretch pb-3 border-b border-neutral-200 justify-start
                  items-center gap-3 inline-flex cursor-pointer"
                onClick={() => setShowDetails(!showDetails)}
              >
                <div className="w-6 h-6 relative">
                  <img src={arrowDownIcon} alt="icon" className={cn(showDetails && 'rotate-180')} />
                </div>
                <div className="text-indigo-600 text-base font-medium font-['Manrope']">Event Details</div>
              </div>
              {showDetails && (
                <>
                  <div
                    className="text-left self-stretch text-black text-opacity-80 text-base
                      font-semibold font-['Manrope']"
                  >
                    Event Description
                  </div>
                  <div className="self-stretch text-left  text-zinc-700 text-base font-medium font-['Manrope']">
                    <MarkdownView value={evaluateReport?.event?.longDescription} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className="w-full h-auto p-6 bg-white rounded-lg flex-col justify-start
            items-start gap-8 inline-flex text-left"
        >
          <div className="self-stretch h-auto flex-col justify-start items-start gap-4 flex">
            <div
              className="self-stretch text-black text-opacity-80 text-base font-semibold font-['Manrope']"
            >
              Title
            </div>
            <div
              className="self-stretch text-zinc-700 text-base font-normal font-['Manrope'] break-all"
            >
              {evaluateReport?.title}
            </div>
            <div
              className="self-stretch text-black text-opacity-80 text-base font-semibold font-['Manrope']"
            >
              Report Details
            </div>
            <div className="self-stretch text-left  text-zinc-700 text-base font-medium font-['Manrope']">
              <MarkdownView value={evaluateReport?.solutionDescription} />
            </div>
            {uploadedFileList?.length > 0 && (
              <div
                className="text-left inline-flex cursor-pointer gap-2"
                onClick={() => setShowUploadedFiles(true)}
              >
                <div className="w-5 h-5 relative">
                  <img src={uploadIcon} alt="icon" />
                </div>
                <div className="text-sky-600 text-base font-semibold font-['Manrope']">
                  View Uploads
                </div>
              </div>
            )}
          </div>
        </div>
        {evaluateReport?.status !== 'question-solution-expired' && (
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="w-full gap-6">
            <div className="grid grid-cols-12 gap-6 w-full">
              <div className="col-span-12 self-stretch h-auto flex-col justify-end items-start gap-6 flex">
                <div className="self-stretch h-auto flex-col justify-center items-start gap-2 flex">
                  <div className="text-zinc-700 text-sm font-normal font-['Manrope']">Feedback</div>
                  <div className="w-full">
                    <TextEditor
                      control={control}
                      errors={errors}
                      name="feedback"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-4">
                <SelectBox
                  name="status"
                  placeholder="Select Status"
                  label="Status"
                  options={statusListData || []}
                  control={control}
                  errors={errors}
                  customLabel="name"
                  customValue="value"
                  customHeight="40px"
                  customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                />
              </div>
              {status?.value === 'Accepted' && (
              <div className="col-span-4">
                <SelectBox
                  name="bountyStatus"
                  placeholder="Select Bounty Status"
                  label="Bounty Status"
                  options={bountyStatusList || []}
                  control={control}
                  errors={errors}
                  customLabel="name"
                  customValue="value"
                  customHeight="40px"
                  customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                />
              </div>
              )}
              <div className="col-span-12 flex justify-start">
                <Button
                  type="submit"
                  customClass="w-[250px]"
                  disabled={evaluateReport?.status === 'reviewed'}
                  variant={evaluateReport?.status === 'reviewed' ? 'black' : 'primary'}
                >
                  <div className="text-white text-base font-bold font-['Manrope']">
                    {evaluateReport?.status === 'reviewed' ? 'Evaluation Complete' : 'Evaluate'}
                  </div>
                </Button>
              </div>
            </div>
          </form>
        )}
        <ChatBox
          vdpReportId={evaluateReport._id}
          commentType="VDP_REPORT"
          currentEventId={evaluateReport.event?._id}
        />
      </div>
    </ModalPopup>
  );
};

export default EvaluateChallenge;
