import { isAxiosError } from 'axios';
import dayjs from 'dayjs';
import HttpService from '../HttpService';
import { HttpStatus } from '../HttpService/HttpStatus';
import {
  CreateChallengeRequest,
  EditChallengeRequest,
  GetAllChallengesResponse,
  IChallengeSolutionUserChallenge, IEvaluateSolutionRequest, IEvaluateSolutionResponse, IGetAllSolutionsResponse,
  IOnGoingChallengesWithEventSolution, IResetChallengeRequest, IUserChallengesWithEventSolutionResponse,
  SolutionStatus,
} from './types';

const getUserSingleChallengeSolution = async (eventId: string, challengeId: string, sessionToken?: string) => {
  const { data } = await HttpService.get<IOnGoingChallengesWithEventSolution>(
    `user/challenges/ongoing/${challengeId}/eventId/${eventId}`,
    sessionToken,
  );
  return data;
};

const getUserChallengesByEvent = async (eventId: string, sessionToken?: string) => {
  const { data } = await HttpService.get<IUserChallengesWithEventSolutionResponse>(
    `user/challenges/eventId/${eventId}`,
    sessionToken,
  );

  const onGoingChallenges: IOnGoingChallengesWithEventSolution[] = [];
  const availableChallenges = data.otherChallenges || [];
  const completedChallenges: IOnGoingChallengesWithEventSolution[] = [];
  const expiredChallenges: IOnGoingChallengesWithEventSolution[] = [];

  if (data.onGoingChallenges) {
    data.onGoingChallenges.forEach((challenge) => {
      const startAtDateTime = dayjs(challenge.solution.startAt).add(
        challenge.challenge.duration,
        'minutes',
      );

      const currentDateTime = dayjs();
      const diffInStartAndCurrentDate = currentDateTime.diff(
        startAtDateTime,
        'seconds',
      );

      if (
        (
          diffInStartAndCurrentDate > 0
        && challenge.challenge.duration > 0
        && (challenge.status !== 'submitted')
        )
        || challenge.status === 'question-solution-expired'
      ) {
        expiredChallenges.push(challenge);
      } else if (challenge.status !== 'submitted') {
        onGoingChallenges.push(challenge);
      } else completedChallenges.push(challenge);
    });
  }
  return {
    onGoingChallenges, availableChallenges, completedChallenges, expiredChallenges,
  };
};

const setChallengeExpiredStatus = async (challengeId: string, solutionId: string, sessionToken?: string) => {
  const { data } = await HttpService.post<{ message: string }>(
    'challenge/update-status',
    undefined,
    sessionToken,
    { challengeId, solutionId },
  );
  return data;
};

const setSolutionHintSeen = async (solutionId: string, sessionToken?: string) => {
  await HttpService.put(`challenge/solution/${solutionId}`, { seenHint: true }, sessionToken);
};

const startEventChallengeSolution = async (eventId: string, challengeId: string, sessionToken?: string) => {
  const { data } = await HttpService.post<IChallengeSolutionUserChallenge>(
    `user/challenges/${challengeId}/eventId/${eventId}`,
    { status: 'inProgress' },
    sessionToken,
  );
  return data;
};

const submitSolution = async (payload: any, sessionToken?: string) => {
  try {
    const { data } = await HttpService.post<any>(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      `/challenge/submit-solution?challengeId=${payload.get('challengeId')!}&solutionId=${payload.get('solutionId')!}`,
      payload,
      sessionToken,
      undefined,
      { 'content-type': 'multipart/form-data' },
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

const getAllSolutions = async (
  queryParams: {page: number, limit: number, status?: SolutionStatus, eventId?: string | null, userId?: string | null},
  sessionToken?: string,
) => {
  try {
    const { data } = await HttpService.get<IGetAllSolutionsResponse>(
      'challenge/get-all-solution',
      sessionToken,
      queryParams,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

const getAllChallenges = async (
  queryParams?: {page: number, limit: number, searchTerm?: string},
  sessionToken?: string,
) => {
  try {
    const { data } = await HttpService.get<GetAllChallengesResponse>(
      'challenge/get-all',
      sessionToken,
      queryParams,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

const createChallenge = async (payload: CreateChallengeRequest,
  sessionToken?: string) => {
  try {
    const { data } = await HttpService.post<{message: string}>(
      'challenge/create',
      payload,
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === HttpStatus.BAD_REQUEST) {
      throw error.response.data;
    }
    throw error;
  }
};

const editChallenge = async (payload: EditChallengeRequest,
  sessionToken?: string) => {
  try {
    const { data } = await HttpService.patch<{message: string}>(
      'challenge/edit',
      payload,
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === HttpStatus.BAD_REQUEST) {
      throw error.response.data;
    }
    throw error;
  }
};

const deleteChallenge = async (payload: { id: string },
  sessionToken?: string) => {
  try {
    const { data } = await HttpService.post<{message: string}>(
      'challenge/delete',
      payload,
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === HttpStatus.BAD_REQUEST) {
      throw error.response.data;
    }
    throw error;
  }
};

const evaluateSolution = async (payload: IEvaluateSolutionRequest, sessionToken?: string) => {
  try {
    const { data } = await HttpService.post<IEvaluateSolutionResponse>(
      'challenge/evaluate',
      payload,
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

const resetChallenge = async (payload: IResetChallengeRequest, sessionToken?: string) => {
  try {
    const { data } = await HttpService.post<{message: string}>(
      'user/challenge/reset',
      payload,
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

const ChallengeService = {
  getUserSingleChallengeSolution,
  getUserChallengesByEvent,
  setChallengeExpiredStatus,
  setSolutionHintSeen,
  startEventChallengeSolution,
  submitSolution,
  getAllSolutions,
  getAllChallenges,
  createChallenge,
  editChallenge,
  deleteChallenge,
  evaluateSolution,
  resetChallenge,
};

export default ChallengeService;
