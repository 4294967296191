// @ts-nocheck
import closeIcon from '../../../../../assets/icons/adminScreen/closeIcon.svg';
import { useForm } from 'react-hook-form';
import SelectBox from '../../../../../components/SelectBox';
import Button from '../../../../../components/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TextField from '../../../../../components/TextField';
import ModalPopup from '../../../../../components/Modal';
import { useAsync } from 'react-use';
import { useEvent } from '../../../../../hooks/useEvent';
import { notification } from 'antd';
import { useUser } from '../../../../../hooks/useUser';
import { useState } from 'react';
import { saveAs } from 'file-saver';

const GenerateInviteCode = (props) => {
  const { setGenerateInvite } = props;
  const [codes, setCodes] = useState();
  const { getAllEvents } = useEvent();
  const { generateInviteCodes } = useUser();

  const { value: events } = useAsync(() => getAllEvents(), []);

  const schema = yup.object({
    codeNumber: yup.number().required('Code number is required').typeError('Code number is required')
      .max(100, 'Maximum 100 codes at a time'),
    selectEvent: yup.object().required('Event Selection is required').nullable(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const generatedCodes = await generateInviteCodes({
        codeNumber: `${data.codeNumber}`, eventId: data.selectEvent._id,
      });
      setCodes(generatedCodes);
      const file = new Blob(generatedCodes.replaceAll(',', '\n.').split('.'), { type: 'text/plain;charset=utf-8' });
      saveAs(file, 'codes.txt');
    } catch (error) {
      notification.success({ message: error?.message || 'Failed to generate invite codes' });
    }
  };

  return (
    <ModalPopup
      fullScreen
    >
      <div className="w-full h-screen pt-8 pb-12 bg-purple-50 flex-col justify-start items-start gap-8 inline-flex">
        <div className="self-stretch px-12 justify-between items-center inline-flex">
          <div className="text-indigo-600 text-xl font-semibold font-['Manrope']">
            Generate Codes
          </div>
          <div
            className="w-8 h-8 relative cursor-pointer"
            onClick={() => setGenerateInvite(false)}
          >
            <img src={closeIcon} alt="icon" />
          </div>
        </div>
        <div className="self-stretch px-12 justify-start items-start gap-[26px] inline-flex">
          <div
            className="w-[800px] self-stretch p-6 bg-white rounded-lg flex-col
              items-start gap-4 inline-flex"
          >
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="w-full">
              <div className="grid grid-cols-12 gap-6 w-full">
                <div className="col-span-12">
                  <TextField
                    name="codeNumber"
                    type="number"
                    label="Enter number of codes"
                    control={control}
                    errors={errors}
                    customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                  />
                </div>
                <div className="col-span-12">
                  <SelectBox
                    name="selectEvent"
                    placeholder="Event Name"
                    label="Select Event"
                    options={events?.events || []}
                    control={control}
                    errors={errors}
                    customLabel="name"
                    customValue="name"
                    customHeight="40px"
                    customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                  />
                </div>
                <div className="col-span-12 flex justify-start">
                  <Button
                    customClass=""
                    variant="primary"
                    type="submit"
                  >
                    <div className="text-white text-base font-bold font-['Manrope']">Generate Codes</div>
                  </Button>
                </div>
              </div>
            </form>
          </div>
          {!!codes && (
            <div
              className="w-[800px] self-stretch p-6 bg-white rounded-lg flex-col
                items-start gap-4 inline-flex"
            >
              {codes.split(',').map((code) => (
                <div key={code} className="text-indigo-600">
                  {code}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </ModalPopup>
  );
};

export default GenerateInviteCode;
