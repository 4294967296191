// @ts-nocheck
import ModalPopup from '../../../../components/Modal';
import closeIcon from '../../../../assets/icons/adminScreen/closeIcon.svg';
import { useForm } from 'react-hook-form';
import TextField from '../../../../components/TextField';
import SelectBox from '../../../../components/SelectBox';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '../../../../components/Button';
import TextEditor from '../../../../components/TextEditor';
import { notification } from 'antd';
import { useChallenge } from '../../../../hooks/useChallenge';
import { useCategories } from '../../../../hooks/useCategory';
import { useAsync } from 'react-use';
import { useCallback, useEffect } from 'react';

export const challengeComplexity = [
  {
    name: 'Low',
    value: 'low',
  },
  {
    name: 'Medium',
    value: 'medium',
  },
  {
    name: 'High',
    value: 'high',
  },
  {
    name: 'Very High',
    value: 'very high',
  },
];

const ChallengeForm = (props) => {
  const {
    formPopup,
    setFormPopup,
    refreshChallenges,
  } = props;
  const { createChallenge, editChallenge } = useChallenge();
  const { getAllCategories } = useCategories();
  const schema = yup.object({
    title: yup.string().required('Title is required field').nullable(),
    detail: yup.string().required('Problem Statement is required field').nullable(),
    hint: yup.string().required('Challenge Hint is required field').nonNullable(),
    score: yup.number().required('Score is required field').nullable(),
    complexity: yup.object().required('Complexity is required field').nonNullable('Complexity is required field'),
    category: yup.object().required('Category is required field').nonNullable('Category is required field'),
    duration: yup.number().required('Category is required field').nonNullable('Category is required field'),
  });

  const { value: challengeCategories, loading } = useAsync(() => getAllCategories(), []);

  const handleComplexity = () => (
    challengeComplexity?.find((item) => item?.value === formPopup?.formData?.complexity));

  const handleCategory = useCallback(() => (
    challengeCategories?.categories?.find((item) => item?._id === formPopup?.formData?.category)),
  [challengeCategories?.categories, formPopup?.formData?.category]);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...formPopup?.formData,
      complexity: handleComplexity(),
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (challengeCategories?.categories && !loading) {
      const category = handleCategory();
      setValue('category', category);
    }
  }, [challengeCategories?.categories, handleCategory, loading, setValue]);

  const onSubmit = async (data) => {
    try {
      const request = {
        ...data,
        category: data.category?._id,
        id: formPopup.formData?._id,
        type: data.eventType,
        complexity: data.complexity?.value,
      };

      let response;

      if (formPopup.formData) {
        response = await editChallenge(request);
      } else {
        response = await createChallenge(request);
      }
      notification.success({
        message: response?.message || `Successfully ${formPopup.formData ? 'updated' : 'created'} challenge`,
        duration: 3,
      });
      if (refreshChallenges) {
        refreshChallenges();
      }
      setFormPopup({ formData: null, openPopup: false });
    } catch (error) {
      notification.error({
        message: error?.message || `Failed to ${formPopup.formData ? 'edit' : 'create'} challenge`,
        duration: 3,
      });
    }
  };

  return (
    <ModalPopup fullScreen>
      <div
        className="w-full h-full pt-8 pb-12 bg-purple-50 flex-col justify-start
          items-start gap-8 inline-flex overflow-auto"
      >
        <div className="self-stretch px-12 justify-between items-center inline-flex">
          <div
            className="text-indigo-600 text-xl font-semibold font-['Manrope']"
          >
            {formPopup?.formData ? 'Edit Challenge' : 'New Challenge'}
          </div>
          <div
            className="w-8 h-8 relative cursor-pointer"
            onClick={() => setFormPopup({ formData: null, openPopup: false })}
          >
            <img src={closeIcon} alt="icon" />
          </div>
        </div>
        <div className="self-stretch h-full px-12 flex-col justify-start items-start gap-[26px] flex">
          <div
            className="self-stretch h-full p-6 bg-white rounded-lg flex-col justify-center items-start gap-4 flex"
          >
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="w-full gap-6">
              <div className="grid grid-cols-12 gap-6 w-full">
                <div className="col-span-12">
                  <TextField
                    name="title"
                    type="text"
                    label="Title"
                    control={control}
                    errors={errors}
                    customHeight="40px"
                    customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                  />
                </div>
                <div className="col-span-12 self-stretch h-auto flex-col justify-end items-start gap-6 flex">
                  <div className="self-stretch h-auto flex-col justify-center items-start gap-2 flex">
                    <div className="text-zinc-700 text-sm font-normal font-['Manrope']">Problem Statement</div>
                    <div className="w-full">
                      <TextEditor
                        control={control}
                        errors={errors}
                        name="detail"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-12 self-stretch h-auto flex-col justify-end items-start gap-6 flex">
                  <div className="self-stretch h-auto flex-col justify-center items-start gap-2 flex">
                    <div className="text-zinc-700 text-sm font-normal font-['Manrope']">Challenge Hint</div>
                    <div className="w-full">
                      <TextEditor
                        control={control}
                        errors={errors}
                        name="hint"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-12 self-stretch h-auto flex-col justify-end items-start gap-6 flex">
                  <div className="self-stretch h-auto flex-col justify-center items-start gap-2 flex">
                    <div className="text-zinc-700 text-sm font-normal font-['Manrope']">Prerequisite</div>
                    <div className="w-full">
                      <TextEditor
                        control={control}
                        errors={errors}
                        name="prerequisite"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-12">
                  <div className="grid grid-cols-12 w-full gap-6">
                    <div className="col-span-4">
                      <TextField
                        name="score"
                        type="number"
                        label="Score"
                        control={control}
                        errors={errors}
                        customHeight="40px"
                        customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                      />
                    </div>
                    <div className="col-span-4">
                      <TextField
                        name="duration"
                        type="number"
                        label="Duration"
                        control={control}
                        errors={errors}
                        customHeight="40px"
                        customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                      />
                    </div>
                    <div className="col-span-4">
                      <SelectBox
                        name="complexity"
                        placeholder="Select Complexity Level"
                        label="Complexity"
                        options={challengeComplexity || []}
                        control={control}
                        errors={errors}
                        customLabel="name"
                        customValue="value"
                        customHeight="40px"
                        isClearable
                        customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-12">
                  <SelectBox
                    name="category"
                    placeholder="Select Category"
                    label="Category"
                    options={challengeCategories?.categories || []}
                    control={control}
                    errors={errors}
                    customLabel="title"
                    customValue="_id"
                    customHeight="40px"
                    isClearable
                    customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                  />
                </div>
                <div className="col-span-12 flex justify-start">
                  <Button
                    type="submit"
                  >
                    <div className="text-white text-base font-bold font-['Manrope']">Create</div>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};

export default ChallengeForm;
