// @ts-nocheck
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import { useAsync } from 'react-use';
import { useEvent } from '../../../hooks/useEvent';
import MarkdownView from '../../../components/MarkdownView/MarkdownView';
import { useAuthentication } from '../../../contexts/AuthContext';

const EventOverview = () => {
  const navigate = useNavigate();
  const { selectedEvent } = useAuthentication();

  const { getEventByIdWithChallenges } = useEvent();
  const { value: eventDescription = [] } = useAsync(() =>
    getEventByIdWithChallenges(selectedEvent?._id), [selectedEvent]);

  return (
    <>
      {eventDescription?.url && <img className="w-full h-[259.46px]" src={eventDescription?.url} alt="" />}
      <div className="self-stretch w-full text-black text-left text-opacity-75 text-base
        font-medium font-['Manrope'] overflow-scroll"
      >
        {eventDescription?.longDescription && (
          <MarkdownView
            className="event-description ps-4 pt-3"
            linkTarget="_blank"
            value={eventDescription?.longDescription}
          />
        )}
        {eventDescription?.curiculam && (
          <>
            <div
              className="text-indigo-600 text-xl font-semibold font-['Manrope'] mt-4"
            >
              Other Details
            </div>
            <MarkdownView
              className="event-description ps-4 pt-3"
              linkTarget="_blank"
              value={eventDescription?.curiculam}
            />
          </>
        )}
      </div>
      {selectedEvent?.type?.type !== 'VULNERABILITY_DISCLOSURE_EVENT' ? (
        <div className="justify-start items-start gap-6 inline-flex text-left">
          <Button
            variant="primary"
            onClick={() => navigate('/user/challenges')}
          >
            <div className="text-white text-base font-bold font-['Manrope']">Take Challenges</div>
          </Button>
        </div>
      ) : (
        <div className="justify-start items-start gap-6 inline-flex text-left">
          <Button
            variant="primary"
            onClick={() => navigate('/user/reports/submit-report')}
          >
            <div className="text-white text-base font-bold font-['Manrope']">Submit Report</div>
          </Button>
        </div>
      )}
    </>
  );
};

export default EventOverview;
