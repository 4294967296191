/* eslint-disable quotes */
// @ts-nocheck
import TextField from '../../components/TextField';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import cn from 'classnames';
import { useAuthentication } from '../../contexts/AuthContext';
import { notification } from 'antd';

const LoginForm = (props) => {
  const { setSelectedForm } = props;
  const { getSessionToken } = useAuthentication();

  const schema = yup.object({
    email: yup.string().required('Email is required').email('Enter valid email').nullable(),
    password: yup.string().required('Password is required').nullable(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      await getSessionToken(data.email, data.password);
    } catch (error) {
      notification.error({ message: error?.message, duration: 5 });
    }
  };

  return (
    <div
      className={cn('font-Manrope h-full w-full rounded-[16px] bg-cover bg-center',
        `bg-[url(assets/images/loginFormBgGradientImg.svg)]`,
      )}
    >
      <div
        className="pt-[40px] flex items-center justify-center text-[28px] font-[700] text-[#030303] text-center"
      >
        Login Here !!!
      </div>
      <div
        className="px-[40px] py-[24px]"
      >
        <hr className="bg-clip-border bg-[#42E8E0] border-1 border-[#3B4DBC]" />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="px-[40px] gap-[24px]">
          <div className="">
            <TextField
              name="email"
              type="email"
              placeholder="Enter your email address"
              label="Your Email Id*"
              control={control}
              errors={errors}
              customHeight="56px"
              customlabelclass="leading-[42px] text-[20px]"
            />
          </div>
          <div className="">
            <TextField
              name="password"
              type="password"
              placeholder="Enter your password"
              label="Password*"
              control={control}
              errors={errors}
              customHeight="56px"
              customlabelclass="leading-[42px] text-[20px]"
            />
          </div>
        </div>
        <div className="flex justify-center items-center pt-[32px] mx-16">
          <button
            type="submit"
            className="w-[486px] h-[59px] px-10 py-4 bg-gradient-to-b from-indigo-600 to-indigo-800
              rounded-md justify-center items-center gap-[12.72px] inline-flex"
          >
            <span className="text-white text-xl font-bold font-['Manrope']">Login</span>
          </button>
        </div>
      </form>
      <div className="pt-8 pb-10 flex items-center justify-center">
        <span
          className="text-zinc-800 text-base font-medium font-['Manrope'] leading-normal"
        >
          New to Vantagepoint?
        </span>
        <span
          className="text-indigo-700 text-base font-semibold font-['Manrope'] leading-normal"
        >
&nbsp;
          {' '}

        </span>
        <span
          className="text-indigo-700 text-base font-semibold font-['Manrope'] underline leading-normal cursor-pointer"
          onClick={() => setSelectedForm('Register')}
        >
          Register Now
        </span>
      </div>
    </div>
  );
};

export default LoginForm;
