// @ts-nocheck
import { useState, useMemo } from 'react';
import { useAsyncRetry } from'react-use';
import { notification } from 'antd';
import dashboardCardTopRightGradient from '../../../assets/images/dashboardCardTopRightGradient.svg';
import selectedBadgeIcon from '../../../assets/icons/selectedBadgeIcon.svg';
import unSelectedBadgeIcon from '../../../assets/icons/unSelectedBadgeIcon.svg';
import selectedCertificateIcon from '../../../assets/icons/selectedCertificateIcon.svg';
import unSelectedCertificateIcon from '../../../assets/icons/unSelectedCertificateIcon.svg';
import HeaderComp from '../HeaderComp';
import FooterComp from '../FooterComp';
import backIcon from '../../../assets/icons/backIcon.svg';
import EventSection from './eventSection';
import { useNavigate, useParams } from 'react-router';
import cn from 'classnames';
import Certificates from './certificates';
import Badges from './badges';
import Button from '../../../components/Button';
import ShareConfirmation from './shareConfirmation';
import { useAchievements } from '../../../hooks/useAchievement';
import { AuthStatus, useAuthentication } from '../../../contexts/AuthContext';
import { UserRole } from '../../../services/UserService/types';
import { AchievementType } from '../../../services/AchievementService/types';
import { useUser } from '../../../hooks/useUser';
import CertifiedSkills from './certifiedSkills';

const Achievements = () => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState('certificates');
  const [shareConfirm, setShareConfirm] = useState(false);
  const params = useParams();
  const { status, sessionToken } = useAuthentication();
  const { getUserWithAchievements } = useAchievements();
  const { updateUserProfileSettings } = useUser();
  const userId = params?.userId;
  const { value: userWithAchievements, retry: refreshUserAchievements } = useAsyncRetry(() => getUserWithAchievements(userId), []);
  const isAuthenticated = (status === AuthStatus.AUTHENTICATED && sessionToken?.user.role === UserRole.User);

  const hasPublicProfile = userWithAchievements?.userWithEvents?.hasPublicProfile;

  const userFullName = userWithAchievements?.userWithEvents?.userFullName
    || userWithAchievements?.achievements?.[0]?.userFullName
    || sessionToken?.user?.userName;

  const achievements = useMemo(() => {
    return userWithAchievements?.achievements?.reduce((acc, achievement) => {
      if (achievement.type === AchievementType.CERTIFICATE) {
        acc.certificates.push(achievement);
      } else if (achievement.type === AchievementType.BADGE) {
        acc.badges.push(achievement);
      }
      acc.certifiedSkills = [...new Set([...acc.certifiedSkills, ...achievement.certifiedSkills])]
      return acc;
    }, { certificates: [], badges: [], certifiedSkills: [] });
  }, [userWithAchievements])

  const achievementsOption = [
    {
      selectedIcon: selectedCertificateIcon,
      unSelectedIcon: unSelectedCertificateIcon,
      label: 'Awarded Certifications',
      value: 'certificates',
    },
    {
      selectedIcon: selectedBadgeIcon,
      unSelectedIcon: unSelectedBadgeIcon,
      label: 'Awarded Badges',
      value: 'badges',
    }
  ];

  const onActivateProfile = async () => {
    try {
      await updateUserProfileSettings({hasPublicProfile: !hasPublicProfile});
      notification.success({
        message: !hasPublicProfile
          ? 'Your profile is now set to public profile, you can share this page url to others to see your certificates'
          : 'Your profile is now set to private',
        duration: 5,
      });
      refreshUserAchievements();
    } catch (error) {
      notification.error({
        message: error?.message || error?.response?.message || 'Failed to update profile settings',
        duration: 3,
      });
    }
  };

  return (
    <>
      <HeaderComp
        showEventOptions={false}
        showUserName={true}
        headerTitle={true}
        headerTitleName="Achievements"
        hideHeaderIcons={!isAuthenticated}
        userFullName={userFullName}
      />
      {shareConfirm && (
        <ShareConfirmation
          shareConfirm={shareConfirm}
          setShareConfirm={setShareConfirm}
        />
      )}
      <div className="relative w-full h-auto px-12 justify-between items-start inline-flex py-[32px] bg-[#F7F7FF]">
        <div className="absolute w-[347px] h-[300px] bottom-[32px] right-0 bg-no-repeat bg-[url('assets/images/dashboardCardBgImage.svg')] bg-start" />
        <div className='gap-auto w-full z-10'>
          {isAuthenticated && (
            <div className="self-stretch relative">
              <div className='flex items-center gap-3 justify-start pb-8 cursor-pointer w-[200px]' onClick={() => navigate('/user/event-overview')}>
                <img src={backIcon} alt='icon' width='24px' height='24px' />
                <div className="text-indigo-600 text-left text-base font-bold font-['Manrope']">
                  Return to dashboard
                </div>
              </div>
              <div className='flex justify-start items-center absolute right-0 top-0'>
                <Button
                  type='button'
                  variant='primary'
                  onClick={() => onActivateProfile()}
                >
                  <div className="text-white text-base font-bold font-['Manrope']">
                    {hasPublicProfile ? 'Make Profile Private' : "Make Profile Public"}
                  </div>
                </Button>
              </div>
            </div>
          )}
          <div className='w-fit grid grid-row-12 grid-flow-col gap-4'>
            {achievementsOption?.map((item) => (
              <div
                onClick={() => setSelectedTab(item?.value)}
                className={cn("cursor-pointer p-3 justify-start items-start gap-2 flex mb-2 rounded-[16px]",
                  (selectedTab === item?.value) ? 'bg-[#12205c]' : 'bg-[#1876d2]')}
              >
                <div className={cn("w-5 h-5 relative", item?.value === 'badges' ? 'p-[3px]' : '')}>
                  <img src={(selectedTab === item?.value) ? item?.selectedIcon : item?.selectedIcon} alt='icon' />
                </div>
                <div className={cn("text-base font-semibold font-['Manrope'] text-center text-white")}>{item.label}</div>
              </div>
            ))}
          </div>
          <div className="mb-10">
            <img src={dashboardCardTopRightGradient} alt='img' className='absolute right-0 top-0'/>
            <div
              className={cn(
                'font-Manrope h-auto w-full p-6 rounded-[16px]',
                'bg-cover bg-center snap-center relative shadow-xl shadow-[#b6b6fa]',
                `bg-[url(assets/images/loginCourseBgImage.svg)]`,
              )}
            >
              {selectedTab === "certificates" ? <Certificates certificateList={achievements?.certificates} /> : <Badges badgesList={achievements?.badges} />}
            </div>
          </div>
          <CertifiedSkills
            certifiedSkillsList={achievements?.certifiedSkills}
          />
          {hasPublicProfile && !isAuthenticated && (
            <div className="mt-10">
              <EventSection userEvents={userWithAchievements?.userWithEvents?.events} />
            </div>
          )}
        </div>
      </div>
      <FooterComp />
    </>
  )
}

export default Achievements;