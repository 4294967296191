// @ts-nocheck
import { useState } from 'react';
import scoreIcon from '../../../assets/icons/dashboardScreen/scoreIcon.svg';
import rankIcon from '../../../assets/icons/dashboardScreen/rankIcon.svg';
import pointsIcon from '../../../assets/icons/dashboardScreen/pointsIcon.svg';
import challengeIcon from '../../../assets/icons/dashboardScreen/challengeIcon.svg';
import clockIcon from '../../../assets/icons/dashboardScreen/clockIcon.svg';
import starIcon from '../../../assets/icons/dashboardScreen/starIcon.svg';
import closeIcon from '../../../assets/icons/closeIcon.svg';
import { useNavigate } from 'react-router-dom';
import ModalPopup from '../../../components/Modal';
import Button from '../../../components/Button';
import { useAsync } from 'react-use';
import { useChallenge } from '../../../hooks/useChallenge';
import { useEvent } from '../../../hooks/useEvent';
import MarkdownView from '../../../components/MarkdownView/MarkdownView';
import { useAuthentication } from '../../../contexts/AuthContext';
import challengePlaceholder from '../../../assets/images/challengePlaceholder.jpeg';

const Challenges = () => {
  const { selectedEvent } = useAuthentication();
  const navigate = useNavigate();
  const [selectedChallenge, setSelectedChallenge] = useState();
  const [preRequisitePopup, setPreRequisitePopup] = useState(false);

  const { getUserChallengesByEvent } = useChallenge();
  const { value: challengesDetails = [] } = useAsync(() => getUserChallengesByEvent(selectedEvent?._id), []);

  const { getEventLeaderBoard } = useEvent();
  const { value: leaderBoardData = [] } = useAsync(() => getEventLeaderBoard(selectedEvent?._id), []);

  const { startEventChallengeSolution } = useChallenge();

  const onGoingChallenges = challengesDetails?.onGoingChallenges;
  const availableChallenges = challengesDetails?.availableChallenges;
  const completedChallenges = challengesDetails?.completedChallenges;
  const expiredChallenges = challengesDetails?.expiredChallenges;

  const getRankSuffix = (rank) => {
    if (rank >= 11 && rank <= 13) {
      return 'th';
    }

    const lastDigit = rank % 10;

    switch (lastDigit) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  const startSolution = async (challengeId) => {
    await startEventChallengeSolution(selectedEvent?._id, challengeId);
    navigate(`/user/challenge/${challengeId}/solution`);
  };

  return (
    <>
      {preRequisitePopup && (
        <ModalPopup>
          <div
            className="self-stretch w-full h-full p-8 rounded-lg shadow flex-col justify-start items-start
              gap-6 inline-flex relative"
          >
            <div className="w-6 h-6 absolute right-2 top-2 cursor-pointer" onClick={() => setPreRequisitePopup(false)}>
              <img src={closeIcon} alt="icon" />
            </div>
            <div className="text-black text-xl font-medium font-['Manrope']">
              Prerequisites to solve this challenge
            </div>
            <div className="self-stretch text-left">
              <MarkdownView
                linkTarget="_blank"
                value={selectedChallenge?.challenge?.prerequisite || selectedChallenge?.prerequisite}
              />
            </div>
          </div>
        </ModalPopup>
      )}
      <>
        <div className="w-full opacity-80 text-black text-xl font-bold font-['Manrope'] tracking-tight text-left">
          Highlights
        </div>
        <div className="grid grid-cols-12 gap-[16px] text-left w-full pb-[48px]">
          <div
            className="sm:col-span-6 md:col-span-6 lg:col-span-3 col-span-3 aspect-[1.1/1] 2xl:w-[220px] 2xl:h-[200px]
              p-6 bg-[#DCFBF8] rounded-2xl justify-between items-start inline-flex"
          >
            <div className="self-stretch grow shrink basis-0 flex-col justify-between items-start flex">
              <div className="w-8 h-8 relative">
                <img src={rankIcon} alt="icon" />
              </div>
              <div className="self-stretch h-[70px] flex-col justify-start items-start gap-2 flex">
                <div className="self-stretch">
                  <span
                    className="text-indigo-600 text-[40px] font-normal font-['Poppins'] leading-[42px]"
                  >
                    {leaderBoardData?.currentUserRank}
                  </span>
                  <span
                    className="text-indigo-600 text-xl font-normal font-['Poppins'] leading-[42px]"
                  >
                    {getRankSuffix(leaderBoardData?.currentUserRank)}
                  </span>
                </div>
                <div
                  className="self-stretch opacity-60 text-black text-sm font-normal font-['Poppins'] leading-tight"
                >
                  Your Rank
                </div>
              </div>
            </div>
          </div>
          <div
            className="sm:col-span-6 md:col-span-6 lg:col-span-3 col-span-3 aspect-[1.1/1] 2xl:w-[220px] 2xl:h-[200px]
              p-6 bg-[#E3E7FF] rounded-2xl justify-between items-start inline-flex"
          >
            <div className="self-stretch grow shrink basis-0 flex-col justify-between items-start flex">
              <div className="self-stretch grow shrink basis-0 flex-col justify-between items-start flex">
                <div className="w-8 h-8 relative">
                  <img src={scoreIcon} alt="icon" />
                </div>
                <div className="self-stretch h-[70px] flex-col justify-start items-start gap-2 flex">
                  <div className="self-stretch">
                    {leaderBoardData?.currentUserEventHilights?.bestScoreInPercentage ? (
                      <>
                        <span className="text-indigo-600 text-[40px] font-normal font-['Poppins'] leading-[42px]">
                          {leaderBoardData?.currentUserEventHilights?.bestScoreInPercentage}
                        </span>
                        <span className="text-indigo-600 text-xl font-normal font-['Poppins'] leading-[42px]">
                          %
                        </span>
                      </>
                    ) : (
                      <span className="text-indigo-600 text-[40px] font-normal font-['Poppins'] leading-[42px]">
                        0
                      </span>
                    )}
                  </div>
                  <div
                    className="self-stretch opacity-60 text-black text-sm font-normal font-['Poppins'] leading-tight"
                  >
                    Top Score
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="sm:col-span-6 md:col-span-6 lg:col-span-3 col-span-3 aspect-[1.1/1] 2xl:w-[220px] 2xl:h-[200px]
              p-6 bg-[#D9FCFE] rounded-2xl justify-between items-start inline-flex"
          >
            <div className="self-stretch grow shrink basis-0 flex-col justify-between items-start flex">
              <div className="self-stretch grow shrink basis-0 flex-col justify-between items-start flex">
                <div className="w-8 h-8 relative">
                  <img src={pointsIcon} alt="icon" />
                </div>
                <div className="self-stretch h-[70px] flex-col justify-start items-start gap-2 flex">
                  <div
                    className="self-stretch text-indigo-600 text-[40px] font-normal font-['Poppins'] leading-[42px]"
                  >
                    {leaderBoardData?.currentUserEventHilights?.maxScoreOfBestScoredChallenge
                      && leaderBoardData?.currentUserEventHilights?.totalScore
                      ? (
                        <>
                          {leaderBoardData?.currentUserEventHilights?.totalScore}
                          {' '}
                          /
                          {leaderBoardData?.currentUserEventHilights?.totalMaxScoreOfAllChallenges}
                        </>
                      ) : 'N/A'}
                  </div>
                  <div
                    className="self-stretch opacity-60 text-black text-sm font-normal font-['Poppins'] leading-tight"
                  >
                    Total Points
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="sm:col-span-6 md:col-span-6 lg:col-span-3 col-span-3 aspect-[1.1/1] 2xl:w-[220px] 2xl:h-[200px]
              p-6 bg-[#D9EBFB] rounded-2xl justify-between items-start inline-flex"
          >
            <div className="self-stretch grow shrink basis-0 flex-col justify-between items-start flex">
              <div className="self-stretch grow shrink basis-0 flex-col justify-between items-start flex">
                <div className="w-8 h-8 relative">
                  <img src={challengeIcon} alt="icon" />
                </div>
                <div className="self-stretch h-[70px] flex-col justify-start items-start gap-2 flex">
                  <div
                    className="self-stretch text-indigo-600 text-[40px] font-normal font-['Poppins'] leading-[42px]"
                  >
                    {completedChallenges?.length || 0}
                  </div>
                  <div
                    className="self-stretch opacity-60 text-black text-sm font-normal font-['Poppins'] leading-tight"
                  >
                    Completed Challenges
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      {onGoingChallenges?.length > 0 && (
        <div className="flex-cols">
          <div
            className="w-full pb-[16px] opacity-80 text-black text-xl font-bold font-['Manrope']
             tracking-tight text-left"
          >
            {`Ongoing Challenges (${(onGoingChallenges?.length)})`}
          </div>
          <div className="flex-1 gap-[24px]">
            {onGoingChallenges?.map((item, index) => (
              <div
                key={index}
                className="w-[385px] h-full justify-start items-start inline-flex mr-6 mb-6"
              >
                <div
                  className="pl-8 pr-6 py-6 bg-white rounded-2xl border border-indigo-100 flex-col
                    justify-start items-start gap-6 inline-flex relative border-l-[8px] border-l-[#2AF6FF]"
                >
                  <div className="self-stretch justify-start items-center gap-3 inline-flex">
                    <img
                      className="w-[83.32px] h-[62.31px] rounded-lg"
                      src={item?.challenge?.category?.url || challengePlaceholder}
                      alt="img"
                    />
                    <div className="grow shrink basis-0 text-black text-base font-medium font-['Manrope'] break-all">
                      {item?.challenge?.title}
                    </div>
                  </div>
                  <div className="justify-start items-start gap-3 inline-flex">
                    <div
                      className="px-3 py-0.5 bg-gradient-to-r from-cyan-50 to-indigo-50 rounded-[32px]
                        justify-start items-start gap-2.5 flex"
                    >
                      <div className="opacity-60 text-black text-sm font-semibold font-['Manrope'] leading-normal">
                        {item?.challenge?.category?.title}
                      </div>
                    </div>
                  </div>
                  <div className="flex-col justify-start items-start gap-3 flex">
                    <div className="justify-start items-center gap-4 inline-flex">
                      <div className="opacity-60 justify-start items-center gap-3 flex">
                        <div className="w-5 h-5 relative">
                          <img src={clockIcon} alt="icon" />
                        </div>
                        <div className="text-black text-base font-normal font-['Manrope'] leading-tight">
                          Duration
                        </div>
                      </div>
                      <div className="text-black text-base font-normal font-['Manrope'] leading-tight">
                        {item?.challenge?.duration}
                        {' '}
                        Min
                      </div>
                    </div>
                    <div className="justify-start items-center gap-4 inline-flex">
                      <div className="h-5 opacity-60 justify-start items-center gap-3 flex">
                        <div className="w-5 h-5 relative">
                          <img src={starIcon} alt="icon" />
                        </div>
                        <div className="text-black text-base font-normal font-['Manrope'] leading-tight">
                          Points
                        </div>
                      </div>
                      <div className="text-black text-base font-normal font-['Manrope'] leading-tight">
                        {item?.challenge?.score}
                      </div>
                    </div>
                  </div>
                  <div className="justify-start items-start gap-6 inline-flex">
                    <Button
                      customClass=""
                      variant="primary-outline"
                      onClick={() => { setPreRequisitePopup(true); setSelectedChallenge(item); }}
                    >
                      <div className="text-indigo-600 text-base font-bold font-['Manrope']">Prerequisite</div>
                    </Button>
                    <Button
                      customClass="w-[145px] h-[48px] !px-2"
                      variant="primary"
                      disabled={!item?.event?.active}
                      onClick={() => navigate(`/user/challenge/${item?.challenge?._id}/solution`)}
                    >
                      <div className="text-white text-base font-bold font-['Manrope']">Solve Now</div>
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {availableChallenges?.length > 0 && (
        <div className="flex-cols">
          <div
            className="w-full pb-[16px] opacity-80 text-black text-xl font-bold font-['Manrope']
              tracking-tight text-left"
          >
            {`Available Challenges (${(availableChallenges?.length)})`}
          </div>
          <div className="flex-1 gap-[24px]">
            {availableChallenges?.map((item, index) => (
              <div
                key={index}
                className="w-[385px] h-full justify-start items-start inline-flex mr-6 mb-6"
              >
                <div
                  className="pl-8 pr-6 py-6 bg-white rounded-2xl border border-indigo-100
                    flex-col justify-start items-start gap-6 inline-flex relative border-l-[8px] border-l-[#91A1FE]"
                >
                  <div className="self-stretch justify-start items-center gap-3 inline-flex">
                    <img
                      className="w-[83.32px] h-[62.31px] rounded-lg"
                      src={item?.category?.url || challengePlaceholder}
                      alt="img"
                    />
                    <div className="grow shrink basis-0 text-black text-base font-medium font-['Manrope'] break-all">
                      {item?.title}
                    </div>
                  </div>
                  <div className="justify-start items-start gap-3 inline-flex">
                    <div
                      className="px-3 py-0.5 bg-gradient-to-r from-cyan-50 to-indigo-50 rounded-[32px]
                        justify-start items-start gap-2.5 flex"
                    >
                      <div className="opacity-60 text-black text-sm font-semibold font-['Manrope'] leading-normal">
                        {item?.category?.title}
                      </div>
                    </div>
                  </div>
                  <div className="flex-col justify-start items-start gap-3 flex">
                    <div className="justify-start items-center gap-4 inline-flex">
                      <div className="opacity-60 justify-start items-center gap-3 flex">
                        <div className="w-5 h-5 relative">
                          <img src={clockIcon} alt="icon" />
                        </div>
                        <div className="text-black text-base font-normal font-['Manrope'] leading-tight">
                          Duration
                        </div>
                      </div>
                      <div className="text-black text-base font-normal font-['Manrope'] leading-tight">
                        {item?.duration}
                        {' '}
                        Min
                      </div>
                    </div>
                    <div className="justify-start items-center gap-4 inline-flex">
                      <div className="h-5 opacity-60 justify-start items-center gap-3 flex">
                        <div className="w-5 h-5 relative">
                          <img src={starIcon} alt="icon" />
                        </div>
                        <div className="text-black text-base font-normal font-['Manrope'] leading-tight">
                          Points
                        </div>
                      </div>
                      <div className="text-black text-base font-normal font-['Manrope'] leading-tight">
                        {item?.score}
                      </div>
                    </div>
                  </div>
                  <div className="justify-start items-start gap-6 inline-flex">
                    <Button
                      customClass=""
                      variant="primary-outline"
                      onClick={() => { setPreRequisitePopup(true); setSelectedChallenge(item); }}
                    >
                      <div className="text-indigo-600 text-base font-bold font-['Manrope']">Prerequisite</div>
                    </Button>
                    <Button
                      customClass="w-[145px] h-[48px] !px-2"
                      variant="primary"
                      disabled={!selectedEvent?.active}
                      onClick={() => startSolution(item?._id)}
                    >
                      <div className="text-white text-base font-bold font-['Manrope']">Solve Now</div>
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {completedChallenges?.length > 0 && (
        <div className="flex-cols">
          <div
            className="w-full pb-[16px] opacity-80 text-black text-xl font-bold font-['Manrope']
              tracking-tight text-left"
          >
            {`Completed Challenges (${(completedChallenges?.length)})`}
          </div>
          <div className="flex-1 gap-[24px]">
            {completedChallenges?.map((item, index) => (
              <div
                key={index}
                className="w-[385px] h-full justify-start items-start inline-flex mr-6 mb-6"
              >
                <div
                  className="pl-8 pr-6 py-6 bg-white rounded-2xl border border-indigo-100 flex-col
                    justify-start items-start gap-6 inline-flex relative border-l-[8px] border-l-[#219653]"
                >
                  <div className="self-stretch justify-start items-center gap-3 inline-flex">
                    <img
                      className="w-[83.32px] h-[62.31px] rounded-lg"
                      src={item?.challenge?.category?.url || challengePlaceholder}
                      alt="img"
                    />
                    <div className="grow shrink basis-0 text-black text-base font-medium font-['Manrope'] break-all">
                      {item?.challenge?.title}
                    </div>
                  </div>
                  <div className="justify-start items-start gap-3 inline-flex">
                    <div
                      className="px-3 py-0.5 bg-gradient-to-r from-cyan-50 to-indigo-50 rounded-[32px]
                       justify-start items-start gap-2.5 flex"
                    >
                      <div className="opacity-60 text-black text-sm font-semibold font-['Manrope'] leading-normal">
                        {item?.challenge?.category?.title}
                      </div>
                    </div>
                  </div>
                  <div className="flex-col justify-start items-start gap-3 flex">
                    <div className="justify-start items-center gap-4 inline-flex">
                      <div className="opacity-60 justify-start items-center gap-3 flex">
                        <div className="w-5 h-5 relative">
                          <img src={clockIcon} alt="icon" />
                        </div>
                        <div className="text-black text-base font-normal font-['Manrope'] leading-tight">
                          Duration
                        </div>
                      </div>
                      <div className="text-black text-base font-normal font-['Manrope'] leading-tight">
                        {item?.challenge?.duration}
                        {' '}
                        Min
                      </div>
                    </div>
                    <div className="justify-start items-center gap-4 inline-flex">
                      <div className="h-5 opacity-60 justify-start items-center gap-3 flex">
                        <div className="w-5 h-5 relative">
                          <img src={starIcon} alt="icon" />
                        </div>
                        <div className="text-black text-base font-normal font-['Manrope'] leading-tight">
                          Points
                        </div>
                      </div>
                      <div className="text-black text-base font-normal font-['Manrope'] leading-tight">
                        {item?.challenge?.score}
                      </div>
                    </div>
                  </div>
                  <div className="justify-start items-start gap-6 inline-flex">
                    <Button
                      customClass=""
                      variant="primary-outline"
                      onClick={() => { setPreRequisitePopup(true); setSelectedChallenge(item); }}
                    >
                      <div className="text-indigo-600 text-base font-bold font-['Manrope']">Prerequisite</div>
                    </Button>
                    <Button
                      customClass="w-[145px] h-[48px] !px-2"
                      variant="primary"
                      disabled={!item?.event?.active}
                      onClick={() => navigate(`/user/challenge/${item?.challenge?._id}/solution`)}
                    >
                      <div className="text-white text-base font-bold font-['Manrope']">Solve Now</div>
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {expiredChallenges?.length > 0 && (
        <div className="flex-cols">
          <div
            className="w-full pb-[16px] opacity-80 text-black text-xl font-bold
              font-['Manrope'] tracking-tight text-left"
          >
            {`Expired Challenges (${(expiredChallenges?.length)})`}
          </div>
          <div className="flex-1 gap-[24px]">
            {expiredChallenges?.map((item, index) => (
              <div
                key={index}
                className="w-[385px] h-full justify-start items-start inline-flex mr-6 mb-6"
              >
                <div
                  className="pl-8 pr-6 py-6 bg-white rounded-2xl border border-indigo-100
                    flex-col justify-start items-start gap-6 inline-flex relative border-l-[8px] border-l-[#EE8E90]"
                >
                  <div className="self-stretch justify-start items-center gap-3 inline-flex">
                    <img
                      className="w-[83.32px] h-[62.31px] rounded-lg"
                      src={item?.challenge?.category?.url || challengePlaceholder}
                      alt="img"
                    />
                    <div className="grow shrink basis-0 text-black text-base font-medium font-['Manrope'] break-all">
                      {item?.challenge?.title}
                    </div>
                  </div>
                  <div className="justify-start items-start gap-3 inline-flex">
                    <div
                      className="px-3 py-0.5 bg-gradient-to-r from-cyan-50 to-indigo-50 rounded-[32px]
                        justify-start items-start gap-2.5 flex"
                    >
                      <div className="opacity-60 text-black text-sm font-semibold font-['Manrope'] leading-normal">
                        {item?.challenge?.category?.title}
                      </div>
                    </div>
                  </div>
                  <div className="flex-col justify-start items-start gap-3 flex">
                    <div className="justify-start items-center gap-4 inline-flex">
                      <div className="opacity-60 justify-start items-center gap-3 flex">
                        <div className="w-5 h-5 relative">
                          <img src={clockIcon} alt="icon" />
                        </div>
                        <div className="text-black text-base font-normal font-['Manrope'] leading-tight">
                          Duration
                        </div>
                      </div>
                      <div className="text-black text-base font-normal font-['Manrope'] leading-tight">
                        {item?.challenge?.duration}
                        {' '}
                        Min
                      </div>
                    </div>
                    <div className="justify-start items-center gap-4 inline-flex">
                      <div className="h-5 opacity-60 justify-start items-center gap-3 flex">
                        <div className="w-5 h-5 relative">
                          <img src={starIcon} alt="icon" />
                        </div>
                        <div className="text-black text-base font-normal font-['Manrope'] leading-tight">
                          Points
                        </div>
                      </div>
                      <div className="text-black text-base font-normal font-['Manrope'] leading-tight">
                        {item?.challenge?.score}
                      </div>
                    </div>
                  </div>
                  <div className="justify-start items-start gap-6 inline-flex">
                    <Button
                      customClass=""
                      variant="primary-outline"
                      onClick={() => { setPreRequisitePopup(true); setSelectedChallenge(item); }}
                    >
                      <div className="text-indigo-600 text-base font-bold font-['Manrope']">Prerequisite</div>
                    </Button>
                    <Button
                      customClass="w-[145px] h-[48px] !px-2"
                      variant="primary"
                      disabled={!item?.event?.active}
                      onClick={() => navigate(`/user/challenge/${item?.challenge?._id}/solution`)}
                    >
                      <div className="text-white text-base font-bold font-['Manrope']">Solve Now</div>
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Challenges;
