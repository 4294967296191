import { isAxiosError } from 'axios';
import HttpService from '../HttpService';
import { HttpStatus } from '../HttpService/HttpStatus';
import { IAdminDashboard, IGetAllAdminsResponse } from './types';

const getAdminDashboard = async (sessionToken?: string) => {
  try {
    const { data } = await HttpService.get<IAdminDashboard>('dashboard/admin', sessionToken);
    return data;
  } catch (error) {
    throw error;
  }
};

const createAdmin = async (
  payload: { email: string, password: string, userName: string},
  sessionToken?: string,
) => {
  try {
    const { data } = await HttpService.post<string>(
      'user/addAdmin',
      payload,
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === HttpStatus.CONFLICT) {
      throw error.response?.data;
    }
    throw error;
  }
};

const getAllAdmins = async (
  queryParams: { page: number, limit: number, adminSearchTerm?: string},
  sessionToken?: string,
) => {
  try {
    const { data } = await HttpService.get<IGetAllAdminsResponse>(
      'user/get-all-admins',
      sessionToken,
      queryParams,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

const AdminService = {
  getAdminDashboard,
  createAdmin,
  getAllAdmins,
};

export default AdminService;
