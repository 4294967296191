/* eslint-disable max-len */
// @ts-nocheck
import arrowDownIcon from '../../../../assets/icons/adminScreen/arrowDownIcon.svg';
import { useState, useEffect, Fragment } from 'react';
import { Pagination, ConfigProvider } from 'antd';
import cn from 'classnames';
import MarkdownView from '../../../../components/MarkdownView/MarkdownView';
import CONSTANTS from '../../../../constants';
import { useEvent } from '../../../../hooks/useEvent';
import { useAsync } from 'react-use';

const EventDetails = () => {
  const [page, setPage] = useState(1);
  const { getEventsMeta } = useEvent();
  const [eventDataList, setEventDataList] = useState([]);

  const { value: eventsMetaList } = useAsync(() =>
    getEventsMeta(undefined, page, CONSTANTS.ADMIN_DASHBOARD.EVENTS_LIMIT), [page]);

  useEffect(() => {
    const tempDataList = eventsMetaList?.events?.map((item) => ({
      ...item,
      showEvent: false,
    }));
    setEventDataList(tempDataList);
  }, [eventsMetaList?.events]);

  const onChange = (data) => {
    setPage(data);
  };

  const handleUpdate = (index) => {
    const tempListData = eventDataList?.map((item, i) =>
      (index !== i ? { ...item, showEvent: false } : { ...item, showEvent: !(item.showEvent) }));
    setEventDataList(tempListData);
  };

  return (
    <div
      className="w-full h-auto p-6 mt-8 bg-white rounded-lg flex-col
        justify-start items-start gap-4 inline-flex overflow-scroll"
    >
      <div className="self-stretch opacity-80 text-black text-xl font-bold font-['Manrope'] tracking-tight">Events</div>
      <div className="self-stretch justify-start items-start gap-4 inline-flex">
        <div className="grow shrink basis-0 bg-white rounded-lg flex-col justify-start items-start gap-8 inline-flex">
          {eventDataList?.map((item, index) => (
            <Fragment key={index}>
              <div className="self-stretch h-9 flex-col justify-start items-start gap-2 flex">
                <div
                  className="self-stretch pb-3 border-b border-neutral-200 justify-start items-center
                    gap-3 inline-flex cursor-pointer"
                  onClick={() => { handleUpdate(index); }}
                >
                  <div className={cn('w-6 h-6 relative origin-top-left')}>
                    <img src={arrowDownIcon} alt="icon" className={cn(item?.showEvent && 'rotate-180')} />
                  </div>
                  <div
                    className="text-indigo-600 text-base font-medium font-['Manrope']"
                  >
                    {item?.name}
                  </div>
                </div>
              </div>
              {item?.showEvent && (
                <>
                  <div className="self-stretch justify-between items-start inline-flex">
                    <div className="grow shrink basis-0">
                      <span className="text-zinc-700 text-base font-normal font-['Manrope']">Participants: </span>
                      <span
                        className="text-black text-base font-semibold font-['Manrope']"
                      >
                        {item?.registeredUsersCount}
                      </span>
                    </div>
                    <div className="grow shrink basis-0">
                      <span className="text-zinc-700 text-base font-normal font-['Manrope']">Total Challenges: </span>
                      <span
                        className="text-black text-base font-bold font-['Manrope']"
                      >
                        {item?.challengesCount}
                      </span>
                    </div>
                    {item?.isPaid && (
                      <div
                        className="grow shrink basis-0 text-right text-zinc-700 text-base font-normal font-['Manrope']"
                      >
                        Paid Event
                      </div>
                    )}
                  </div>
                  <div className="self-stretch h-auto flex-col justify-start items-start gap-2.5 flex">
                    <div
                      className="self-stretch text-zinc-700 text-base font-bold font-['Manrope']"
                    >
                      Event Details (Public)
                    </div>
                    <div className="self-stretch text-zinc-700 text-base font-normal font-['Manrope'] break-all">
                      <MarkdownView
                        value={item?.metaData}
                      />
                    </div>
                  </div>
                </>
              )}
            </Fragment>
          ))}
        </div>
      </div>
      <div className="justify-start items-start gap-0.5 inline-flex">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#4158DB',
              colorText: '#818EDD',
              fontFamily: 'Manrope',
            },
          }}
        >
          <Pagination
            defaultCurrent={1}
            onChange={onChange}
            total={eventsMetaList?.totalEvents}
            size="small"
            responsive
            pageSize={CONSTANTS.ADMIN_DASHBOARD.EVENTS_LIMIT}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default EventDetails;
