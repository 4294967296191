import cn from 'classnames';

const certifiedSkills = (props: any) => {
  const { certifiedSkillsList } = props;
  return (
    <div
      className={cn(
        'relative mt-[32px] rounded-[32px] border-1 border-solid bg-[#FFFFFF]',
        'w-full h-auto',
        // `bg-[url(assets/images/courseCardGradientImg.svg)]`,
      )}
    > 
      {certifiedSkillsList?.length <= 0 ? (
        <div className='text-indigo-600 min-h-[200px] text-lg w-full flex items-center justify-center'>
          No verified skills assigned
        </div>
      ) : (
        <div className="p-4 rounded-[32px] shadow-xl shadow-[#a8b4f5]">
          <div
            className={cn(
              'font-Manrope h-auto w-auto p-4 m-3 rounded-[32px]',
              'bg-cover bg-center snap-center relative shadow-lg shadow-[#a8b4f5] bg-[#e0f0fa]',
            )}
          >
            <div className='text-indigo-600 text-xl font-bold text-start pl-4'>Verified Skills</div>
          </div>
          <div className="grid grid-cols-12 gap-4 w-full p-4">
            {certifiedSkillsList?.map((item: any) => {
              return (
                <div className='col-span-12 md:col-span-3 lg:col-span-4 p-4 bg-[#f0fcff] text-indigo-600 rounded-[16px] shadow-lg shadow-[#a8b4f5] inline-flex'>
                  <div className="text-base">{item}</div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default certifiedSkills