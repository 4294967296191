// @ts-nocheck
import MDEditor from '@uiw/react-md-editor';
import MarkdownView from '../MarkdownView/MarkdownView';
import rehypeSanitize from 'rehype-sanitize';
import { Controller } from 'react-hook-form';

const TextEditor = (props) => {
  const {
    control,
    errors,
    name,
    defaultValue = '',
  } = props;

  return (
    <div className="">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <MDEditor
            {...field}
            previewOptions={{
              rehypePlugins: [[rehypeSanitize]],
              source: <MarkdownView value={defaultValue} />,
            }}
            placeholder="..."
            rows={10}
            style={{ minHeight: '300px', backgroundColor: '#FFF', color: '#000' }}
          />
        )}
      />
      {errors && errors[name]?.message && (
        <p className="text-danger text-left p-[5px]" style={{ color: 'red' }}>{errors[name].message}</p>
      )}
    </div>
  );
};

export default TextEditor;
