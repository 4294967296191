// @ts-nocheck
/* eslint-disable */
import React from 'react';
import { unified } from 'unified';
import remarkParse from 'remark-parse';
import remarkGfm from 'remark-gfm';
import remarkRehype from 'remark-rehype';
import rehypeSanitize from 'rehype-sanitize';
import rehypeReact from 'rehype-react';
import cn from 'classnames';
import 'normalize.css';
import './MarkdownView.css';
import './MarkdownReset.css';

const filterWhitespace = node =>
  !(typeof node === 'string' && node.trim() === '');

const Table = ({ children, ...props }) => (
  <table {...props}>{children.filter(filterWhitespace)}</table>
);

const TableHead = ({ children, ...props }) => (
  <thead {...props}>{children.filter(filterWhitespace)}</thead>
);

const TableBody = ({ children, ...props }) => (
  <tbody {...props}>{children.filter(filterWhitespace)}</tbody>
);

const TableRow = ({ children, ...props }) => (
  <tr {...props}>{children.filter(filterWhitespace)}</tr>
);

const AnchorTag = ({ children, ...props }) => {
  return (
    <a {...props} target="_blank">
      {children.filter(filterWhitespace)}
    </a>
  );
};

const processor = unified()
  .use(remarkParse)
  .use(remarkGfm)
  .use(remarkRehype)
  .use(rehypeSanitize)
  .use(rehypeReact, {
    createElement: React.createElement,
    components: {
      table: Table,
      thead: TableHead,
      tbody: TableBody,
      tr: TableRow,
      a: AnchorTag
    }
  });

const MarkdownView = ({ value, className }) => {
  return (
    <div className={cn("markdown-preview", className)}>
      {processor.processSync(value).result}
    </div>
  );
};

export default MarkdownView;
