import { useCallback, useEffect, useState } from 'react';
import {
  Form, Input, notification,
} from 'antd';
import dayjs from 'dayjs';
import { FormOutlined, DeleteOutlined } from '@ant-design/icons';
import './index.css';
import CustomAlert from './CustomModal';
import { useAuthentication } from '../../contexts/AuthContext';
import { useComment } from '../../hooks/useComment';
import Button from '../../components/Button';
import { useSocketNotification } from '../../contexts/NotificationContext';

const { TextArea } = Input;

const CommentType = {
  CHALLENGE_SOLUTION: 'CHALLENGE_SOLUTION',
  VDP_REPORT: 'VDP_REPORT',
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const ChatBox = ({
  vdpReportId,
  solutionId,
  commentType, // CHALLENGE_SOLUTION, VDP_REPORT
  currentEventId,
}) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [commentInAction, setCommentInAction] = useState();
  const [comments, setComments] = useState([]);
  const { selectedEvent, sessionToken } = useAuthentication();
  const user = sessionToken?.user;
  const { socket } = useSocketNotification();

  const {
    getUserComments, sendComment, editComment, deleteComment: deleteCommentApi,
  } = useComment();

  const eventId = selectedEvent?._id || currentEventId;

  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  const getReportComments = useCallback(async () => {
    const payload = {
      eventId,
      type: commentType,
      vdpReportId,
    };
    const response = await getUserComments(payload);
    setComments(response);
  }, [commentType, eventId, getUserComments, vdpReportId]);

  const getSolutionComments = useCallback(async () => {
    const payload = {
      eventId,
      type: commentType,
      solutionId,
    };
    const response = await getUserComments(payload);
    setComments(response);
  }, [commentType, eventId, getUserComments, solutionId]);

  const getComments = useCallback(async () => {
    if (commentType === CommentType.CHALLENGE_SOLUTION) {
      await getSolutionComments();
    } else if (commentType === CommentType.VDP_REPORT) {
      await getReportComments();
    }
  }, [commentType, getReportComments, getSolutionComments]);

  const submitCommentForm = async ({ commentMessage }) => {
    try {
      const payload = {
        commentMessage,
        vdpReportId,
        solutionId,
        eventId,
        type: commentType,
      };

      const response = await sendComment(payload);

      form.resetFields();

      notification.success({
        message: response?.message || 'Comment added successfully',
        duration: 3,
      });
      await getComments();
      // send notification
      if (socket && response.notify) {
        socket.emit('send-notification', {
          to: response.notify.receiver,
          from: response.notify.sender,
          msg: response.notify,
        });
      }
    } catch (error) {
      notification.warn({
        message: error?.message || 'Failed to add comment',
        duration: 3,
      });
    }
  };

  const editCommentForm = async ({ commentMessage }) => {
    try {
      const payload = {
        commentMessage,
        commentId: commentInAction?._id,
      };

      const response = await editComment(payload);

      editForm.resetFields();

      setShowFormModal(false);
      setCommentInAction(undefined);

      await getComments();

      notification.success({
        message: response?.message || 'Comment updated successfully',
        duration: 3,
      });
      await getComments();
    } catch (error) {
      notification.warn({
        message: error?.message || 'Failed to update comment',
        duration: 3,
      });
    }
  };

  const deleteComment = async (commentId) => {
    try {
      const response = await deleteCommentApi(commentId);
      await getComments();

      notification.success({
        message: response?.message || 'Deleted comment successfully',
        duration: 3,
      });
    } catch (error) {
      notification.warn({
        message: error?.message || 'Failed to delete comment, Try again',
        duration: 3,
      });
    }
  };

  useEffect(() => {
    getComments();
  }, [getComments]);

  if (!user) {
    return null;
  }

  return (
    <div className="page">
      <div className="chat-container">
        <div className="conversation">
          <div className="conversation-container">
            {comments.map((comment) => (
              <div key={comment?._id}>
                {comment?.user?._id === user?._id
                || (comment.user.role === user?.role && user?.role === 'admin') ? (
                  <div className="message sent">
                    {comment.commentMessage}
                    <span className="metadata">
                      {user.role === 'admin' && (
                        <FormOutlined
                          onClick={() => {
                            editForm.setFieldsValue({
                              commentMessage: comment.commentMessage,
                            });
                            setShowFormModal(true);
                            setCommentInAction(comment);
                          }}
                          style={{ cursor: 'pointer', color: '#494949' }}
                        />
                      )}
                      <DeleteOutlined
                        onClick={() => deleteComment(comment?._id)}
                        style={{ cursor: 'pointer', color: '#494949' }}
                      />
                      <span className="time">
                        {dayjs(comment.createdAt).format('hh:mma D MMM YYYY')}
                      </span>
                    </span>
                  </div>
                  ) : (
                    <div className="message received">
                      {comment.commentMessage}
                      <span className="metadata">
                        <span className="time">
                          {dayjs(comment.createdAt).format('hh:mma D MMM YYYY')}
                        </span>
                      </span>
                    </div>
                  )}
              </div>
            ))}
          </div>

          <Form
            form={form}
            name="comment-form"
            onFinish={submitCommentForm}
            layout="vertical"
            className="conversation-compose"
          >
            <Form.Item
              className="m-0 w-100"
              name="commentMessage"
              rules={[{ required: true, message: 'Comment is required' }]}
              style={{ width: '100%', paddingBottom: '20px' }}
            >
              <TextArea
                className="input-msg"
                rows={4}
                placeholder="Type your comment here"
              />
            </Form.Item>
            <Button
              type="submit"
              variant="primary-outline"
            >
              <div className="text-indigo-600 text-base font-bold font-['Manrope']">Submit</div>
            </Button>
          </Form>
        </div>
      </div>
      {showFormModal && commentInAction && (
        <CustomAlert
          close={() => {
            setCommentInAction(undefined);
            editForm.resetFields();
            setShowFormModal(false);
          }}
          isOpen={showFormModal}
          hideDefaultButtons
          title={<span>Edit Comment</span>}
        >
          <Form
            form={editForm}
            name="comment-form"
            onFinish={editCommentForm}
            layout="vertical"
            initialValues={{ commentMessage: commentInAction.commentMessage }}
          >
            <Form.Item
              name="commentMessage"
              rules={[{ required: true, message: 'Comment is required' }]}
              style={{ width: '100%', paddingBottom: '20px' }}
            >
              <TextArea rows={4} placeholder="Type your comment here" />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button
                type="submit"
                variant="primary-outline"
              >
                <div className="text-indigo-600 text-base font-bold font-['Manrope']">Submit</div>
              </Button>
            </Form.Item>
          </Form>
        </CustomAlert>
      )}
    </div>
  );
};

export default ChatBox;
