// @ts-nocheck
import { useCallback, useEffect, useState } from 'react';
import TextField from '../../../components/TextField';
import DynamicTable from '../../../components/DynamicTable';
import detailsIcon from '../../../assets/icons/adminScreen/detailsIcon.svg';
import deleteIcon from '../../../assets/icons/adminScreen/deleteIcon.svg';
import { useForm } from 'react-hook-form';
import DetailsDataPopup from '../CommonPopup/DetailsDataPopup';
import Checkbox from 'antd/es/checkbox/Checkbox';
import MarkAsDonePopup from '../CommonPopup/MarkAsDonePopup';
import { useUser } from '../../../hooks/useUser';
import CONSTANTS from '../../../constants';
import { useAsyncRetry } from 'react-use';
import { notification } from 'antd';
import DeleteUserRequestPopup from './DeleteUserRequestPopup';

const UserRequest = () => {
  const [deleteData, setDeleteData] = useState(false);
  const [detailsData, setDetailsData] = useState(false);
  const [markedData, setMarkedData] = useState(null);
  const [page, setPage] = useState(1);

  const { getAllUserEnrollmentRequests, editUserEnrollmentRequest } = useUser();
  const {
    control,
    watch,
    formState: { errors },
  } = useForm();

  const handleCheckBox = (data) => {
    setMarkedData(data);
  };

  const searchTerm = watch('searchTerm');

  useEffect(() => { setPage(1); }, [searchTerm]);

  const { value: userRequestListData, retry: refreshUserRequests } = useAsyncRetry(() =>
    getAllUserEnrollmentRequests({ page, searchTerm, limit: CONSTANTS.PAGE_LIMIT }), [searchTerm, page]);

  const markUserRequestStatus = useCallback(async () => {
    try {
      const response = await editUserEnrollmentRequest({
        ...markedData,
        id: markedData?._id,
        status: markedData?.status === 'pending' ? 'complete' : 'pending',
      });
      notification.success({ message: response?.message || 'Successfully marked request status' });
      if (refreshUserRequests) {
        refreshUserRequests();
      }
      setMarkedData(null);
    } catch (error) {
      notification.error({ message: error?.message || 'Failed to mark request status', duration: 3 });
    }
  }, [editUserEnrollmentRequest, markedData, refreshUserRequests]);

  const columns = [
    {
      title: 'Mark as Done',
      dataIndex: 'status',
      key: 'status',
      render: (data, record) => (
        <Checkbox checked={data !== 'pending'} onChange={() => handleCheckBox(record)} />
      ),
    },
    {
      title: 'S. No',
      dataIndex: '_id',
      key: '_id',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'User Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email Id',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Interested Event',
      dataIndex: 'eventId',
      key: 'eventId',
      render: (event) => event?.name,
    },
    {
      title: 'Status',
      dataIndex: '',
      key: '',
      render: (data) => (
        <div className="justify-start items-center gap-4 flex">
          <div
            className="justify-center items-center gap-1.5 flex cursor-pointer"
            onClick={() => { setDetailsData(data); }}
          >
            <div className="w-4 h-4 relative">
              <img src={detailsIcon} alt="icon" />
            </div>
            <div className="text-sky-600 text-sm font-medium font-['Manrope'] leading-normal">Details</div>
          </div>
          <div
            className="justify-start items-center gap-1.5 flex cursor-pointer"
            onClick={() => { setDeleteData(data); }}
          >
            <div className="w-4 h-4 relative">
              <img src={deleteIcon} alt="icon" />
            </div>
            <div className="text-sky-600 text-sm font-medium font-['Manrope'] leading-normal">Delete</div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full h-auto flex-col justify-start items-start gap-8 inline-flex p-8">
      {deleteData && (
        <DeleteUserRequestPopup
          deleteData={deleteData}
          setDeleteData={setDeleteData}
          refreshUserRequests={refreshUserRequests}
        />
      )}
      {detailsData && (
        <DetailsDataPopup
          detailsData={detailsData}
          setDetailsData={setDetailsData}
          refreshUserRequests={refreshUserRequests}
        />
      )}
      {markedData && (
        <MarkAsDonePopup
          markedData={markedData}
          setMarkedData={setMarkedData}
          refreshUserRequests={refreshUserRequests}
          onConfirm={markUserRequestStatus}
        />
      )}
      <div className="self-stretch justify-start items-start gap-8 grid grid-cols-12">
        <div
          className="col-span-8 grow shrink basis-0 self-stretch p-6 bg-white rounded-lg flex-col
            justify-center items-start gap-4 inline-flex"
        >
          <form autoComplete="off" className="w-full">
            <div className="grid grid-cols-12 gap-6 w-full">
              <div className="col-span-12">
                <TextField
                  name="searchTerm"
                  placeholder="Search by User Name or Email ID"
                  label="User Name or Email ID"
                  control={control}
                  errors={errors}
                  customHeight="40px"
                  customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] pb-2"
                  isSearchBtn
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="self-stretch h-auto w-full p-6 bg-white rounded-lg flex-col justify-start items-start flex">
        <DynamicTable
          dataList={userRequestListData?.userRequests}
          columns={columns}
          totalCount={userRequestListData?.totalUserRequests}
          pageSize={CONSTANTS.PAGE_LIMIT}
          onPageChange={setPage}
          currentPage={page}
        />
      </div>
    </div>
  );
};

export default UserRequest;
