import EventTypeService from '../services/EventTypeService';
import { IEventType } from '../services/EventTypeService/types';
import { useCallbackWithLoading } from './useCallbackWithLoading';

interface IUseEventType {
	getEventTypes: () => Promise<IEventType[]>;
}

export const useEventType = (): IUseEventType => {
  const getEventTypes = useCallbackWithLoading(async () => {
    const eventTypes = await EventTypeService.getEventTypes();
    return eventTypes;
  }, []);

  return {
    getEventTypes,
  };
};
