import cn from 'classnames';

const ModalPopup = (props: { fullScreen?: boolean; children: any; }) => {
  const {
    fullScreen,
    children,
  } = props;
  return (
    <div
      className={cn('relative z-50')}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      />
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className={cn('flex items-center justify-center p-4 text-center',
          'sm:items-center sm:p-0',
          fullScreen ? 'max-h-screen' : 'min-h-full',
        )}
        >
          <div className={cn('relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl',
            'transition-all sm:my-8 sm:mx-4 overflow-y-auto',
            fullScreen ? 'h-screen w-screen' : 'sm:w-full sm:max-w-lg',
          )}
          >
            <div className="w-full text-center">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPopup;
