// @ts-nocheck
import ModalPopup from '../../../../components/Modal';
import closeIcon from '../../../../assets/icons/closeIcon.svg';
import moment from 'moment';

const DetailsDataPopup = (props) => {
  const { detailsData, setDetailsData } = props;

  return (
    <ModalPopup>
      <div
        className="w-full h-auto p-8 bg-white rounded-lg shadow flex-col
          justify-start items-start gap-6 inline-flex"
      >
        <div className="w-6 h-6 absolute right-2 top-2 cursor-pointer" onClick={() => setDetailsData(false)}>
          <img src={closeIcon} alt="icon" />
        </div>
        <div
          className="text-black text-xl font-bold font-['Manrope'] tracking-tight"
        >
          Enroll Request Details
        </div>
        <div className="text-left self-stretch h-[82px] flex-col justify-start items-start gap-4 flex">
          <div>
            <span
              className="text-black text-opacity-80 text-base font-semibold font-['Manrope'] tracking-tight"
            >
              Registered On:
            </span>
            <span
              className="text-black text-opacity-80 text-base font-medium font-['Manrope'] tracking-tight"
            />
            <span
              className="text-black text-opacity-80 text-base font-normal font-['Manrope'] tracking-tight"
            >
              {moment(detailsData?.createdAt).format('YYYY-MM-DD')}
            </span>
          </div>
          <div>
            <span
              className="text-black text-opacity-80 text-base font-semibold font-['Manrope'] tracking-tight"
            >
              User Note :
            </span>
            <br />
            <span
              className="text-black text-opacity-80 text-base font-normal font-['Manrope'] tracking-tight"
            >
              {detailsData?.note ? detailsData?.note : 'User didn\'t provide any notes'}
            </span>
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};

export default DetailsDataPopup;
