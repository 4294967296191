import { isAxiosError } from 'axios';
import HttpService from '../HttpService';

const generateFileUrl = async (payload: FormData, sessionToken?: string) => {
  try {
    const { data } = await HttpService.post<{url: string}>(
      'image/generate-url',
      payload,
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error?.response?.data;
    }
    throw error;
  }
};

const HelperAPIService = {
  generateFileUrl,
};

export default HelperAPIService;
