// @ts-nocheck
/* eslint-disable */
import { useForm } from 'react-hook-form';
import TextField from '../../../components/TextField';
import { useAuthentication } from '../../../contexts/AuthContext';

const MyProfileForm = () => { 
  const { sessionToken } = useAuthentication();
  const userData = sessionToken?.user;

  const defaultProfileData = {
    userName: userData?.userName,
    email: userData?.email,
    inviteCodeUsed: userData?.code
  };

  const {
    control,
    formState: { errors }
  } = useForm({
    defaultValues: userData && defaultProfileData,
  });


  return (
    <div className="w-[70%] 2xl:w-[60%] h-auto p-6 bg-white rounded-lg flex-col justify-start items-start gap-6 inline-flex">
      <div className="flex-col justify-start items-start gap-4 flex">
        <div className="w-full opacity-80 text-black text-xl font-bold font-['Manrope'] tracking-tight">My Profile</div>
      </div>
      <form className='h-auto w-full'>
        <div className="h-auto w-full flex-col justify-start items-start gap-8 flex">
          <TextField
            name="userName"
            type="text"
            label="User Name"
            control={control}
            errors={errors}
            customHeight="56px"
            readOnly
            customlabelclass={`!text-zinc-700 !text-base !font-medium !font-['Manrope'] !leading-normal !pb-2`}
          />
          <TextField
            name="email"
            type="text"
            label="Email"
            control={control}
            errors={errors}
            customHeight="56px"
            readOnly
            customlabelclass={`!text-zinc-700 !text-base !font-medium !font-['Manrope'] !leading-normal !pb-2`}
          />
          <TextField
            name="inviteCodeUsed"
            type="text"
            label="Invite Code Used"
            control={control}
            errors={errors}
            customHeight="56px"
            readOnly
            customlabelclass={`!text-zinc-700 !text-base !font-medium !font-['Manrope'] !leading-normal !pb-2`}
          />
        </div>
      </form>
    </div>
  )
}

export default MyProfileForm;