// @ts-nocheck
import goldBadgeIcon from '../../../assets/icons/dashboardScreen/goldBadgeIcon.svg';
import silverBadgeIcon from '../../../assets/icons/dashboardScreen/silverBadgeIcon.svg';
import bronzeBadgeIcon from '../../../assets/icons/dashboardScreen/bronzeBadgeIcon.svg';
import rankIcon from '../../../assets/icons/dashboardScreen/rankIcon.svg';
import profileIcon from '../../../assets/icons/dashboardScreen/profileIcon.svg';
import { useAsync } from 'react-use';
import { useEvent } from '../../../hooks/useEvent';
import { useAuthentication } from '../../../contexts/AuthContext';

const LeaderBoard = () => {
  const { selectedEvent } = useAuthentication();

  const { getEventLeaderBoard } = useEvent();
  const { value: leaderBoardData = [] } = useAsync(() => getEventLeaderBoard(selectedEvent?._id), []);
  
  const handleBadgeIcon = (rank) => {
    let badgeIcon = '';
    switch (rank) {
      case 0: badgeIcon = goldBadgeIcon;
        return badgeIcon;
      case 1: badgeIcon = silverBadgeIcon;
        return badgeIcon;
      default: badgeIcon = bronzeBadgeIcon;
        return badgeIcon;
    }
  };

  const getRankSuffix = (rank) => {
    if (rank >= 11 && rank <= 13) {
      return 'th';
    }

    const lastDigit = rank % 10;

    switch (lastDigit) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  const calculatePercentage = (rank, totalParticipants) => {
    // Ensure that both rank and totalParticipants are positive integers
    if (rank <= 0 || totalParticipants <= 0 || !Number.isInteger(rank) || !Number.isInteger(totalParticipants)) {
      return;
    }

    // Calculate the percentage
    const percentage = ((totalParticipants - rank) / totalParticipants) * 100;

    // Round the percentage to two decimal places
    return Math.round(percentage * 100) / 100;
  };

  return (
    <>
      <div
        className="w-full opacity-80 text-black text-xl font-bold font-['Manrope'] tracking-tight text-left"
      >
        Your Current Rank
      </div>
      <div
        className="w-[394px] h-[116px] p-2 bg-cyan-200 rounded-2xl justify-start
          items-center gap-6 inline-flex my-[16px] text-left"
      >
        <div
          className="w-[100px] h-[100px] p-3 bg-cyan-100 rounded-xl flex-col justify-between items-start inline-flex"
        >
          <div className="w-6 h-6 relative">
            <img src={rankIcon} alt="icon" />
          </div>
          <div className="self-stretch">
            <span className="text-indigo-600 text-[40px] font-normal font-['Poppins'] leading-[42px]">
              {leaderBoardData?.currentUserRank}
            </span>
            <span className="text-indigo-600 text-xl font-normal font-['Poppins'] leading-[42px]">
              {leaderBoardData?.currentUserRank > 0 && getRankSuffix(leaderBoardData?.currentUserRank)}
            </span>
          </div>
        </div>
        {leaderBoardData?.currentUserRank > 0 && leaderBoardData?.leaderboardByEvent?.length > 0 && (
          <div
            className="grow shrink basis-0 opacity-60 text-black text-base font-normal font-['Poppins'] leading-tight"
          >
            {`You are doing better than
            ${calculatePercentage(leaderBoardData?.currentUserRank, leaderBoardData?.leaderboardByEvent?.length)}%
              of other candidates`}
          </div>
        )}
      </div>
      <div
        className="w-full min-h-[408px] p-6 bg-white rounded-2xl border border-indigo-100
          flex-col justify-start items-start gap-6 inline-flex"
      >
        {leaderBoardData?.leaderboardByEvent?.length > 0 && leaderBoardData?.leaderboardByEvent?.map((item, index) => (
          <div
            className="self-stretch justify-between items-center inline-flex"
            key={index}
          >
            <div className="justify-start items-center gap-3 flex">
              <div className="w-[22px] h-8 relative">
                <div className="w-[29.52px] h-[35.71px] left-[-3.76px] top-[-1.86px] absolute">
                  {index > 2 ? `${index + 1}` : <img src={handleBadgeIcon(index)} alt="icon" />}
                </div>
              </div>
              <img className="w-10 h-10 rounded-full" src={profileIcon} alt="icon" />
              <div className="text-black text-base font-medium font-['Manrope'] md:max-w-[30%] lg:max-w-[50%] truncate">
                {item?.userName}
              </div>
              <div
                className="px-3 py-0.5 bg-gradient-to-r from-cyan-50 to-indigo-50 rounded-[32px]
                  justify-start items-start gap-2.5 flex"
              >
                <div className="opacity-60 text-black text-sm font-semibold font-['Manrope'] leading-normal">
                  {Math.round((item?.totalScore/item.totalMaxScoreOfAllChallenges)*100) || 0}
                  % Score
                </div>
              </div>
            </div>
            <div className="text-black text-base font-normal font-['Manrope']">
              {item?.totalScore
              && item?.totalMaxScoreOfAllChallenges
                ? (
                  <>
                    {item?.totalScore}
                    {' '}
                    /
                    {item?.totalMaxScoreOfAllChallenges}
                    {' '}
                    points
                  </>
                ) : 'N/A'}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default LeaderBoard;
