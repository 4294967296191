/* eslint-disable quotes */
import { useState } from 'react';
import cn from 'classnames';
import loginScreenImage1 from '../../assets/images/loginScreenImage1.svg';
import vantagePointLogo from '../../assets/logo/vantagePointLogo.svg';
import enciphersLogo from '../../assets/logo/enciphersLogo.svg';
import LoginForm from './loginForm';
import RegisterForm from './registerForm';
import { NavLink } from 'react-router-dom';

const LoginFormSection = () => {
  const [selectedForm, setSelectedForm] = useState('Login');
  return (
    <>
      <div
        className={cn('w-full h-auto bg-cover bg-center',
          `bg-[url(assets/images/loginScreenBgImage.svg)]`,
        )}
      >
        <div
          className="flex justify-center items-start pt-[16px] cursor-pointer"
          // onClick={() => window?.open('https://www.enciphers.com/')}
        >
          <NavLink to={window.location.origin}>
            <img src={vantagePointLogo} alt="" width="300px" height="40px" />
          </NavLink>
        </div>
        <div
          className="flex justify-center items-center gap-[8px] cursor-pointer"
          onClick={() => window?.open('https://www.enciphers.com/')}
        >
          <span className="flex justify-center items-center text-[14px] font-[400] text-[#5DB3FF]">A product of</span>
          <img src={enciphersLogo} alt="" width="148px" height="56px" />
        </div>
        <div className="grid grid-cols-12 gap-4 px-[15vw] justify-center items-center">
          <div className="md:col-span-12 lg:col-span-7 flex md:justify-center lg:justify-start items-center">
            <div className="flex flex-col justify-start items-baseline pt-[7px] gap-[16px]">
              <div className="text-white text-[52px] font-bold font-['Manrope'] leading-[56px]">
                Hack, Learn
                <br />
              </div>
              <div className="text-white text-[52px] font-bold font-['Manrope'] leading-[56px]">
                and
                {' '}
                <span className="text-emerald-300 text-[52px] font-bold font-['Manrope'] leading-[56px]">Improve.</span>
              </div>
              <div className="w-full h-full">
                <img src={loginScreenImage1} alt="" className="max-w-[40vw] max-h-[36vh]" />
              </div>
            </div>
          </div>
          <div className="md:col-span-12 lg:col-span-5 flex md:justify-center py-5 lg:justify-start
            items-center"
          >
            {selectedForm === 'Login'
              ? (
                <LoginForm setSelectedForm={setSelectedForm} />
              )
              : (
                <RegisterForm setSelectedForm={setSelectedForm} />
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginFormSection;
