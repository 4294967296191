/* eslint-disable quotes */
// @ts-nocheck
import { useForm } from 'react-hook-form';
import { useRef } from 'react';
import TextField from '../../components/TextField';
import TextArea from '../../components/TextArea';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import cn from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';
import { useUser } from '../../hooks/useUser';
import { notification } from 'antd';

const EnrollPopUp = (props) => {
  const {
    setEventToEnroll,
    eventToEnroll,
  } = props;

  const { createEventEnrollmentRequest } = useUser();

  const captchaRef = useRef(null);

  const schema = yup.object({
    email: yup.string().required('Email Id is required')
      .max(100, 'Email should not be more than 100 characters').email('Enter valid email')
      .nullable(),
    name: yup.string().required('Name is required')
      .max(60, 'Name should not be more than 60 characters').nullable(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (formValues) => {
    try {
      const captcha = captchaRef?.current?.getValue();
      const payload = {
        ...formValues,
        eventId: eventToEnroll.id,
        captcha,
      };
      captchaRef.current.reset();
      const response = await createEventEnrollmentRequest(payload);
      notification.success({ message: response?.message || 'Request created successfully', duration: 3 });
      setEventToEnroll(null);
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message
          || error?.message
          || 'Failed to submit, please try again',
        duration: 3,
      });
    }
  };

  return (
    <div className="relative flex justify-center items-center">
      <div className="fixed inset-0 z-20 bg-gray-500 bg-opacity-75 transition-opacity" />
      <div className="fixed inset-0 z-20 overflow-y-auto">
        <div className="relative w-[40vw] flex justify-center m-auto h-screen items-center">
          {/* <!-- Modal content --> */}
          <div
            className={cn('relative font-Manrope h-auto w-full rounded-[16px] p-8 bg-cover bg-center',
              `bg-[url(assets/images/loginFormBgGradientImg.svg)]`,
            )}
          >
            <div
              className="absolute right-4 top-0 cursor-pointer text-[32px] p-2 font-[600]"
              onClick={() => setEventToEnroll(null)}
            >
              x
            </div>
            <div className="flex items-center justify-center text-[32px] font-[700] text-[#030303]">
              Get Invite Code To Join The Event
            </div>
            <div
              className="px-[64px] py-[40px]"
            >
              <hr className="bg-clip-border bg-[#42E8E0] border-1 border-[#3B4DBC]" />
            </div>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="px-[64px] gap-[24px]">
                <div className="">
                  <TextField
                    name="name"
                    type="text"
                    fieldLabel="Enter your name"
                    label="Full Name *"
                    control={control}
                    errors={errors}
                    customHeight="56px"
                  />
                </div>
                <div className="">
                  <TextField
                    name="email"
                    type="email"
                    fieldLabel="Enter your email"
                    label="Email Address *"
                    control={control}
                    errors={errors}
                    customHeight="56px"
                  />
                </div>
                <div className="">
                  <TextArea
                    name="note"
                    label="Message"
                    control={control}
                    errors={errors}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '10px',
                    marginTop: '30px',
                  }}
                >
                  <ReCAPTCHA
                    sitekey="6LdZSOYiAAAAAE147AeGSfJfwE03pGz-F8WNeV3s"
                    ref={captchaRef}
                  />
                </div>
              </div>
              <div className="flex justify-center items-center pt-[20px] mx-16">
                <button
                  type="submit"
                  className="w-[486px] h-[59px] px-20 py-4 bg-gradient-to-b from-indigo-600 to-indigo-800
                    rounded-md justify-center items-center gap-[12.72px] inline-flex"
                  disabled={
                    !captchaRef
                      && !captchaRef.current
                      && !captchaRef.current.getValue()
                  }
                >
                  <span className="text-white text-xl font-bold font-['Manrope']">Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnrollPopUp;
