import vantagePointIcon from '../../assets/logo/vantagePointIcon.svg';
import vantagePointText from '../../assets/logo/vantageText.svg';
import logoutIcon from '../../assets/icons/adminScreen/admin_logout_icon.svg';
import { useAuthentication } from '../../contexts/AuthContext';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';

const AdminSideBar = () => {
  const { resetAuth } = useAuthentication();
  const location = useLocation();
  const navigate = useNavigate();

  const sidebarData = [
    {
      label: 'Dashboard',
      path: '/admin/dashboard',
    },
    {
      label: 'Users',
      path: '/admin/user',
    },
    {
      label: 'Events',
      path: '/admin/event',
    },
    {
      label: 'Challenges',
      path: '/admin/challenge',
    },
    {
      label: 'PoC',
      path: '/admin/poc',
    },
    {
      label: 'Reports',
      path: '/admin/report',
    },
    {
      label: 'Categories',
      path: '/admin/categories',
    },
    {
      label: 'User Requests',
      path: '/admin/user-requests',
    },
    {
      label: 'Certificates',
      path: '/admin/certificates',
    },
  ];

  return (
    <div className="w-full h-screen px-8 py-6 border-r border-blue-200 border-opacity-50
        flex-col justify-between items-start inline-flex"
    >
      <div className="flex-col justify-start items-start gap-16 flex">
        <div
          className="flex justify-start items-center cursor-pointer"
          // onClick={() => window?.open('https://www.enciphers.com/')}
        >
          <NavLink to={window.location.origin}>
            <img src={vantagePointIcon} alt="" width="24.28px" height="24px" />
            <img src={vantagePointText} alt="" width="126.63px" height="11.17px" className="pl-[8px]" />
          </NavLink>
        </div>
        <div className="flex-col justify-start items-start gap-4 flex">
          {sidebarData?.map((item, index) => (
            <div
              key={index}
              onClick={() => navigate(item?.path)}
              className={cn('cursor-pointer self-stretch px-4 py-2',
                'justify-start items-start gap-2.5 inline-flex',
                location?.pathname === item?.path && 'bg-gradient-to-b from-blue-200 to-indigo-300 rounded-lg')}
            >
              <div className={cn('text-base font-semibold font-[\'Manrope\']',
                location?.pathname === item?.path ? 'text-indigo-600' : 'opacity-60 text-black')}
              >
                {item?.label}
              </div>
            </div>
          ))}
          {/* <div className="cursor-pointer self-stretch px-4 py-2 justify-start items-start gap-2.5 inline-flex">
            <div className="text-indigo-600 text-base font-semibold font-['Manrope']">
              Dashboard
            </div>
          </div>
          <div className="cursor-pointer self-stretch px-4 py-2 justify-start items-start gap-2.5 inline-flex">
            <div className="opacity-60 text-black text-base font-semibold font-['Manrope']">
              Users
            </div>
          </div>
          <div className="cursor-pointer self-stretch px-4 py-2 justify-start items-start gap-2.5 inline-flex">
            <div className="opacity-60 text-black text-base font-semibold font-['Manrope']">
              Events
            </div>
          </div>
          <div className="cursor-pointer self-stretch px-4 py-2 justify-start items-start gap-2.5 inline-flex">
            <div className="opacity-60 text-black text-base font-semibold font-['Manrope']">
              Challenges
            </div>
          </div>
          <div className="cursor-pointer self-stretch px-4 py-2 justify-start items-start gap-2.5 inline-flex">
            <div className="opacity-60 text-black text-base font-semibold font-['Manrope']">
              PoC
            </div>
          </div>
          <div className="cursor-pointer self-stretch px-4 py-2 justify-start items-start gap-2.5 inline-flex">
            <div className="opacity-60 text-black text-base font-semibold font-['Manrope']">
              Reports
            </div>
          </div>
          <div className="cursor-pointer self-stretch px-4 py-2 justify-start items-start gap-2.5 inline-flex">
            <div className="opacity-60 text-black text-base font-semibold font-['Manrope']">
              Categories
            </div>
          </div>
          <div className="cursor-pointer self-stretch px-4 py-2 justify-start items-start gap-2.5 inline-flex">
            <div className="opacity-60 text-black text-base font-semibold font-['Manrope']">
              User Requests
            </div>
          </div> */}
        </div>
      </div>
      <div
        className="self-stretch px-4 py-2 justify-start items-start gap-2.5 inline-flex cursor-pointer"
        onClick={() => resetAuth()}
      >
        <div className="w-6 h-6 relative">
          <img src={logoutIcon} alt="icon" />
        </div>
        <div className="text-indigo-600 text-base font-semibold font-['Manrope']">
          Logout
        </div>
      </div>
    </div>
  );
};

export default AdminSideBar;
