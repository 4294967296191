// @ts-nocheck
import closeIcon from '../../../../../assets/icons/adminScreen/closeIcon.svg';
import { useForm } from 'react-hook-form';
import SelectBox from '../../../../../components/SelectBox';
import Button from '../../../../../components/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ModalPopup from '../../../../../components/Modal';
import { useUser } from '../../../../../hooks/useUser';
import { notification } from 'antd';
import { useEvent } from '../../../../../hooks/useEvent';
import { useAsync } from 'react-use';
import { useSocketNotification } from '../../../../../contexts/NotificationContext';

const InviteUser = (props) => {
  const { setInviteUser } = props;
  const { addUserToEvent, getAllUsers } = useUser();
  const { getAllEvents } = useEvent();
  const { socket } = useSocketNotification();

  const schema = yup.object({
    selectUser: yup.object().required('User Selection is required').nullable(),
    selectEvent: yup.object().required('Event Selection is required').nullable(),
  });

  const { value: allEvents } = useAsync(() => getAllEvents(), []);
  const { value: allUsers } = useAsync(() => getAllUsers(), []);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const response = await addUserToEvent({ eventId: data.selectEvent._id, userId: data.selectUser._id });
      notification.success({ message: 'Successfully added user to event' });
      setInviteUser(false);
      // send notification
      socket.emit('send-notification', {
        to: response.user._id,
        msg: response.notify,
      });
    } catch (error) {
      notification.error({ message: error?.message || ' Failed to add user to event' });
    }
  };

  return (
    <ModalPopup
      fullScreen
    >
      <div className="w-full h-screen pt-8 pb-12 bg-purple-50 flex-col justify-start items-start gap-8 inline-flex">
        <div className="self-stretch px-12 justify-between items-center inline-flex">
          <div className="text-indigo-600 text-xl font-semibold font-['Manrope']">Invite A User</div>
          <div
            className="w-8 h-8 relative cursor-pointer"
            onClick={() => setInviteUser(false)}
          >
            <img src={closeIcon} alt="icon" />
          </div>
        </div>
        <div className="self-stretch px-12 justify-start items-start gap-[26px] inline-flex">
          <div
            className="w-[800px] self-stretch p-6 bg-white rounded-lg flex-col
              justify-center items-start gap-4 inline-flex"
          >
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="w-full">
              <div className="grid grid-cols-12 gap-6 w-full">
                <div className="col-span-12">
                  <SelectBox
                    name="selectUser"
                    placeholder="User Name"
                    label="Select User"
                    options={allUsers?.users || []}
                    control={control}
                    errors={errors}
                    customLabel="userName"
                    customValue="userName"
                    customHeight="40px"
                    customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                  />
                </div>
                <div className="col-span-12">
                  <SelectBox
                    name="selectEvent"
                    placeholder="Event Name"
                    label="Select Event"
                    options={allEvents?.events || []}
                    control={control}
                    errors={errors}
                    customLabel="name"
                    customValue="name"
                    customHeight="40px"
                    customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                  />
                </div>
                <div className="col-span-12 flex justify-start">
                  <Button
                    customClass=""
                    variant="primary"
                    type="submit"
                  >
                    <div className="text-white text-base font-bold font-['Manrope']">Invite User</div>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};

export default InviteUser;
