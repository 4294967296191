import { Fragment } from 'react';
import { Modal } from 'antd';
import './index.css';

const CustomAlert = ({
  children,
  className,
  title = 'Are you sure to delete?',
  iconSrc,
  isOpen,
  close,
  onSubmit,
  buttons = [],
  buttonsStyleType = 'inline', // inline, block
  hideDefaultButtons = false,
  ...props
}) => {
  const classNames = `custom-modal ${className}`;

  return (
    <Modal
      footer={null}
      className={classNames}
      onCancel={close}
      {...props}
      visible={isOpen}
    >
      {title && <h1>{title}</h1>}

      {iconSrc && <img src={iconSrc} alt="" />}

      {children}

      {buttons.length > 0 && !hideDefaultButtons ? (
        <div className={`btns-container ${buttonsStyleType}`}>
          {buttons.map((button, i) => (
            <Fragment key={i}>{button}</Fragment>
          ))}
        </div>
      ) : (
        !hideDefaultButtons && (
          <div className={`btns-container ${buttonsStyleType}`}>
            <button onClick={close}>Cancel</button>
            <button onClick={onSubmit}>Yes</button>
          </div>
        )
      )}
    </Modal>
  );
};

export default CustomAlert;
