/* eslint-disable max-len */
// @ts-nocheck
import ModalPopup from '../../../components/Modal';
import closeIcon from '../../../assets/icons/closeIcon.svg';
import Button from '../../../components/Button';

const ShareConfirmation = (props) => {
  const { setShareConfirm } = props;
  const onShareConfirm = () => {
    console.log('Confirmed');
    setShareConfirm(false);
  };

  return (
    <ModalPopup>
      <div
        className="w-full h-auto p-8 bg-white rounded-lg shadow flex-col
          justify-center items-start gap-6 inline-flex"
      >
        <div className="w-6 h-6 absolute right-2 top-2 cursor-pointer" onClick={() => setShareConfirm(false)}>
          <img src={closeIcon} alt="icon" />
        </div>
        <div
          className="text-black text-xl font-bold font-['Manrope'] tracking-tight"
        >
          <div>
            You are about to share this content via a public URL.
            This means that anyone with the link will be able to access it.
          </div>
          Are you sure you want to proceed?
        </div>
        <div className="self-stretch justify-center items-center gap-6 inline-flex">
          <Button onClick={() => onShareConfirm()}>
            <div className="text-white text-base font-bold font-['Manrope']">Confirm</div>
          </Button>
          <Button
            variant="primary-outline"
            onClick={() => { setShareConfirm(false); }}
          >
            <div className="text-indigo-600 text-base font-bold font-['Manrope']">Cancel</div>
          </Button>
        </div>
      </div>
    </ModalPopup>
  );
};

export default ShareConfirmation;
