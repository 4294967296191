// @ts-nocheck
import ModalPopup from '../../../../components/Modal';
import closeIcon from '../../../../assets/icons/adminScreen/closeIcon.svg';
import uploadIcon from '../../../../assets/icons/dashboardScreen/uploadIcon.svg';
import arrowDownIcon from '../../../../assets/icons/adminScreen/arrowDownIcon.svg';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import MarkdownView from '../../../../components/MarkdownView/MarkdownView';
import { useForm } from 'react-hook-form';
import TextField from '../../../../components/TextField';
import TextEditor from '../../../../components/TextEditor';
import Button from '../../../../components/Button';
import { useChallenge } from '../../../../hooks/useChallenge';
import { notification } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ChatBox from '../../../ChatBox';
import { useSocketNotification } from '../../../../contexts/NotificationContext';
import { useAuthentication } from '../../../../contexts/AuthContext';

const EvaluateChallenge = (props) => {
  const { evaluateChallenge, setEvaluateChallenge, refreshSolutions } = props;
  const [showDetails, setShowDetails] = useState(false);
  const [uploadedFileList, setUploadedFiles] = useState([]);
  const [showUploadedFiles, setShowUploadedFiles] = useState(false);
  const { evaluateSolution } = useChallenge();
  const { socket } = useSocketNotification();
  const { sessionToken } = useAuthentication();

  const schema = yup.object({
    score: yup.number().required(
      'Score is required',
    ).max(evaluateChallenge.challenge?.score).min(0)
      .typeError('Score is required')
      .nullable(),
    feedback: yup.string(),
  });

  useEffect(() => {
    if (evaluateChallenge?.files && evaluateChallenge?.files?.length > 0) {
      const fileData = evaluateChallenge?.files?.map((item) => {
        const urlParts = item?.file?.split('/');
        const fileName = urlParts[urlParts.length - 1];
        return {
          ...item,
          name: fileName,
        };
      });
      setUploadedFiles(fileData);
    }
  }, [evaluateChallenge?.files]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...evaluateChallenge,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const response = await evaluateSolution({
        solutionId: evaluateChallenge._id,
        pastScore: evaluateChallenge?.score,
        feedback: data.feedback,
        score: data.score,
      });
      socket.emit('send-notification', {
        to: response.user._id,
        from: sessionToken?.user._id,
        msg: response.notify,
      });
      notification.success({ message: response?.message || 'Successfully evaluated', duration: 3 });
      if (refreshSolutions) {
        refreshSolutions();
      }
      setEvaluateChallenge(null);
    } catch (error) {
      notification.error({ message: error?.message || 'Failed to evaluate', duration: 3 });
    }
  };
  return (
    <ModalPopup
      fullScreen
    >
      {showUploadedFiles && (
        <ModalPopup>
          <div
            className="w-full h-full p-8 rounded-lg shadow flex-col justify-start items-start
              gap-6 inline-flex relative"
          >
            <div className="w-6 h-6 absolute right-2 top-2 cursor-pointer" onClick={() => setShowUploadedFiles(false)}>
              <img src={closeIcon} alt="icon" />
            </div>
            <div className="text-black text-xl font-medium font-['Manrope']">
              Uploaded Files
            </div>
            <div className="self-stretch text-left gap-5 overflow-auto max-h-[65vh]">
              {uploadedFileList?.map((item, index) => (
                <div
                  key={index}
                  className="w-full my-2 p-5 gap-5 rounded-md border-[1px] border-solid border-black"
                >
                  <div className="grid grid-cols-12 gap-5">
                    <span className="col-span-12">
                      <a href={item.file} target="_blank" rel="noreferrer">{item.name}</a>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </ModalPopup>
      )}
      <div className="w-full h-full p-8 bg-purple-50 flex-col justify-start items-start gap-8 inline-flex">
        <div className="self-stretch justify-between items-center inline-flex">
          <div className="text-indigo-600 text-xl font-semibold font-['Manrope']">Evaluate Challenge</div>
          <div className="w-8 h-8 relative" onClick={() => setEvaluateChallenge(false)}>
            <img src={closeIcon} alt="icon" />
          </div>
        </div>
        <div className="self-stretch h-auto flex-col justify-start items-start gap-[26px] flex">
          <div className="self-stretch h-full p-6 bg-white rounded-lg flex-col justify-start items-start gap-8 flex">
            <div className="self-stretch h-full flex-col justify-start items-start gap-4 flex">
              <div
                className="self-stretch pb-3 border-b border-neutral-200 justify-start
                  items-center gap-3 inline-flex cursor-pointer"
                onClick={() => setShowDetails(!showDetails)}
              >
                <div className="w-6 h-6 relative">
                  <img src={arrowDownIcon} alt="icon" className={cn(showDetails && 'rotate-180')} />
                </div>
                <div className="text-indigo-600 text-base font-medium font-['Manrope']">Challenge Details</div>
              </div>
              {showDetails && (
                <>
                  <div
                    className="text-left self-stretch text-black text-opacity-80 text-base
                      font-semibold font-['Manrope']"
                  >
                    Total Points:
                    {' '}
                    {evaluateChallenge?.challenge?.score}
                  </div>
                  <div
                    className="text-left self-stretch text-black text-opacity-80 text-base
                      font-semibold font-['Manrope']"
                  >
                    Challenge Description
                  </div>
                  <div className="self-stretch text-left  text-zinc-700 text-base font-medium font-['Manrope']">
                    <MarkdownView value={evaluateChallenge?.challenge?.detail} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className="w-full h-auto p-6 bg-white rounded-lg flex-col justify-start
            items-start gap-8 inline-flex text-left"
        >
          <div className="self-stretch h-auto flex-col justify-start items-start gap-4 flex">
            <div
              className="self-stretch text-black text-opacity-80 text-base font-semibold font-['Manrope']"
            >
              { evaluateChallenge?.challenge?.title }
              {' solution'}
              :
            </div>
            <div
              className="self-stretch text-zinc-700 text-base font-normal font-['Manrope'] break-all"
            >
              <MarkdownView value={evaluateChallenge?.solutionDescription} />
            </div>
            {evaluateChallenge?.status === 'question-solution-expired' && (
              <div
                className="w-[98px] h-[27px] px-3 py-1 bg-red-50 rounded-[32px] border border-red-600
                  justify-center items-center gap-2.5 inline-flex"
              >
                <div className="text-center text-red-600 text-sm font-normal font-['Manrope'] tracking-tight">
                  Expired
                </div>
              </div>
            )}
            <div className="self-stretch">
              <span className="text-black text-opacity-80 text-base font-semibold font-['Manrope']">Hint Viewed :</span>
              <span className="text-black text-opacity-80 text-base font-normal font-['Manrope']">
                {evaluateChallenge?.seenHint ? 'Yes' : 'No'}
              </span>
            </div>
          </div>
          {uploadedFileList?.length > 0 && (
          <div
            className="text-left inline-flex cursor-pointer gap-2"
            onClick={() => setShowUploadedFiles(true)}
          >
            <div className="w-5 h-5 relative">
              <img src={uploadIcon} alt="icon" />
            </div>
            <div className="text-sky-600 text-base font-semibold font-['Manrope']">
              View Uploads
            </div>
          </div>
          )}
        </div>
        {evaluateChallenge?.status !== 'question-solution-expired' && (
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="w-full gap-6">
            <div className="grid grid-cols-12 gap-6 w-full">
              <div className="col-span-12">
                <TextField
                  name="score"
                  type="number"
                  placeholder={`Max score: ${evaluateChallenge.challenge?.score}`}
                  label="score"
                  control={control}
                  errors={errors}
                  customHeight="40px"
                  customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                />
              </div>
              <div className="col-span-12 self-stretch h-auto flex-col justify-end items-start gap-6 flex">
                <div className="self-stretch h-auto flex-col justify-center items-start gap-2 flex">
                  <div className="text-zinc-700 text-sm font-normal font-['Manrope']">Feedback</div>
                  <div className="w-full">
                    <TextEditor
                      control={control}
                      errors={errors}
                      name="feedback"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-12 flex justify-start">
                <Button
                  type="submit"
                  customClass="w-[250px]"
                  // disabled={evaluateChallenge?.status === 'reviewed'}
                  variant={evaluateChallenge?.status === 'reviewed' ? 'black' : 'primary'}
                >
                  <div className="text-white text-base font-bold font-['Manrope']">
                    {evaluateChallenge?.status === 'reviewed' ? 'Evaluation Complete' : 'Evaluate'}
                  </div>
                </Button>
              </div>
            </div>
          </form>
        )}
        <ChatBox
          solutionId={evaluateChallenge?._id}
          commentType="CHALLENGE_SOLUTION"
          currentEventId={evaluateChallenge?.event?._id}
        />
      </div>
    </ModalPopup>
  );
};

export default EvaluateChallenge;
