// @ts-nocheck
import { useCallback, useRef, useState } from 'react';
import ModalPopup from '../../../../components/Modal';
import closeIcon from '../../../../assets/icons/adminScreen/closeIcon.svg';
import { useForm, useWatch } from 'react-hook-form';
import TextField from '../../../../components/TextField';
import SelectBox from '../../../../components/SelectBox';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '../../../../components/Button';
import { notification } from 'antd';
import { useAchievements } from '../../../../hooks/useAchievement';
import { useHelperApi } from '../../../../hooks/useHelperApi';
import downloadIcon from '../../../../assets/icons/downloadIcon.svg';

const CertificatesForm = (props) => {
  const { createAchievement, editAchievement } = useAchievements();
  const { generateFileUrl } = useHelperApi();
  const inputFileRef = useRef(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [generatedUrl, setGeneratedUrl] = useState();

  const { userListData, allEvents } = props;
  const {
    certificateForm,
    setCertificateForm,
    refreshAchievements,
  } = props;

  const schema = yup.object({
    name: yup.string().required('Name is required field').nullable(),
    event: yup.object().required('Event is required field').nullable().nonNullable('Event is required field'),
    type: yup.object().required('Type is required field').nullable().nonNullable('Type is required field'),
    certificateUrl: yup.string().required('Certificate Url is required field').nullable(),
    user: yup.object().required('User is required field').nullable().nonNullable('User is required field'),
    userFullName: yup.string().required('User Name is required field').nullable(),
    certificateFileUrl: yup.string().required('Certificate Document Url is required field').nullable(),
  });

  const types = [
    {
      _id: '1',
      name: 'Certificate',
      type: 'CERTIFICATE',
      label: 'Certificate',
    },
    {
      _id: '2',
      name: 'Badge',
      type: 'BADGE',
      label: 'Badge',
    },
  ];

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...certificateForm?.certificateFormData,
      type: types.find((item) => item.type === certificateForm?.certificateFormData?.type),
      certifiedSkills: certificateForm?.certificateFormData?.certifiedSkills?.map((item) => ({ value: item, label: item })),
    },
    resolver: yupResolver(schema),
  });

  const { event, type } = useWatch({ control });

  const onSubmit = async (data) => {
    let response;
    try {
      const request = {
        ...data,
        id: certificateForm?.certificateFormData?._id,
        user: data?.user?._id,
        event: data?.event?._id,
        type: data?.type?.type,
        certifiedSkills: data?.certifiedSkills?.map((item) => item.value) || [],
      };
      if (certificateForm.certificateFormData) {
        response = await editAchievement(request);
      } else {
        response = await createAchievement(request);
      }
      notification.success({
        message:
          response?.message || `Successfully ${certificateForm.certificateFormData ? 'updated' : 'created'} achievement`,
        duration: 3,
      });
      if (refreshAchievements) {
        refreshAchievements();
      }
      setCertificateForm({ certificateFormData: null, openPopup: false });
    } catch (error) {
      notification.error({
        message: error?.message || `Failed to ${certificateForm.certificateFormData ? 'edit' : 'create'} achievement`,
        duration: 3,
      });
    }
  };

  const onFileChange = (e) => {
    e.preventDefault();

    if (e?.target?.files && e?.target?.files?.length > 0) {
      const uploadedFileList = Array.from(e?.target?.files);
      const uniqueFileUploadedList = uploadedFileList.filter((item) =>
        [
          'jpg', 'jpeg', 'png', 'svg',
        ].includes(item?.name?.split('.')[1]?.toLowerCase()),
      );
      // Use the spread operator to create a new array with the updated files
      const tempUploadedFiles = [...uniqueFileUploadedList];
      setUploadedFiles(tempUploadedFiles);
    }
  };

  const onDropHandler = (e) => {
    e.preventDefault();

    if (e?.dataTransfer?.files && e?.dataTransfer?.files?.length > 0) {
      const uploadedFileList = Array.from(e?.dataTransfer?.files);
      const uniqueFileUploadedList = uploadedFileList.filter((item) =>
        [
          'jpg', 'jpeg', 'png', 'svg',
        ].includes(item?.name?.split('.')[1]?.toLowerCase()),
      );

      // Use the spread operator to create a new array with the updated files
      const tempUploadedFiles = [...uniqueFileUploadedList];
      setUploadedFiles(tempUploadedFiles);
    }
  };

  const generateUrl = useCallback(async () => {
    try {
      const formData = new FormData();
      formData.append('images', uploadedFiles[0]);
      const response = await generateFileUrl(formData);
      notification.success({ message: 'Successfully generated url' });
      setGeneratedUrl(response.url);
      setUploadedFiles([]);
    } catch (error) {
      notification.error({ message: error?.message || 'Failed to generate URL' });
    }
  }, [generateFileUrl, uploadedFiles]);

  return (
    <ModalPopup fullScreen>
      <div
        className="w-full h-screen pt-8 pb-12 bg-purple-50 flex-col justify-start
          items-start gap-8 inline-flex overflow-auto"
      >
        <div className="self-stretch px-12 justify-between items-center inline-flex">
          <div
            className="self-stretch text-indigo-600 text-xl font-semibold font-['Manrope']"
          >
            {certificateForm?.certificateFormData ? 'Edit Certificate' : 'New Certificate'}
          </div>
          <div
            className="w-8 h-8 relative cursor-pointer"
            onClick={() => setCertificateForm({ certificateFormData: null, openPopup: false })}
          >
            <img src={closeIcon} alt="icon" />
          </div>
        </div>
        <div className="self-stretch px-12 flex-col justify-start items-start gap-[26px] flex">
          <div
            className="self-stretch h-full p-6 bg-white rounded-lg flex-col justify-center items-start gap-4 flex"
          >
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="w-full gap-6">
              <div className="grid grid-cols-12 gap-6 w-full">
                <div className="col-span-12">
                  <TextField
                    name="name"
                    type="text"
                    label="Certificate Name"
                    control={control}
                    errors={errors}
                    customHeight="40px"
                    customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                  />
                </div>
                <div className="col-span-12">
                  <SelectBox
                    name="event"
                    placeholder="Select Event"
                    label="Event"
                    options={allEvents || []}
                    control={control}
                    errors={errors}
                    customLabel="name"
                    customValue="_id"
                    customHeight="40px"
                    isClearable
                    onChange={() => setValue('user', null)}
                    customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                  />
                </div>
                <div className="col-span-12">
                  <SelectBox
                    name="type"
                    placeholder="Select Type"
                    label="Type"
                    options={types || []}
                    control={control}
                    errors={errors}
                    customLabel="name"
                    customValue="_id"
                    customHeight="40px"
                    isClearable
                    customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                  />
                </div>
                <div className="col-span-12">
                  <SelectBox
                    name="user"
                    placeholder="Select user"
                    label="User"
                    options={userListData?.filter((user) => user?.events?.includes(event?._id)) || []}
                    control={control}
                    errors={errors}
                    customLabel="userName"
                    customValue="userName"
                    customHeight="40px"
                    isClearable
                    customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                  />
                </div>
                <div className="col-span-12">
                  <TextField
                    name="userFullName"
                    type="text"
                    label="User Full Name (Show in user certificate section)"
                    control={control}
                    errors={errors}
                    customHeight="40px"
                    customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                  />
                </div>
                <div className="col-span-12">
                  <SelectBox
                    name="certifiedSkills"
                    placeholder="Verified Skills"
                    label="Verified Skills"
                    options={certificateForm?.certificateFormData?.certifiedSkills?.map(certificate => ({value: certificate, label: certificate})) || []}
                    control={control}
                    errors={errors}
                    customHeight="40px"
                    isClearable
                    isCreatable
                    isMulti
                    formikSetValue={setValue}
                    customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                  />
                </div>
                <div className="col-span-12">
                  <TextField
                    name="certificateUrl"
                    type="text"
                    label="Certificate URL"
                    control={control}
                    errors={errors}
                    customHeight="40px"
                    customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                  />
                </div>
                <div className="col-span-12">
                  <TextField
                    name="certificateFileUrl"
                    type="text"
                    label="Certificate Document URL"
                    control={control}
                    errors={errors}
                    customHeight="40px"
                    customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                  />
                </div>
                {type && type.type === 'BADGE' && (
                  <div className="col-span-12">
                    <div
                      className="self-stretch p-6 bg-white rounded-lg flex-col justify-center items-start gap-4 flex"
                    >
                      <div className="self-stretch h-[186px] flex-col justify-end items-start gap-6 flex">
                        <div
                          className="text-zinc-700 text-base font-semibold font-['Manrope']"
                        >
                          Generate Image URL
                        </div>
                        <div
                          className="w-full h-[140px] relative flex justify-center items-center cursor-pointer"
                          onDrop={onDropHandler}
                          onClick={() => { inputFileRef.current.click(); }}
                          onDragOver={(e) => e.preventDefault()}
                        >
                          <div
                            className="w-full h-[140px] left-0 top-0 absolute rounded-lg border border-dashed
                              border-black border-opacity-20"
                          />
                          <div className="w-full top-[23.50px] absolute flex-col justify-start items-center gap-2 inline-flex">
                            <div className="w-8 h-8 relative">
                              <img src={downloadIcon} alt="icon" />
                            </div>
                            <div className="">
                              <span className="text-zinc-700 text-base font-normal font-['Manrope'] break-words">
                                Drop your files here, or
                                {' '}
          
                              </span>
                              <span
                                className="text-indigo-600 text-base font-normal font-['Manrope'] cursor-pointer"
                              >
                                browse
                              </span>
                            </div>
                            <div className="text-black text-opacity-20 text-sm font-normal font-['Manrope']">
                              Supports PNG, JPG, SVG,
                            </div>
                            <input
                              type="file"
                              onChange={onFileChange}
                              onDrop={onFileChange}
                              ref={inputFileRef}
                              name="files"
                              id="file"
                              className="hidden"
                              accept=".jpg, .jpeg, .png, .svg"
                            />
                          </div>
                        </div>
                      </div>
                      {uploadedFiles?.length > 0 && uploadedFiles?.map((item, index) => (
                        <div key={index} className="w-full my-2 p-3 gap-5 rounded-md border-[2px] border-solid border-black">
                          <div className="grid grid-cols-6 gap-5">
                            <span className="col-span-12">
                              {item?.name}
                            </span>
                          </div>
                        </div>
                      ),
                      )}
                      <Button
                        disabled={uploadedFiles?.length <= 0}
                        type="button"
                        variant="primary-outline"
                        onClick={() => generateUrl()}
                      >
                        <div className="text-indigo-600 text-base font-bold font-['Manrope']">Generate URL</div>
                      </Button>
                      {generatedUrl && (
                      <div className="text-indigo-600 rounded-md border-[2px] mb-11 p-[20px]">
                        <span><b>Generated Url:</b></span>
                        {' '}
                        {generatedUrl}
                      </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="col-span-12 flex justify-start">
                  <Button
                    type="submit"
                  >
                    <div className="text-white text-base font-bold font-['Manrope']">Save</div>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};

export default CertificatesForm;
