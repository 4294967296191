/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/function-component-definition */
import { useState } from 'react';
import cn from 'classnames';
import { Controller } from 'react-hook-form';
import styles from './textField.module.css';
import hidePasswordIcon from '../../assets/icons/hidePasswordIcon.svg';
import showPasswordIcon from '../../assets/icons/showPasswordIcon.svg';
import searchIcon from '../../assets/icons/adminScreen/searchIcon.svg';

const TextField = ({ ...props }) => {
  const {
    id,
    name,
    rules,
    type,
    errors,
    customClass,
    readOnly,
    defaultValue,
    customHeight,
    customWidth,
    customStyle,
    resetField,
    isClearable,
    label,
    control,
    placeholder,
    isrequired,
    customlabelclass,
    isTogglePassword,
    isSearchBtn,
  } = props;

  const defaultStyle = {
    width: customWidth || '100%',
    height: customHeight || '52px',
    borderRadius: isClearable ? '10px 0px 0px 10px' : '8px',
    border: '2px solid #0000001A',
    margin: 'auto',
    fontSize: '16px',
    padding: '16px',
    background: readOnly && '#e1e6ea',
    cursor: readOnly && 'not-allowed',
    color: 'black',
    paddingRight: isTogglePassword && '40px',
  };

  const defaultClearBtnStyle = {
    width: '25px',
    height: customHeight || '52px',
    borderRadius: '0px 10px 10px 0px',
    border: '2px solid grey',
    margin: '7px auto',
    fontSize: '16px',
    fontWeight: '400',
    background: readOnly ? '#e1e6ea' : '#4C5C68',
    cursor: readOnly && 'not-allowed',
    padding: '5px',
    borderLeft: '0px',
    color: 'white',
  };

  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className={cn('w-full relative',
      (isClearable || isTogglePassword || isSearchBtn) ? 'input-group' : styles.input_container)}
    >
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <>
            <label className={cn((field.value || field.value === 0)
                && styles.filled, customlabelclass || 'font-[500] text-[20px] text-[#030303] leading-[61.07px]')}
            >
              {label}
              {isrequired
                && <span className="text-rose-500 text-base font-medium font-['Manrope'] leading-normal">*</span>}
            </label>
            <input
              {...field}
              {...props}
              style={customStyle || defaultStyle}
              className={'form-control' || customClass}
              id={id || name}
              name={name}
              type={(isTogglePassword && (showPassword ? 'text' : 'password')) || type}
              readOnly={readOnly}
              defaultValue={defaultValue}
              placeholder={placeholder}
            />
          </>
        )}
      />
      {isTogglePassword && (
        <button
          type="button"
          onClick={() => setShowPassword(!showPassword)}
          className="input-group-text z-10 absolute right-3 bottom-4"
        >
          <img
            src={showPassword ? hidePasswordIcon : showPasswordIcon}
            width="25px"
            height="25px"
            alt="icon"
          />
        </button>
      )}
      {isClearable && (
        <button
          type="button"
          style={defaultClearBtnStyle}
          onClick={() => resetField(name)}
          className="input-group-text"
        >
          X
        </button>
      )}
      {isSearchBtn && (
        <button
          type="button"
          className="input-group-text absolute right-2 top-8"
        >
          <img
            src={searchIcon}
            alt="icon"
            width="16px"
            height="16px"
          />
        </button>
      )}
      {errors && errors[name]?.message && (
        <p className="text-danger text-left p-[5px]" style={{ color: 'red' }}>{errors[name].message}</p>
      )}
    </div>
  );
};

export default TextField;
