import cn from 'classnames';

const Button = ({ ...props }) => {
  const {
    children,
    variant,
    type,
    customClass,
    disabled,
    onClick,
  } = props;

  const handleVariantStyle = () => {
    let variantStyle = '';
    switch (variant) {
      case 'primary':
        variantStyle = `px-8 py-3 bg-gradient-to-b from-indigo-600 to-indigo-800
          rounded-md justify-center items-center gap-[12.72px] inline-flex`;
        return variantStyle;
      case 'primary-outline':
        variantStyle = `px-8 py-3 rounded-md border-2 border-indigo-600
          justify-center items-center gap-[12.30px] flex`;
        return variantStyle;
      case 'black':
        variantStyle = `px-8 py-3 rounded-md bg-[#343434]
          justify-center items-center gap-[12.30px] flex`;
        return variantStyle;
      default:
        variantStyle = `px-8 py-3 bg-gradient-to-b from-indigo-600 to-indigo-800
          rounded-md justify-center items-center gap-[12.72px] inline-flex`;
        return variantStyle;
    }
  };

  return (
    <button
      type={type || 'button'}
      disabled={disabled}
      className={cn(customClass,
        handleVariantStyle(),
        disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
        'flex justify-center items-center z-10')}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
