import { useAuthentication } from '../contexts/AuthContext';
import CommentService from '../services/CommentService';
import {
  IComment, IGetAllCommentsResponse, IGetCommentRequest, ISendCommentRequest,
} from '../services/CommentService/types';
import { useCallbackWithLoading } from './useCallbackWithLoading';

interface IUseComment {
	getUserComments: (payload: IGetCommentRequest) => Promise<IComment[]>;
  sendComment: (payload: ISendCommentRequest) => Promise<{
    message: string;
  }>;
  getAllComments: (queryParams: Record<string, string | number>) =>
    Promise<IGetAllCommentsResponse>;
  editComment: (payload: {
    commentId: string;
    commentMessage: string;
  }) => Promise<{
      message: string;
  }>;
  deleteComment: (commentId: string) => Promise<{
    message: string;
  }>
}

export const useComment = (): IUseComment => {
  const { sessionToken } = useAuthentication();

  const getUserComments = useCallbackWithLoading(async (payload) => {
    const eventWithChallenges = await CommentService.getUserComments(payload, sessionToken?.rawValue);
    return eventWithChallenges;
  }, [sessionToken?.rawValue]);

  const sendComment = useCallbackWithLoading(async (payload) => {
    const eventLeaderBoard = await CommentService.sendComment(payload, sessionToken?.rawValue);
    return eventLeaderBoard;
  }, [sessionToken?.rawValue]);

  const deleteComment = useCallbackWithLoading(async (commentId: string) => {
    const response = await CommentService.deleteComment(commentId, sessionToken?.rawValue);
    return response;
  }, [sessionToken?.rawValue]);

  const editComment = useCallbackWithLoading(async (payload: { commentId: string, commentMessage: string }) => {
    const commentsResponse = await CommentService.editComment(payload, sessionToken?.rawValue);
    return commentsResponse;
  }, [sessionToken?.rawValue]);

  const getAllComments = useCallbackWithLoading(async (queryParams) => {
    const eventLeaderBoard = await CommentService.getAllComments(queryParams, sessionToken?.rawValue);
    return eventLeaderBoard;
  }, [sessionToken?.rawValue]);

  return {
    getUserComments,
    sendComment,
    getAllComments,
    editComment,
    deleteComment,
  };
};
