// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import dashboardCardTopRightGradient from '../../assets/images/dashboardCardTopRightGradient.svg';
import dashboardCardBottomLeftGradient from '../../assets/images/dashboardCardBottomLeftGradient.svg';
import cn from 'classnames';
import performanceBadge from '../../assets/icons/dashboardScreen/performanceBadge.svg';
import selectedEventOverViewIcon from '../../assets/icons/dashboardScreen/selectedEventOverViewIcon.svg';
import unSelectedEventOverViewIcon from '../../assets/icons/dashboardScreen/unSelectedEventOverViewIcon.svg';
import selectedChallengerIcon from '../../assets/icons/dashboardScreen/selectedChallengesIcon.svg';
import unSelectedChallengerIcon from '../../assets/icons/dashboardScreen/unSelectedChallengesIcon.svg';
import selectedLeaderBoardIcon from '../../assets/icons/dashboardScreen/selectedLeaderBoardIcon.svg';
import unSelectedLeaderBoardIcon from '../../assets/icons/dashboardScreen/unSelectedLeaderBoardIcon.svg';
import selectedReportIcon from '../../assets/icons/dashboardScreen/selectedReportIcon.svg';
import unSelectedReportIcon from '../../assets/icons/dashboardScreen/unSelectedReportIcon.svg';
import selectedPaymentIcon from '../../assets/icons/dashboardScreen/selectedPaymentIcon.svg';
import unSelectedPaymentIcon from '../../assets/icons/dashboardScreen/unSelectedPaymentIcon.svg';
import profileIcon from '../../assets/icons/dashboardScreen/profileIcon.svg';
import goldBadgeIcon from '../../assets/icons/dashboardScreen/goldBadgeIcon.svg';
import silverBadgeIcon from '../../assets/icons/dashboardScreen/silverBadgeIcon.svg';
import bronzeBadgeIcon from '../../assets/icons/dashboardScreen/bronzeBadgeIcon.svg';
import HeaderComp from './HeaderComp';
import FooterComp from './FooterComp';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useAsync } from 'react-use';
import { useEvent } from '../../hooks/useEvent';
import { useAuthentication } from '../../contexts/AuthContext';

const Dashboard = () => {
  const { selectedEvent } = useAuthentication();
  const navigate = useNavigate();
  const location = useLocation();
  const [optionChanged, setOptionChanged] = useState(false);
  const { getEventLeaderBoard } = useEvent();
  const { value: leaderBoardData = [] } = useAsync(() => getEventLeaderBoard(selectedEvent?._id), [selectedEvent]);

  useEffect(() => {
    if (optionChanged) {
      navigate('/user/event-overview')
    }
    setOptionChanged(false);
  }, [optionChanged]);

  const tabOption = [
    {
      label: 'Event Overview',
      selectIcon: selectedEventOverViewIcon,
      unSelectIcon: unSelectedEventOverViewIcon,
      value: 'eventOverview',
      isVisible: true,
      path: 'event-overview',
    },
    {
      label: 'Challenges',
      selectIcon: selectedChallengerIcon,
      unSelectIcon: unSelectedChallengerIcon,
      value: 'challenge',
      isVisible: selectedEvent?.type?.type !== 'VULNERABILITY_DISCLOSURE_EVENT',
      path: 'challenges',
    },
    {
      label: 'Leaderboard',
      selectIcon: selectedLeaderBoardIcon,
      unSelectIcon: unSelectedLeaderBoardIcon,
      value: 'leaderBoard',
      isVisible: selectedEvent?.type?.type !== 'VULNERABILITY_DISCLOSURE_EVENT',
      path: 'leader-board',
    },
    {
      label: 'Reports',
      selectIcon: selectedReportIcon,
      unSelectIcon: unSelectedReportIcon,
      value: 'reports',
      isVisible: selectedEvent?.type?.type === 'VULNERABILITY_DISCLOSURE_EVENT',
      path: 'reports',
    },
    {
      label: 'Payment Settings',
      selectIcon: selectedPaymentIcon,
      unSelectIcon: unSelectedPaymentIcon,
      value: 'paymentSettings',
      isVisible: selectedEvent?.type?.type === 'VULNERABILITY_DISCLOSURE_EVENT',
      path: 'payment-settings',
    }
  ];

  const visibleTabItems = tabOption.filter(item => item?.isVisible);

  const handleBadgeIcon = (rank) => {
    let badgeIcon = '';
    switch (rank) {
      case 0: badgeIcon = goldBadgeIcon;
        return badgeIcon;
      case 1: badgeIcon = silverBadgeIcon;
        return badgeIcon;
      default: badgeIcon = bronzeBadgeIcon;
        return badgeIcon;
    }
  };

  return (
    <>
      {/* HeaderSection */}
      <HeaderComp
        showEventOptions={true}
        setOptionChanged={setOptionChanged}
      />
      <div className="relative w-full h-auto px-12 justify-between items-start inline-flex py-[32px] bg-[#F7F7FF]">
        <div className="absolute w-[347px] h-[300px] bottom-[32px] right-0 bg-no-repeat bg-[url('assets/images/dashboardCardBgImage.svg')] bg-start" />
        {/* <div className={cn(`p-6 relative rounded-lg flex-col justify-start items-start gap-6 inline-flex !bg-[url('assets/images/courseCardGradientImg.svg') bg-cover bg-center`)}> */}
        <div className='w-full grid grid-cols-12 gap-[32px]'>
        <div className={cn('col-span-9 p-6 bg-white w-auto rounded-lg flex-col justify-start items-start gap-6 inline-flex relative')}>
          {/* <img src={dashboardCardTopRightGradient} alt='img' className='absolute right-0 top-0'/> */}
          {/* <img src={dashboardCardBottomLeftGradient} alt='img' className='absolute left-0 bottom-0'/> */}
          {/* <div className="w-[347.62px] h-[347.62px] opacity-30 bg-sky-600 rounded-full blur-[150px]" /> */}
          <div className="justify-start items-start gap-4 inline-flex z-[1]">
            {visibleTabItems?.map((item) => (
              <div
                onClick={() => navigate(item.path)}
                className={cn("cursor-pointer p-3 justify-start items-start gap-2 flex z-[1]",
                  location?.pathname?.includes(item?.path) && 'bg-gradient-to-b from-blue-200 to-indigo-300 rounded-[32px]')}>
                <div className="w-5 h-5 relative">
                  <img src={location?.pathname?.includes(item?.path) ? item?.selectIcon : item?.unSelectIcon} alt='icon' />
                </div>
                <div className={cn("text-base font-semibold font-['Manrope']",
                  location?.pathname?.includes(item?.path) && 'text-indigo-600 ')}>{item.label}</div>
              </div>
            ))}
          </div>
          <Outlet />
        </div>
        {selectedEvent?.type?.type !== 'VULNERABILITY_DISCLOSURE_EVENT' &&
          leaderBoardData?.leaderboardByEvent?.length > 0 &&
          (
            <div className="col-span-3 sm:p-2 lg:p-6 bg-white max-h-[300px] rounded-lg flex-col justify-start items-start gap-8 inline-flex z-[1]">
              <div className="justify-start items-center gap-2 inline-flex">
                <div className="w-5 h-5 px-[2.50px] justify-center items-center flex">
                  <div className="w-[15px] h-5 relative flex-col justify-start items-start flex">
                    <img src={performanceBadge} alt='icon' />
                  </div>
                </div>
                <div className="text-indigo-600 text-base font-medium font-['Manrope']">Top Performers</div>
              </div>
              <div className="w-full flex-col justify-start items-start gap-6 flex">
                {leaderBoardData?.leaderboardByEvent?.length > 0 && leaderBoardData?.leaderboardByEvent?.map((itemData, index) => {
                  return index <= 2 && (
                    <div className='w-full grid grid-cols-12'>
                      <div className="col-span-10 justify-start items-center gap-2 flex">
                        <img className="w-10 h-10 rounded-full" src={profileIcon} alt='icon' />
                        <div className="flex-col justify-center items-start gap-1 inline-flex">
                          <div className="text-black text-base font-medium font-['Manrope'] lg:max-w-[10vw] md:max-w-[8vw] truncate break-words">
                            {itemData?.userName}
                          </div>
                          <div className="text-black text-opacity-75 text-[10px] font-normal font-['Manrope']">
                            {itemData?.totalScore} points
                          </div>
                        </div>
                      </div>
                      <div className="col-span-2 h-8 relative flex justify-end">
                        <img src={handleBadgeIcon(index)} alt='icon' width='21.75px' />
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        }
      </div>
      </div>
      {/* <FooterSection /> */}
      <FooterComp />
    </>
  );
};

export default Dashboard;