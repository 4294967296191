/* eslint-disable max-len */
/* eslint-disable quotes */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
// @ts-nocheck
import {
  useState, useRef, useEffect, useCallback,
} from 'react';
import cn from 'classnames';
import leftArrowIcon from '../../assets/icons/leftArrowIconBlue.svg';
import rightArrowIcon from '../../assets/icons/rightArrowIconBlue.svg';
import EnrollPopup from './EnrollPopup';
import { useEventType } from '../../hooks/useEventType';
import { useAsync } from 'react-use';
import { useEvent } from '../../hooks/useEvent';
import MarkdownView from '../../components/MarkdownView/MarkdownView';
import { notification } from 'antd';

const EventsSection = () => {
  const [selectedEventType, setSelectedEventType] = useState({});
  const [hoveringEventType, setHoveringEventType] = useState({});
  const [eventsMetaList, setEventsMetaList] = useState([]);
  const eventListStyleDiv = useRef(null);
  const [eventToEnroll, setEventToEnroll] = useState(null);

  const { getEventTypes } = useEventType();
  const { getEventsMeta } = useEvent();

  const { value: eventTypes = [] } = useAsync(async () => {
    const result = await getEventTypes();
    setSelectedEventType(result[0]);
    return result;
  }, [getEventTypes]);

  const getActiveEventsList = useCallback(async () => {
    try {
      if (selectedEventType) {
        const result = await getEventsMeta(selectedEventType._id);
        const eventsListWithShow = result.events?.map((item) => ({
          ...item,
          showMore: false,
        }));
        setEventsMetaList(eventsListWithShow);
      }
    } catch {
      notification.error({ message: 'Something went wrong, refresh or try again!', duration: 3 });
    }
  }, [getEventsMeta, selectedEventType]);

  useEffect(() => {
    getActiveEventsList();
  }, [getActiveEventsList]);

  const calculateScroll = () => {
    let scrollLeftOrRight = 0;
    const eventDataList = document.getElementById('eventDataList');
    const eventDetailCard = document?.getElementById('eventDetailCard');
    const eventCardWidth = eventDetailCard?.clientWidth;
    const courseDataListWidth = eventDataList?.clientWidth;
    scrollLeftOrRight = (Math.floor(courseDataListWidth / eventCardWidth) * eventCardWidth)
      + (48 * Math.floor(courseDataListWidth / eventCardWidth)); // Gap between images is 8px
    return scrollLeftOrRight;
  };

  const scrollToLeft = () => {
    const scrollLeft = calculateScroll();
    if (eventListStyleDiv.current) {
      eventListStyleDiv.current.scrollBy({
        top: 0,
        left: -scrollLeft,
        behavior: 'smooth',
      });
    }
  };

  const scrollToRight = () => {
    const scrollLeft = calculateScroll();
    if (eventListStyleDiv.current) {
      eventListStyleDiv.current.scrollBy({
        top: 0,
        left: scrollLeft,
        behavior: 'smooth',
      });
    }
  };

  const handleUpdate = (index) => {
    const tempListData = eventsMetaList?.map((item, i) =>
      (index !== i ? { ...item, showMore: false } : { ...item, showMore: !(item.showMore) }));
    setEventsMetaList(tempListData);
  };

  return (
    <>
      {eventToEnroll && (
        <EnrollPopup
          setEventToEnroll={setEventToEnroll}
          eventToEnroll={eventToEnroll}
        />
      )}
      <div
        className={cn('w-[468px] h-[433px] absolute top-0 right-0 bg-no-repeat',
          `bg-[url('assets/images/loginEventScreenBgImg.svg')] bg-start`)}
      />
      <div
        className="max-w-[700px] z-10 flex justify-start font-Manrope font-[700] text-[48px] leading-[56px] text-left"
      >
        Join An Event | Start Hacking
      </div>
      <div
        className="opacity-60 text-black max-w-[700px] z-10 flex justify-start pt-[24px] font-Manrope font-[500]
          text-[16px] leading-[24px] text-left"
      >
        VantagePoint events fall into three main categories: Training events, featuring practical labs for ongoing training sessions; Certifications, encompassing labs for certification exams; and Vulnerability Disclosure Programs, which include bug bounty or responsible disclosure initiatives. Participate in any of these events and commence your journey into cybersecurity by honing your hacking skills.
      </div>
      <div className="grid grid-cols-12 gap-6 pt-12 z-10">
        <div
          className="md:col-span-6 lg:col-span-4 xl:col-span-3 flex flex-col gap-8 h-[40vh]
            font-Manrope font-[600] text-[20px] leading-[24px] text-left"
        >
          {eventTypes?.map((type, index) => (
            <div
              key={index}
              onClick={() => setSelectedEventType(type)}
              onMouseOver={() => setHoveringEventType(type)}
              onMouseOut={() => setHoveringEventType()}
              className={cn('w-auto', (selectedEventType._id === type._id || hoveringEventType === type._id)
                && 'bg-clip-content bg-gradient-to-b from-blue-200 to-indigo-300 cursor-pointer rounded-[48px]',
              )}
            >
              <div className="flex flex-row items-center justify-start p-3">
                <div>
                  <div
                    className={cn('h-[46px] w-[46px] flex items-center justify-center',
                      (selectedEventType._id === type._id || hoveringEventType === type._id)
                        ? `bg-[url('assets/icons/ellipseIcon.svg')]`
                        : `bg-[url('assets/icons/ellipseIconLightBg.svg')]`,
                    )}
                  >
                    <div
                      className={cn((selectedEventType._id === type._id || hoveringEventType === type._id)
                        ? `bg-[url('assets/icons/beginnersIcon.svg')]`
                        : `bg-[url('assets/icons/beginnersIconLight.svg')]`, 'bg-cover w-[24px] h-[24px]')}
                    />
                  </div>
                </div>
                <div className="pl-2 text-[18px]">{type.name}</div>
              </div>
            </div>
          ))}
        </div>
        <div
          id="eventListData"
          className="md:col-span-6 lg:col-span-8 xl:col-span-9 font-Manrope overflow-x-auto"
        >
          <div
            className="text-left opacity-80 text-black text-[24px] font-semibold font-Manrope leading-loose"
          >
            Hands on Labs | Certification Exams | BugBounty Programs
          </div>
          <div className="overflow-x-auto overflow-y-hidden" id="eventDataList" ref={eventListStyleDiv}>
            <div className="flex flex-row gap-[48px] mt-8">
              {eventsMetaList?.map((item, index) => (
                <div
                  key={index}
                  id="eventDetailCard"
                  className={cn('relative rounded-[16px] border-1 border-solid border-[#E4E4FF] min-w-[467.36px]',
                    `bg-[url('assets/images/courseCardGradientImg.svg')] bg-cover bg-center`,
                    item?.showMore ? 'h-auto' : 'max-h-full')}
                >
                  <div className="h-full p-8">
                    <div className="text-zinc-900 font-[700] text-[20px] text-left leading-[32px]">
                      {item?.name}
                    </div>
                    <div className="py-[32px]">
                      <hr className="bg-clip-border bg-[#42E8E0] border-1 border-[#3B4DBC]" />
                    </div>
                    <div className="flex flex-row gap-[24px]">
                      {item?.eventType?.map((eventItem, index) => (
                        <div
                          key={index}
                          className="min-w-[129px] p-0.5 bg-gradient-to-r from-cyan-50 to-indigo-50 rounded-[32px]
                            justify-center items-center gap-2.5 inline-flex"
                        >
                          <div
                            className="opacity-60 text-black text-sm font-semibold font-['Manrope'] leading-normal px-5"
                          >
                            {eventItem?.name}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div
                      className={cn('self-stretch text-stone-500 text-base font-semibold mt-8 mb-16 text-left text-[16px]',
                        'leading-[24px] overflow-hidden whitespace-pre-line',
                        item?.showMore ? 'mb-[50px] max-h-fit' : 'max-h-[250px] min-h-[250px]',
                      )}
                    >
                      <p className="self-stretch">
                        {
                          item?.showMore
                            ? (
                              <MarkdownView
                                value={item?.metaData}
                              />
                            )
                            : (
                              <MarkdownView
                                value={item?.metaData.substring(0, 200)}
                              />
                            )
                        }
                      </p>
                    </div>
                    <div className="bottom-[32px] flex justify-start gap-[24px] absolute">
                      <div
                        className="w-[182.03px] h-[59px] p-2 bg-gradient-to-b from-indigo-600 to-indigo-800 rounded-md
                          justify-center items-center gap-[12.72px] inline-flex cursor-pointer"
                        onClick={() => setEventToEnroll(item)}
                      >
                        <div className="text-white text-xl font-bold font-['Manrope']">Enroll Now</div>
                      </div>
                      <div
                        className="w-[170px] h-[59px] p-2 rounded-md border-2 border-indigo-600 justify-center
                          items-center gap-[12.30px] inline-flex cursor-pointer"
                        onClick={() => { handleUpdate(index); }}
                      >
                        <div
                          className="text-indigo-600 text-xl font-semibold font-['Manrope']"
                        >
                          {!item?.showMore ? 'Know More' : 'Show Less'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-start gap-[24px] py-8">
            <div
              className="flex justify-center items-center w-[76.29px] h-[49.58px] rounded-[32px] border-[1px]
                border-solid border-[#DADAFF]"
              onClick={() => scrollToLeft()}
            >
              <img src={leftArrowIcon} alt="icon" width="20px" height="20px" />
            </div>
            <div
              className="flex justify-center items-center w-[76.29px] h-[49.58px] rounded-[32px] border-[1px]
                border-solid border-[#DADAFF]"
              onClick={() => scrollToRight()}
            >
              <img src={rightArrowIcon} alt="icon" width="20px" height="20px" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventsSection;
