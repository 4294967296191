// @ts-nocheck
/* eslint-disable */
import vantagePointIcon from '../../../assets/logo/vantagePointIcon.svg';
import vantagePointText from '../../../assets/logo/vantageText.svg';
import enciphersLogo from '../../../assets/logo/enciphersLogo.svg';
import { NavLink } from 'react-router-dom';

const FooterComp = () => {
  return (
    <div className="w-full h-auto bg-[#EFFAFE] pt-12 pb-5 px-[15vw]">
      <div className="grid grid-cols-12 gap-4">
        <div className="sm:col-span-12 md:col-span-6 lg:col-span-7">
          <div
            className="flex justify-start items-center cursor-pointer"
            // onClick={() => window?.open('https://www.enciphers.com/')}
          >
            <NavLink to={window.location.origin}>
            <img src={vantagePointIcon} alt="" width="50px" height="52x" />
            <img src={vantagePointText} alt="" width="250px" height="24px" className="pl-[8px] max-w-[268.87px]" />
            </NavLink>
          </div>
          <div
            className="flex justify-start items-center pt-[24px] gap-[8px] cursor-pointer"
            onClick={() => window?.open('https://www.enciphers.com/')}
          >
            <span className="flex justify-center items-center text-[14px] font-[400] text-[#5DB3FF]">A product of</span>
            <img src={enciphersLogo} alt="" width="148px" height="56px" />
          </div>
          <div
            className="w-auto opacity-60 text-black text-[16px] font-medium font-['Manrope'] leading-[30px] text-left"
          >
            Hack | Learn | Improve
          </div>
        </div>
        <div className="sm:col-span-6 md:col-span-3 lg:col-span-3 pt-[29.19px] flex flex-col text-left gap-[40px]">
          <div
            className="opacity-60 text-black text-[16px] font-semibold
              font-Manrope leading-normal cursor-pointer"
            onClick={() => window?.open('https://training.enciphers.com/')}
          >
            Enciphers Training
          </div>
          <div
            className="opacity-60 text-black text-[16px] font-semibold
              font-Manrope leading-normal cursor-pointer"
            onClick={() => window?.open('https://chat.enciphers.com/')}
          >
            Chat Platform
          </div>
          <div
            className="opacity-60 text-black text-[16px] font-semibold
              font-Manrope leading-normal cursor-pointer"
            onClick={() => window?.open('https://www.enciphers.com/contact-us')}
          >
            Contact Us
          </div>
        </div>
        <div className="sm:col-span-6 md:col-span-3 lg:col-span-2 pt-[29.19px] flex flex-col text-left gap-[40px]">
          <div
            className="opacity-60 text-black text-[16px] font-semibold
              font-Manrope leading-normal cursor-pointer"
            onClick={() => window?.open('https://www.enciphers.com/about-us')}
          >
            About Us
          </div>
          <div
            className="opacity-60 text-black text-[16px] font-semibold
              font-Manrope leading-normal cursor-pointer"
            onClick={() => window?.open('https://www.enciphers.com/services')}
          >
            Our Services
          </div>
          <div
            className="opacity-60 text-black text-[16px] font-semibold
              font-Manrope leading-normal cursor-pointer"
            onClick={() => window?.open('https://www.enciphers.com/training')}
          >
            Other Trainings
          </div>
        </div>
      </div>
      <div
        className="flex justify-center items-center pt-12 opacity-50 text-center text-black text-base
          font-semibold font-['Manrope'] leading-normal"
      >
        © Copyright 2024 Enciphers - All Rights Reserved.
      </div>
    </div>
  )
}

export default FooterComp;