import { isAxiosError } from 'axios';
import HttpService from '../HttpService';
import { HttpStatus } from '../HttpService/HttpStatus';
import {
  IEventMetaResponse, IEvent, IEventWithChallenges, LeaderBoardResponse, GetAllEventsResponse, CreateOrEditEventRequest,
} from './types';

const getEventsMeta = async ({
  eventTypeId,
  active,
  page,
  limit,
}: {
	eventTypeId?: string;
	page?: number;
	limit?: number;
  active?: boolean;
}): Promise<IEventMetaResponse[]> => {
  const { data } = await HttpService.get<IEventMetaResponse[]>(
    'event/get-all-events-meta',
    undefined,
    {
      active: active === undefined ? true : active,
      eventTypeId,
      limit,
      page,
    },
  );
  return data;
};

const getUserEvents = async (sessionToken?: string): Promise<IEvent[]> => {
  const { data } = await HttpService.get<IEvent[]>(
    'event/get-all-user-events',
    sessionToken,
  );
  return data;
};

const getEventByIdWithChallenges = async (eventId: string, sessionToken?: string): Promise<IEventWithChallenges> => {
  const { data } = await HttpService.get<IEventWithChallenges>(
    `event/${eventId}`,
    sessionToken,
  );
  return data;
};

const getEventLeaderBoard = async (eventId: string, sessionToken?: string) => {
  const { data } = await HttpService.get<LeaderBoardResponse>(
    `dashboard/get-leaderboard/eventId/${eventId}`,
    sessionToken,
  );
  return data;
};

const getAllEvents = async (
  queryParams?: {
    page: number, limit: number,
    searchTerm?: string,
  },
  sessionToken?: string) => {
  try {
    const { data } = await HttpService.get<GetAllEventsResponse>(
      'event/get-all',
      sessionToken,
      queryParams,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error?.response?.data;
    }
    throw error;
  }
};

const setEventActiveStatus = async (payload: { id: string; active: boolean },
  sessionToken?: string) => {
  try {
    const { data } = await HttpService.patch<GetAllEventsResponse>(
      'event/active',
      payload,
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error?.response?.data;
    }
    throw error;
  }
};

const deleteEvent = async (eventId: string,
  sessionToken?: string) => {
  try {
    const { data } = await HttpService.delete<{message: string}>(
      `event/${eventId}`,
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error?.response?.data;
    }
    throw error;
  }
};

const createEvent = async (payload: CreateOrEditEventRequest,
  sessionToken?: string) => {
  try {
    const { data } = await HttpService.post<{message: string}>(
      'event/create',
      payload,
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === HttpStatus.BAD_REQUEST) {
      throw error.response.data;
    }
    throw error;
  }
};

const editEvent = async (payload: CreateOrEditEventRequest,
  sessionToken?: string) => {
  try {
    const { data } = await HttpService.patch<{message: string}>(
      'event/edit',
      payload,
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === HttpStatus.BAD_REQUEST) {
      throw error.response.data;
    }
    throw error;
  }
};

const EventService = {
  getEventsMeta,
  getUserEvents,
  getEventByIdWithChallenges,
  getEventLeaderBoard,
  getAllEvents,
  setEventActiveStatus,
  deleteEvent,
  createEvent,
  editEvent,
};

export default EventService;
