// @ts-nocheck
import { Table, Pagination, ConfigProvider } from 'antd';

const DynamicTable = ({
  dataList, columns, pageSize, totalCount, currentPage, onPageChange,
}) => {
  const handlePageChange = (page) => {
    onPageChange(page);
  };

  return (
    <div className="w-full overflow-auto">
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: '#D9FCFE',
              fontFamily: 'Manrope',
            },
          },
        }}
      >
        <Table
          className="table-auto w-full"
          rowClassName={(record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')}
          dataSource={dataList}
          columns={columns}
          pagination={false}
          headerColor="bg-cyan-100"
        />
      </ConfigProvider>

      <div className="p-3 flex justify-center">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#4158DB',
              colorText: '#818EDD',
              fontFamily: 'Manrope',
            },
          }}
        >
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalCount}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default DynamicTable;
