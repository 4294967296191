// @ts-nocheck
import ModalPopup from '../../../../components/Modal';
import closeIcon from '../../../../assets/icons/closeIcon.svg';
import Button from '../../../../components/Button';
import { useEvent } from '../../../../hooks/useEvent';
import { notification } from 'antd';

const DeactivateEventPopup = (props) => {
  const { deactivateData, setDeactivateData, refreshEvents } = props;

  const { setEventActiveStatus } = useEvent();

  const onDeactivate = async () => {
    try {
      const response = await setEventActiveStatus({ id: deactivateData._id, active: !deactivateData.active });
      notification.success({
        message: response?.message || `Successfully ${deactivateData.active ? 'deactivated' : 'activated'} event`,
        duration: 3,
      });
      if (refreshEvents) {
        await refreshEvents();
      }
      setDeactivateData(null);
    } catch (error) {
      notification.error({
        message: error?.message
          || error?.response?.message
          || `Failed to ${deactivateData.active ? 'deactivate' : 'activate'} event`,
        duration: 3,
      });
    }
  };

  return (
    <ModalPopup>
      <div
        className="w-full h-auto p-8 bg-white rounded-lg shadow flex-col
          justify-start items-start gap-6 inline-flex"
      >
        <div className="w-6 h-6 absolute right-2 top-2 cursor-pointer" onClick={() => setDeactivateData(null)}>
          <img src={closeIcon} alt="icon" />
        </div>
        <div
          className="text-black text-xl font-bold font-['Manrope'] tracking-tight"
        >
          Are you sure you want to deactivate?
        </div>
        <div className="justify-start items-start gap-6 inline-flex">
          <Button onClick={() => onDeactivate()}>
            <div
              className="text-white text-base font-bold font-['Manrope']"
            >
              {deactivateData?.active ? 'Deactivate' : 'Activate'}
            </div>
          </Button>
          <Button
            variant="primary-outline"
            onClick={() => { setDeactivateData(null); }}
          >
            <div className="text-indigo-600 text-base font-bold font-['Manrope']">Cancel</div>
          </Button>
        </div>
      </div>
    </ModalPopup>
  );
};

export default DeactivateEventPopup;
