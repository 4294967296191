/* eslint-disable max-len */
/* eslint-disable quotes */
// @ts-nocheck
import { useRef, useState, useEffect } from 'react';
import leftArrowIcon from '../../assets/icons/leftArrowIcon.svg';
import rightArrowIcon from '../../assets/icons/rightArrowIcon.svg';
import cn from 'classnames';
import { courseList } from './ConstantData';

const CourseDetailSection = () => {
  const [courseListData, setCourseListData] = useState([]);
  const courseListStyleDiv = useRef(null);

  useEffect(() => {
    const courseListModData = courseList?.map((item) => ({
      ...item,
      showMore: false,
    }));
    setCourseListData(courseListModData);
  }, []);

  const calculateScroll = () => {
    let scrollLeftOrRight = 0;
    const eventDataList = document.getElementById('courseDataList');
    const eventDetailCard = document?.getElementById('cardElement');
    const eventCardWidth = eventDetailCard?.clientWidth;
    const courseDataListWidth = eventDataList?.clientWidth;
    scrollLeftOrRight = (Math.floor(courseDataListWidth / eventCardWidth)
      * eventCardWidth) + (48 * Math.floor(courseDataListWidth / eventCardWidth)); // Gap between images is 8px
    return scrollLeftOrRight;
  };

  const scrollToLeft = () => {
    const scrollLeft = calculateScroll();
    if (courseListStyleDiv.current) {
      courseListStyleDiv.current.scrollBy({
        top: 0,
        left: -scrollLeft,
        behavior: 'smooth',
      });
    }
  };

  const scrollToRight = () => {
    const scrollLeft = calculateScroll();
    if (courseListStyleDiv.current) {
      courseListStyleDiv.current.scrollBy({
        top: 0,
        left: scrollLeft,
        behavior: 'smooth',
      });
    }
  };

  const handleKnowMore = (item) => {
    window?.open(item.link);
  };

  return (
    <>
      <div
        className={cn(
          'min-w-[411px] min-h-[391px] absolute top-0 right-0 bg-no-repeat bg-start',
          `bg-[url('assets/images/buildingBg.svg')]`,
        )}
      />
      <div
        className="text-[#FFFFFF] max-w-[807px] z-10 flex justify-start font-Manrope
          font-[700] text-[48px] leading-[56px] text-left"
      >
        Our Online Training Courses
      </div>
      <div
        className="opacity-60 text-[#FFFFFF] max-w-[807px] z-10 flex justify-start
          pt-[24px] font-Manrope font-[500] text-[16px] leading-[24px] text-left"
      >
        Explore our on-demand online training programs. Enroll in these courses to initiate your venture into cybersecurity, and enhance your learning by combining the training with VantagePoint labs for practical hands-on experience.
      </div>
      <div
        className="flex gap-[48px] overflow-x-auto overflow-y-hidden z-10 min-h-[470px]"
        id="courseDataList"
        ref={courseListStyleDiv}
      >
        {courseListData?.map((item, index) => (
          <div
            key={index}
            id="cardElement"
            className={cn(
              'relative mt-[32px] rounded-[16px] border-1 border-solid border-[#E4E4FF]]',
              'min-w-[467.36px] bg-cover bg-center',
              !item?.showMore && 'h-[500px]',
              `bg-[url(assets/images/courseCardGradientImg.svg)]`,
            )}
          >
            <div className="pt-[38.82px] pl-[32.24px] pr-[19.12px] pb-[44.8px]">
              <div className="text-zinc-900 font-[700] text-[24px] text-left leading-[32.78px]">
                {item?.name}
              </div>
              <div className="py-[32px]">
                <hr className="bg-clip-border bg-[#42E8E0] border-1 border-[#3B4DBC]" />
              </div>
              <div className="flex flex-row gap-[24px]">
                {item?.eventType?.map((eventItem) => (
                  <div
                    key={index}
                    className="w-[129px] p-0.5 bg-gradient-to-r from-cyan-50 to-indigo-50 rounded-[32px]
                      justify-center items-center gap-2.5 inline-flex"
                  >
                    <div className="opacity-60 text-black text-sm font-semibold font-['Manrope'] leading-normal">
                      {eventItem?.name}
                    </div>
                  </div>
                ))}
              </div>
              <div
                className={cn(
                  'text-stone-500 text-base font-semibold py-[32px] text-left text-[16px] leading-[24px]',
                  'overflow-auto whitespace-pre-line',
                )}
              >
                <p>
                  {`${item?.metaData.substring(0, 200)}`}
                </p>
              </div>
              <div className="bottom-[32px] flex justify-start gap-[24px] absolute">
                <div
                  className="w-[170px] h-[59px] p-2 rounded-md border-2 border-indigo-600
                    justify-center items-center gap-[12.30px] inline-flex cursor-pointer"
                  onClick={() => {
                    handleKnowMore(item);
                  }}
                >
                  <div className="text-indigo-600 text-xl font-semibold font-['Manrope']">
                    Know More
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-start gap-[24px] py-[43.33px]">
        <div
          className="flex justify-center items-center w-[76.29px] h-[49.58px] rounded-[32px]
            border-[1px] border-solid border-[#DADAFF]"
          onClick={() => scrollToLeft()}
        >
          <img
            src={leftArrowIcon}
            alt="icon"
            width="20px"
            height="20px"
          />
        </div>
        <div
          className="flex justify-center items-center w-[76.29px] h-[49.58px] rounded-[32px]
            border-[1px] border-solid border-[#DADAFF]"
          onClick={() => scrollToRight()}
        >
          <img
            src={rightArrowIcon}
            alt="icon"
            width="20px"
            height="20px"
          />
        </div>
      </div>
    </>
  );
};

export default CourseDetailSection;
