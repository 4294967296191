import { IChallengeCategory } from '../CategoryService/types';
import { IEvent } from '../EventService.ts/types';
import { INotification } from '../NotificationService/types';
import { IUser } from '../UserService/types';

export interface IChallenge {
  totalRegistrations: number;
  duration: number;
  _id: string;
  title: string;
  score: number;
  category: string;
  complexity: 'low' | 'medium' | 'high';
  hint: string;
  prerequisite?: string;
  detail: string;
  updatedAt: string;
  createdAt: string;
}

export enum SolutionStatus {
  NOT_SUBMITTED = 'notSubmitted',
  PENDING = 'pending',
  REVIEWED = 'reviewed',
  SOLUTION_EXPIRED = 'question-solution-expired'
}

interface ISolution {
  challenge: string;
  createdAt: string;
  event: string;
  files: string[];
  score: number;
  seenHint: boolean;
  startAt: string;
  stopAt?: string;
  status: SolutionStatus;
  updatedAt: string;
  user: string;
  solutionDescription?: string;
  __v: number;
  _id: string;
}

export type IChallengeWithCategory = Omit<IChallenge, 'category'> & {
  category: IChallengeCategory
}

enum ChallengeStatus {
  COMPLETED = 'submitted',
  PENDING = 'inProgress',
  SOLUTION_EXPIRED = 'question-solution-expired',
}

export interface IOnGoingChallengesWithEventSolution {
  challenge: IChallengeWithCategory;
  event: IEvent;
  solution: ISolution;
  status: ChallengeStatus;
  createdAt: string;
  updatedAt: string;
  user: string;
  _id: string
}

export interface IUserChallengesWithEventSolutionResponse {
  onGoingChallenges: IOnGoingChallengesWithEventSolution[];
  otherChallenges: IChallengeWithCategory[]
}

interface IUserChallenge {
  challenge: string;
  event: string;
  status: ChallengeStatus;
  user: string;
  solution?: string;
  createdAt: string;
  updatedAt: string;
  _id: string;
}

export interface IChallengeSolutionUserChallenge {
  challenge: IChallenge;
  solution: ISolution;
  userChallenge: IUserChallenge;
}

export type SolutionChallengeEventUserUserChallenge = {
  challenge: IChallenge;
  event: IEvent;
  user: IUser;
  userChallenge: IUserChallenge;
} & Omit<ISolution, 'challenge' | 'event' | 'user'>;

export interface IGetAllSolutionsResponse {
  solution: Array<SolutionChallengeEventUserUserChallenge>;
  totalSolution: number
}

export type GetAllChallengesResponse = {
  challenge: IChallenge[];
  totalChallenge: number;
}

export type CreateChallengeRequest = Omit<IChallenge, 'updatedAt' | 'createdAt' | '_id' | 'totalRegistrations'>;
export type EditChallengeRequest = CreateChallengeRequest & { id: string };

export interface IEvaluateSolutionRequest {
  feedback: string;
  pastScore: number;
  score: number;
  solutionId: string;
}

export interface IEvaluateSolutionResponse {
  user: IUser;
  notify: INotification;
}

export interface IResetChallengeRequest {
  challengeId: string;
  eventId: string;
  userId: string;
}
