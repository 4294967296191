import HttpService from '../HttpService';
import { IEventType } from './types';

const getEventTypes = async (): Promise<IEventType[]> => {
  const { data: { eventTypes } } = await HttpService.get<{eventTypes: IEventType[]}>(
    'event-type',
  );
  return eventTypes;
};

const EventTypeService = {
  getEventTypes,
};

export default EventTypeService;
