import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
} from 'react';

import { SetValueAction, useStorage } from '../hooks/useStorage';
import { ISessionToken } from '../services/JsonWebTokenService/types';
import SessionService from '../services/SessionService';

export interface ISessionTokenContext {
	sessionToken?: ISessionToken;
	setSessionToken: SetValueAction<ISessionToken | undefined>;
    getSessionToken: (email: string, password: string) => Promise<void>;
    deleteSessionToken: () => void;
}

export const SessionTokenContext = createContext<ISessionTokenContext>(
	undefined as any,
);

export const SessionTokenProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [sessionToken, setSessionToken, deleteSessionToken] =	useStorage<ISessionToken>('session-token');

  const getSessionToken = useCallback(
    async (email: string, password: string) => {
      const newSessionToken =	await SessionService.login(email, password);
      setSessionToken(newSessionToken);
    },
    [setSessionToken],
  );

  return (
    <SessionTokenContext.Provider
      value={{
			  sessionToken,
			  setSessionToken,
			  getSessionToken,
			  deleteSessionToken,
      }}
    >
      {children}
    </SessionTokenContext.Provider>
  );
};

export const useSessionToken = () => useContext(SessionTokenContext);
