/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import { useRef } from 'react';
import reportCarIcon from '../../../assets/icons/dashboardScreen/reportCardIcon.svg';
import { usePdf } from '@mikecousins/react-pdf';
import newTabIcon from '../../../assets/icons/newTabIcon.svg';
import { IAchievement } from '../../../services/AchievementService/types';

const Badges = ({ badgesList }: { badgesList: IAchievement[] }) => {

  const PdfViewer = ({ url }: { url: string }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    usePdf({
      file: url,
      page: 1,
      canvasRef, // Include canvasRef in the hook properties
    });

    return (
      <canvas ref={canvasRef} className="max-w-[100%] max-h-[100%] object-contain flex-1 my-3" />
    );
  };

  const handleNewTab = (item: any) => {
    window.open(item?.certificateUrl, '_blank')?.focus();
  };

  return (
    <>
      {badgesList?.length > 0 ? (
        <div className="grid grid-cols-12 gap-4 text-left w-full h-auto overflow-auto">
          {badgesList?.map((item, index) => (
            <div
              className="sm:col-span-6 md:col-span-4 lg:col-span-4 xl:col-span-3 aspect-[1/1] 2xl:col-span-3
                  p-6 bg-[#E3E7FF]
                  rounded-2xl flex-col justify-between items-start inline-flex
                  hover:shadow-lg hover:shadow-[#a8b4f5]"
              key={item?._id}
            >
              <div className="self-stretch grow shrink basis-0 flex-col justify-between items-start flex">
                <div className="self-stretch grow shrink basis-0 flex-col justify-between items-start flex">
                  <div className="self-stretch relative">
                    <div className="w-8 h-8 relative">
                      <img src={reportCarIcon} alt="icon" />
                    </div>
                    <div className="w-6 h-6 absolute top-0 right-0 cursor-pointer" onClick={() => handleNewTab(item)}>
                      <img src={newTabIcon} alt="icon" />
                    </div>
                  </div>
                  <div className="h-[200px] w-full flex justify-center align-middle">
                    <img src={item?.certificateFileUrl} alt="Badge" className="w-full h-full object-contain" />
                  </div>
                  <div className="self-stretch h-[70px] flex-col justify-start items-start gap-2 flex">
                    <div className="self-stretch text-indigo-600 text-lg font-normal font-['Poppins'] leading-tight">
                      {item?.name}
                    </div>
                    <div
                      className="self-stretch opacity-60 text-black text-sm font-normal font-['Poppins'] leading-tight"
                    >
                      {item?.event?.name}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-white text-center text-base font-bold font-['Manrope'] w-full p-20">
          No Badges Have Been Issued Yet
        </div>
      )}
    </>
  );
};

export default Badges;
