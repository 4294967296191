import { useAuthentication } from '../contexts/AuthContext';
import AchievementService from '../services/AchievementService';
import {
  ICreateAchievementRequest, IEditAchievementRequest, IGetAllAchievementsResponse, IUserWihAchievements,
} from '../services/AchievementService/types';
import { useCallbackWithLoading } from './useCallbackWithLoading';

interface IUseAchievement {
  getAllAchievements: (queryParams?: {
      page: number;
      limit: number;
      eventId?: string;
      userId?: string;
    }) => Promise<IGetAllAchievementsResponse>;
  createAchievement: (payload: ICreateAchievementRequest) => Promise<{message: string}>;
  editAchievement: (payload: IEditAchievementRequest) => Promise<{message: string}>;
  deleteAchievement: (id: string) => Promise<{message: string}>;
  getUserWithAchievements: (id: string) => Promise<IUserWihAchievements>
}

export const useAchievements = (): IUseAchievement => {
  const { sessionToken } = useAuthentication();

  const getAllAchievements = useCallbackWithLoading(async (queryParams?: {
    page: number;
    limit: number;
    eventId?: string;
    userId?: string;
  }) => {
    const achievementsResponse = await AchievementService.getAllAchievements(
      queryParams, sessionToken?.rawValue,
    );
    return achievementsResponse;
  }, [sessionToken?.rawValue]);

  const createAchievement = useCallbackWithLoading(async (payload: ICreateAchievementRequest) => {
    const response = await AchievementService.createAchievement(
      payload, sessionToken?.rawValue,
    );
    return response;
  }, [sessionToken?.rawValue]);

  const editAchievement = useCallbackWithLoading(async (payload: IEditAchievementRequest) => {
    const response = await AchievementService.editAchievement(
      payload, sessionToken?.rawValue,
    );
    return response;
  }, [sessionToken?.rawValue]);

  const deleteAchievement = useCallbackWithLoading(async (id: string) => {
    const response = await AchievementService.deleteAchievement(
      id, sessionToken?.rawValue,
    );
    return response;
  }, [sessionToken?.rawValue]);

  const getUserWithAchievements = useCallbackWithLoading(async (id: string) => {
    const response = await AchievementService.getUserWithAchievements(
      id, sessionToken?.rawValue,
    );
    return response;
  }, [sessionToken?.rawValue]);

  return {
    getAllAchievements,
    createAchievement,
    editAchievement,
    deleteAchievement,
    getUserWithAchievements,
  };
};
