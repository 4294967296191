import { useAuthentication } from '../contexts/AuthContext';
import AdminService from '../services/AdminService';
import {
  IAdminDashboard, IGetAllAdminsResponse,
} from '../services/AdminService/types';
import { useCallbackWithLoading } from './useCallbackWithLoading';

interface IUseAdmin {
  getAdminDashboard: (sessionToken?: string | undefined) => Promise<IAdminDashboard>;
  createAdmin: (payload: {
      email: string;
      password: string;
      userName: string;
    }) => Promise<string>;
  getAllAdmins: (queryParams: {
      page: number;
      limit: number;
      adminSearchTerm?: string;
    }) => Promise<IGetAllAdminsResponse>
}

export const useAdmin = (): IUseAdmin => {
  const { sessionToken } = useAuthentication();

  const getAdminDashboard = useCallbackWithLoading(async () => {
    const adminDashboard = await AdminService.getAdminDashboard(sessionToken?.rawValue);
    return adminDashboard;
  }, [sessionToken?.rawValue]);

  const createAdmin = useCallbackWithLoading(async (
    payload: { email: string, password: string, userName: string},
  ) => {
    const response = await AdminService.createAdmin(
      payload, sessionToken?.rawValue,
    );
    return response;
  }, [sessionToken?.rawValue]);

  const getAllAdmins = useCallbackWithLoading(async (
    queryParams: { page: number, limit: number, adminSearchTerm?: string},
  ) => {
    const response = await AdminService.getAllAdmins(
      queryParams, sessionToken?.rawValue,
    );
    return response;
  }, [sessionToken?.rawValue]);

  return {
    getAdminDashboard,
    createAdmin,
    getAllAdmins,
  };
};
