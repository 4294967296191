import { StrictMode, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { LoadingProvider } from './contexts/LoadingContext';
import { SessionTokenProvider } from './contexts/SessionContext';
import AppRoutes from './pages/AppRoutes';
import './Root.css';
import { z } from 'zod';
import { NotificationProvider } from './contexts/NotificationContext';
import ModalPopup from './components/Modal';

const envVariables = z.object({
  REACT_APP_BASE_URL: z.string(),
  REACT_APP_SOCKET_URL: z.string(),
});

envVariables.parse(process.env);

declare global {
  namespace NodeJS {
    interface ProcessEnv extends z.infer<typeof envVariables> {}
  }
}

function Root() {
  const [isMobile, setMobileStatus] = useState(false);
  useEffect(() => {
    const checkDeviceType = () => {
      const isMobile = window.innerWidth <= 750;
      setMobileStatus(isMobile);
    };

    // Initial check on component mount
    checkDeviceType();

    window.addEventListener('resize', checkDeviceType);

    return () => {
      window.removeEventListener('resize', checkDeviceType);
    };
  }, []);
  return (
    isMobile ? (
      <ModalPopup>
        <div className="flex justify-center items-center h-[150px]">
          The application is designed to work only on Desktop mode. Mobile view not supported.
          For best experience on Tab, try landscape mode.
        </div>
      </ModalPopup>
    ) : (
      <StrictMode>
        <LoadingProvider>
          <SessionTokenProvider>
            <AuthProvider>
              <NotificationProvider>
                <BrowserRouter>
                  <AppRoutes />
                </BrowserRouter>
              </NotificationProvider>
            </AuthProvider>
          </SessionTokenProvider>
        </LoadingProvider>
      </StrictMode>
    )
  );
}

export default Root;
