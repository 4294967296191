import { isAxiosError } from 'axios';
import HttpService from '../HttpService';
import { ICreateCategoryRequest, IEditCategoryRequest, IGetAllCategoriesResponse } from './types';

const getAllCategories = async (
  queryParams?: {page: number, limit: number, searchTerm?: string},
  sessionToken?: string,
) => {
  try {
    const { data } = await HttpService.get<IGetAllCategoriesResponse>(
      'category',
      sessionToken,
      queryParams,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

const createCategory = async (
  payload: ICreateCategoryRequest,
  sessionToken?: string,
) => {
  try {
    const { data } = await HttpService.post<{message: string}>(
      'category/create',
      payload,
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

const editCategory = async (
  payload: IEditCategoryRequest,
  sessionToken?: string,
) => {
  try {
    const { data } = await HttpService.patch<{message: string}>(
      'category/edit',
      payload,
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

const deleteCategory = async (
  id: string,
  sessionToken?: string,
) => {
  try {
    const { data } = await HttpService.post<{message: string}>(
      'category/delete',
      { id },
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

const CategoryService = {
  getAllCategories,
  createCategory,
  editCategory,
  deleteCategory,
};

export default CategoryService;
