import React from 'react';
import {
  Navigate, useRoutes,
} from 'react-router-dom';
import Login from './Login';
import { AuthStatus, useAuthentication } from '../contexts/AuthContext';
import { UserRole } from '../services/UserService/types';
import Dashboard from './User';
import ProfileSettings from './User/ProfileSettings';
import EventOverview from './User/EventOverview';
import Challenges from './User/Challenges';
import ChallengesDetails from './User/Challenges/challengesDetails';
import LeaderBoard from './User/LeaderBoard';
import Reports from './User/Reports';
import ViewReports from './User/Reports/viewReports';
import PaymentSettings from './User/PaymentSettings';
import MyProfileForm from './User/ProfileSettings/myProfileForm';
import ChangePasswordForm from './User/ProfileSettings/changePasswordForm';
import SubmitVDPReport from './User/Reports/SubmitVDPReport';
import Admin from './Admin';
import AdminDashboard from './Admin/Dashboard';
import UserAndAdmin from './Admin/UserAndAdmin';
import EventComponent from './Admin/Event';
import AdminChallenge from './Admin/Challenges';
import PocComponent from './Admin/Poc';
import AdminReport from './Admin/Report';
import CategoriesComponent from './Admin/Categories';
import UserRequest from './Admin/UserRequest';
import CertificatesList from './Admin/Certificates';
import Achievements from './User/Achievements';

const AppRoutes: React.FC = () => {
  const { status, sessionToken } = useAuthentication();
  const unAuthorizedRoutes = useRoutes([
    {
      path: '',
      element: <Login />,
    },
    {
      path: 'user/achievements/:userId',
      element: <Achievements />,
    },
    {
      path: '*',
      element: <Navigate to="/" />,
    },
  ]);

  const userRoutes = useRoutes([
    {
      path: 'event-overview',
      element: <EventOverview />,
    },
    {
      path: 'my-reports/:id',
      element: <ViewReports />,
    },
    {
      path: 'user/reports/my-reports/:id',
      element: <ViewReports />,
    },
    {
      path: 'user/challenge/:challengeId/solution',
      element: <ChallengesDetails />,
    },
    {
      path: 'user/reports/submit-report',
      element: <SubmitVDPReport />,
    },
    {
      path: 'user',
      element: <Dashboard />,
      children: [
        {
          path: 'event-overview',
          element: <EventOverview />,
        },
        {
          path: 'challenges',
          element: <Challenges />,
        },
        {
          path: 'leader-board',
          element: <LeaderBoard />,
        },
        {
          path: 'reports',
          element: <Reports />,
        },
        {
          path: 'payment-settings',
          element: <PaymentSettings />,
        },
        {
          path: '',
          element: <Navigate to="event-overview" />,
        },
      ],
    },
    {
      path: 'user/achievements/:userId',
      element: <Achievements />,
    },
    {
      path: 'user/profile',
      element: <ProfileSettings />,
      children: [
        {
          path: 'my-profile',
          element: <MyProfileForm />,
        },
        {
          path: 'change-password',
          element: <ChangePasswordForm />,
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to="user" />,
    },
  ]);

  const adminRoutes = useRoutes([
    {
      path: 'admin',
      element: <Admin />,
      children: [
        {
          path: 'dashboard',
          element: <AdminDashboard />,
        },
        {
          path: 'user',
          element: <UserAndAdmin />,
        },
        {
          path: 'event',
          element: <EventComponent />,
        },
        {
          path: 'challenge',
          element: <AdminChallenge />,
        },
        {
          path: 'poc',
          element: <PocComponent />,
        },
        {
          path: 'report',
          element: <AdminReport />,
        },
        {
          path: 'categories',
          element: <CategoriesComponent />,
        },
        {
          path: 'user-requests',
          element: <UserRequest />,
        },
        {
          path: 'certificates',
          element: <CertificatesList />,
        },
        {
          path: '',
          element: <Navigate to="dashboard" />,
        },
      ],
    },
    {
      path: 'user/achievements/:userId',
      element: <Achievements />,
    },
    {
      path: '*',
      element: <Navigate to="admin" />,
    },
  ]);

  if ([AuthStatus.NOT_AUTHENTICATED, AuthStatus.AUTHENTICATION_EXPIRED].includes(status)) {
    return (
      unAuthorizedRoutes
    );
  }
  if (status === AuthStatus.AUTHENTICATED && sessionToken?.user.role === UserRole.User) {
    return (
      userRoutes
    );
  }
  if (status === AuthStatus.AUTHENTICATED && sessionToken?.user.role === UserRole.Admin) {
    return (
      adminRoutes
    );
  }
  return <></>;
};

export default AppRoutes;
