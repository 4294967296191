import { IEvent, IEventWithChallenges } from '../EventService.ts/types';
import { INotification } from '../NotificationService/types';

export enum UserRole {
    Admin = 'admin',
    User = 'user'
}

export enum ShirtSize {
  Small = 'S',
  Medium = 'M',
  Large = 'L',
  XLarge = 'XL',
  XXLarge = 'XXL',
  XXXLarge = 'XXXL'
}

export interface IUser {
    role: UserRole;
    events: string[];
    totalScore: number;
    bestScore: number;
    active: boolean;
    _id: string;
    email: string;
    code: string;
    userName: string;
    fullAddress: string;
    panNumber: string;
    paymentEmail: string;
    shirtSize?: ShirtSize;
    password?: string,
    userFullName?: string;
    hasPublicProfile?: boolean;
  }

export type UserPaymentSettingsRequest = Pick<IUser,
  'fullAddress' | 'paymentEmail' | 'shirtSize' | 'panNumber' | 'password'>;

export interface IUserChangePasswordRequest {
  password: string,
  oldPassword?: string,
  userName?: string,
  id: string,
}

export interface IUserEventEnrollmentRequest {
  email: string;
  note?: string;
  captcha: string;
  eventId: string;
  name: string;
}

export type GetAllUserResponse = {
  users: Array<Omit<IUser, 'events'> & {
    events: IEventWithChallenges[];
  }>;
  totalUser: number;
}

enum UserEnrollmentStatus {
  PENDING = 'pending',
  COMPLETE = 'complete'
}

interface IUserEnrollment {
  createdAt: string;
  updatedAt: string;
  email: string;
  name: string;
  note: string;
  status: UserEnrollmentStatus;
  _id: string;
  eventId: IEvent
}

export type EditUserEnrollmentRequest = Omit<IUserEnrollment, 'eventId'> & {
  eventId: string;
}

export interface IAllUserEnrollmentsResponse {
  userRequests: Array<IUserEnrollment>;
  totalUserRequests: number;
}

export interface IAddRemoveEventOnUserResponse {
  notify: INotification;
  user: Omit<IUser, 'event'> & { event: IEvent[] };
}
