const CONSTANTS = {
  ADMIN_DASHBOARD: {
    EVENTS_LIMIT: 10,
    SOLUTIONS_LIMIT: 10,
    COMMENTS_LIMIT: 10,
  },
  PAGE_LIMIT: 25,
  USER_NOTIFICATION_LIMIT: 10,
};

export default CONSTANTS;
