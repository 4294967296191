import { Outlet } from 'react-router-dom';
import AdminSideBar from './adminSideBar';

const Admin = () => (
  <div className="w-screen h-screen flex bg-[#F7F7FF]">
    <div className="w-[236px] overflow-hidden">
      <AdminSideBar />
    </div>
    <div className="flex-1 overflow-scroll">
      <Outlet />
    </div>
  </div>
);

export default Admin;
