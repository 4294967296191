import { useAuthentication } from '../contexts/AuthContext';
import EventService from '../services/EventService.ts';
import {
  CreateOrEditEventRequest,
  GetAllEventsResponse,
  IEvent, IEventMetaResponse, IEventWithChallenges, LeaderBoardResponse,
} from '../services/EventService.ts/types';
import { useCallbackWithLoading } from './useCallbackWithLoading';

interface IUseEvent {
	getEventsMeta: (eventTypeId: string | undefined, page: number, limit: number, active?: boolean) =>
    Promise<IEventMetaResponse[]>;
  getUserEvents: () => Promise<IEvent[]>;
  getEventLeaderBoard: (eventId: string) => Promise<LeaderBoardResponse>;
  getEventByIdWithChallenges: (eventId: string) => Promise<IEventWithChallenges>;
  getAllEvents: (queryParams?: {
    page: number;
    limit: number;
    searchTerm?: string | undefined;
  }) => Promise<GetAllEventsResponse>;
  setEventActiveStatus: (payload: {
      id: string;
      active: boolean;
    }) => Promise<GetAllEventsResponse>;
  deleteEvent: (eventId: string) => Promise<{
      message: string;
    }>;
  createEvent: (payload: CreateOrEditEventRequest) => Promise<{
      message: string;
    }>;
  editEvent: (payload: CreateOrEditEventRequest) => Promise<{
      message: string;
    }>
}

export const useEvent = (): IUseEvent => {
  const { sessionToken } = useAuthentication();
  const getEventsMeta = useCallbackWithLoading(async (
    eventTypeId,
    page,
    limit,
    active,
  ) => {
    const events = await EventService.getEventsMeta({
      eventTypeId,
      page,
      limit,
      active,
    });
    return events;
  }, []);

  const getUserEvents = useCallbackWithLoading(async () => {
    const userEvents = await EventService.getUserEvents(sessionToken?.rawValue);
    return userEvents;
  }, [sessionToken?.rawValue]);

  const getEventByIdWithChallenges = useCallbackWithLoading(async (eventId) => {
    const eventWithChallenges = await EventService.getEventByIdWithChallenges(eventId, sessionToken?.rawValue);
    return eventWithChallenges;
  }, [sessionToken?.rawValue]);

  const getEventLeaderBoard = useCallbackWithLoading(async (eventId) => {
    const eventLeaderBoard = await EventService.getEventLeaderBoard(eventId, sessionToken?.rawValue);
    return eventLeaderBoard;
  }, [sessionToken?.rawValue]);

  const getAllEvents = useCallbackWithLoading(async (queryParams?: {
    page: number;
    limit: number;
    searchTerm?: string | undefined;
    },
  ) => {
    const eventLeaderBoard = await EventService.getAllEvents(queryParams, sessionToken?.rawValue);
    return eventLeaderBoard;
  }, [sessionToken?.rawValue]);

  const setEventActiveStatus = useCallbackWithLoading(async (payload: { id: string; active: boolean }) => {
    const response = await EventService.setEventActiveStatus(payload, sessionToken?.rawValue);
    return response;
  }, [sessionToken?.rawValue]);

  const deleteEvent = useCallbackWithLoading(async (eventId: string) => {
    const response = await EventService.deleteEvent(eventId, sessionToken?.rawValue);
    return response;
  }, [sessionToken?.rawValue]);

  const createEvent = useCallbackWithLoading(async (payload: CreateOrEditEventRequest) => {
    const response = await EventService.createEvent(payload, sessionToken?.rawValue);
    return response;
  }, [sessionToken?.rawValue]);

  const editEvent = useCallbackWithLoading(async (payload: CreateOrEditEventRequest) => {
    const response = await EventService.editEvent(payload, sessionToken?.rawValue);
    return response;
  }, [sessionToken?.rawValue]);

  return {
    getEventsMeta,
    getUserEvents,
    getEventByIdWithChallenges,
    getEventLeaderBoard,
    getAllEvents,
    setEventActiveStatus,
    deleteEvent,
    createEvent,
    editEvent,
  };
};
