import { useAuthentication } from '../contexts/AuthContext';
import HelperAPIService from '../services/HelperAPIService';
import { useCallbackWithLoading } from './useCallbackWithLoading';

interface IUseHelperAPI {
  generateFileUrl: (payload: FormData) => Promise<{url: string}>;
}

export const useHelperApi = (): IUseHelperAPI => {
  const { sessionToken } = useAuthentication();

  const generateFileUrl = useCallbackWithLoading(async (payload: FormData) => {
    const generatedUrl = await HelperAPIService.generateFileUrl(payload, sessionToken?.rawValue);
    return generatedUrl;
  }, [sessionToken?.rawValue]);

  return {
    generateFileUrl,
  };
};
