/* eslint-disable max-len */
/* eslint-disable quotes */
// @ts-nocheck
import { useRef, useState, useEffect } from 'react';
import rightArrowIcon from '../../../assets/icons/rightArrowIconBlue.svg';
import leftArrowIcon from '../../../assets/icons/leftArrowIconBlue.svg';
import cn from 'classnames';
import MarkdownView from '../../../components/MarkdownView/MarkdownView';

const EventSection = ({userEvents}) => {
  const [eventList, setEventList] = useState([]);
  const courseListStyleDiv = useRef(null);

  useEffect(() => {
    const prepareUserEventList = async () => {
      const tempEventList = (userEvents || []).map((item) => ({ ...item, name: item.active ? item.name : `${item.name} (Inactive)` }));
      setEventList(tempEventList);
    };

    prepareUserEventList();
  }, [userEvents]);

  const calculateScroll = () => {
    let scrollLeftOrRight = 0;
    const eventDataList = document.getElementById('courseDataList');
    const eventDetailCard = document?.getElementById('cardElement');
    const eventCardWidth = eventDetailCard?.clientWidth;
    const courseDataListWidth = eventDataList?.clientWidth;
    scrollLeftOrRight = (Math.floor(courseDataListWidth / eventCardWidth)
      * eventCardWidth) + (48 * Math.floor(courseDataListWidth / eventCardWidth)); // Gap between images is 8px
    return scrollLeftOrRight;
  };

  const scrollToLeft = () => {
    const scrollLeft = calculateScroll();
    if (courseListStyleDiv.current) {
      courseListStyleDiv.current.scrollBy({
        top: 0,
        left: -scrollLeft,
        behavior: 'smooth',
      });
    }
  };

  const scrollToRight = () => {
    console.log('first');
    const scrollLeft = calculateScroll();
    if (courseListStyleDiv.current) {
      courseListStyleDiv.current.scrollBy({
        top: 0,
        left: scrollLeft,
        behavior: 'smooth',
      });
    }
  };

  const handleUpdate = (index) => {
    const tempListData = eventList?.map((item, i) =>
      (index !== i ? { ...item, showMore: false } : { ...item, showMore: !(item.showMore) }));
    setEventList(tempListData);
  };

  return (
    <>
      <div
        className="max-w-[700px] z-10 flex justify-start font-Manrope font-[700] text-[32px] leading-[56px] text-left"
      >
        Enrolled Events
      </div>
      <div
        className="opacity-60 text-black max-w-[700px] z-10 flex justify-start pt-[24px] font-Manrope font-[500]
          text-[16px] leading-[24px] text-left"
      >
        List Of Enrolled Events By The User
      </div>
      <div
        className="flex gap-[48px] overflow-x-auto overflow-y-hidden z-10 h-auto"
        id="courseDataList"
        ref={courseListStyleDiv}
      >
        {eventList?.map((item, index) => (
          <div
            key={index}
            id="cardElement"
            className={cn(
              'relative mt-[32px] rounded-[16px] border-1 border-solid border-[#E4E4FF]]',
              'min-w-[300px] min-h-[200px] max-w-[300px] w-[300px] bg-cover bg-center',
              item?.showMore ? 'h-auto pb-5' : 'max-h-full',
              `bg-[url(assets/images/courseCardGradientImg.svg)]`,
            )}
          >
            <div className="p-4">
              <div className="text-zinc-900 font-[700] text-[18px] text-left leading-[32.78px]">
                {item?.name}
              </div>
              <div className="py-[24x]">
                <hr className="bg-clip-border bg-[#42E8E0] border-1 border-[#3B4DBC]" />
              </div>
              <div
                className={cn('self-stretch text-stone-500 text-base font-semibold mt-4 text-left text-[16px]',
                  'leading-[24px] overflow-hidden whitespace-pre-line',
                  item?.showMore ? 'max-h-fit mb-12' : 'max-h-[250px] min-h-[250px] mb-8',
                )}
              >
                <p className="self-stretch">
                  {
                    item?.showMore
                      ? (
                        <MarkdownView
                          value={item?.metaData}
                        />
                      )
                      : (
                        <MarkdownView
                          value={item?.metaData.substring(0, 200)}
                          className={"max-h-[220px] overflow-hidden"}
                        />
                      )
                  }
                </p>
              </div>
              <div className="bottom-[15px] flex justify-start gap-[24px] absolute">
                <div
                  className="w-[140px] h-[48px] p-2 rounded-md border-2 border-indigo-600
                    justify-center items-center gap-[12.30px] inline-flex cursor-pointer"
                  onClick={() => {
                    handleUpdate(index);
                  }}
                >
                  <div className="text-indigo-600 text-md font-semibold font-['Manrope']">
                    {!item?.showMore ? 'Know More' : 'Show Less'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-start gap-[24px] py-[43.33px]">
        <div
          className="flex justify-center items-center w-[76.29px] h-[49.58px] rounded-[32px]
            border-[1px] border-solid border-[#DADAFF]"
          onClick={() => scrollToLeft()}
        >
          <img
            src={leftArrowIcon}
            alt="icon"
            width="20px"
            height="20px"
          />
        </div>
        <div
          className="flex justify-center items-center w-[76.29px] h-[49.58px] rounded-[32px]
            border-[1px] border-solid border-[#DADAFF]"
          onClick={() => scrollToRight()}
        >
          <img
            src={rightArrowIcon}
            alt="icon"
            width="20px"
            height="20px"
          />
        </div>
      </div>
    </>
  );
};

export default EventSection;
