// @ts-nocheck
/* eslint-disable */
import { useState, useEffect, useMemo } from 'react';
import vantagePointLogo from '../../../assets/logo/vantagePointLogo.svg';
import eventIcon from '../../../assets/icons/dashboardScreen/eventIcon.svg';
import SelectBox from '../../../components/SelectBox';
import settingIcon from '../../../assets/icons/dashboardScreen/settingIcon.svg';
import achievementIcon from '../../../assets/icons/dashboardScreen/achievementIcon.svg';
import logoutIcon from '../../../assets/icons/dashboardScreen/logoutIcon.svg';
import { useForm, useWatch } from 'react-hook-form';
import profileIcon from '../../../assets/icons/dashboardScreen/profileIcon.svg';
import { useNavigate } from 'react-router';
import { useAuthentication } from '../../../contexts/AuthContext';
import { useEvent } from '../../../hooks/useEvent';
import { useUser } from '../../../hooks/useUser';
import { useAsync } from 'react-use';
import { NavLink } from 'react-router-dom';
import { useSocketNotification } from '../../../contexts/NotificationContext';
import dayjs from 'dayjs';

const HeaderComp = (props) => {
  const { sessionToken, resetAuth, setSelectedEvent, selectedEvent } = useAuthentication();
  const {
    showEventOptions,
    setOptionChanged,
    showUserName,
    hideHeaderIcons,
    headerTitle,
    headerTitleName,
    userFullName,
  } = props;

  const { getUserEvents }  = useEvent();
  const { getUserInfo } = useUser();
  const navigate = useNavigate();
  const { notifications } = useSocketNotification();

  const [showNotification, setShowNotification] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [userEventList, setUserEventList] = useState([]);

  const { value: user } = useAsync(() => getUserInfo(), []);

  const { control, setValue } = useForm({
    defaultValues: useMemo(() => {
      const defaultEvent = selectedEvent ? selectedEvent : (userEventList && userEventList.length > 0 ? userEventList[0] : null);
      return { eventSelected: defaultEvent };
    }, [userEventList]),
  });

  useEffect(() => {
    const fetchUserEventList = async () => {
      try {
        const data = await getUserEvents();
        const tempEventList = (data || []).map((item) => ({ ...item, name: item.active ? item.name : `${item.name} (Inactive)`}))
        setUserEventList(tempEventList);
        if (tempEventList && !watch?.eventSelected) {
          setValue('eventSelected', tempEventList[0]);
        } else if (selectedEvent) {
          const findEvent = tempEventList?.find(event => event._id === selectedEvent._id);
          setSelectedEvent(findEvent || selectedEvent)
          setValue('eventSelected', findEvent);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchUserEventList();
  }, []);

  const watch = useWatch({ control });

  useEffect(() => {
    if (showEventOptions && watch?.eventSelected) {
      setOptionChanged(true);
      setSelectedEvent(watch?.eventSelected)
    }
  }, [showEventOptions, watch?.eventSelected])

  const getNotificationHtml = (message, link) => {
    if (message.match(/<a.*?<\/a>/g)) {
      const messageArrayWithoutAnchorTag = message.split(/<a.*?<\/a>/g);
      const anchorTagString = message.match(/<a.*?<\/a>/g);
      const anchorTag = new DOMParser().parseFromString(
        anchorTagString,
        'text/xml'
      );

      return (
        <div className="icon-content">
          <span
            dangerouslySetInnerHTML={{
              __html: messageArrayWithoutAnchorTag[0]
            }}
          />
          <span className="text-sky-600 text-base font-medium font-['Manrope']">
            {/* <NavLink to={link}> */}
              <span
                dangerouslySetInnerHTML={{
                  __html: anchorTag.firstChild.innerHTML
                }}
              />
            {/* </NavLink> */}
          </span>
          <span
            dangerouslySetInnerHTML={{
              __html: messageArrayWithoutAnchorTag[1]
            }}
          />
        </div>
      );
    } else {
      return (
        <div className="icon-content">
          <span
            dangerouslySetInnerHTML={{
              __html: message
            }}
          />
        </div>
      );
    }
  };

  return (
    <div className="w-full h-auto px-12 pt-4 pb-6 flex-col justify-start items-start gap-4 inline-flex bg-gradient-to-r from-[#18348A] to-[#000059]">
        <div className="self-stretch justify-between items-center inline-flex">
          <div className="w-[128.75px] h-5 relative">
            <div className="w-[128.75px] h-5 left-0 top-0 absolute">
              <div
                className='flex justify-start cursor-pointer'
                // onClick={() => window?.open('https://www.enciphers.com/')}
              >
                <NavLink to={window.location.origin}>
                <img src={vantagePointLogo} alt='' width="128.75px" height='64px' />
                </NavLink>
              </div>
            </div>
          </div>
          {headerTitle && (
            <div className="text-white text-2xl font-bold font-['Manrope']">
              {headerTitleName}
            </div>
          )}
          {hideHeaderIcons && <div />}
          {!hideHeaderIcons && (
            <div className="justify-start items-center gap-6 flex">
              <div className="w-6 h-6 relative"
                onClick={() => { setShowNotification(true); setShowProfile(false); }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                  <g clip-path="url(#clip0_694_260)">
                    <path d="M5.11953 9.59986C5.11888 8.10389 5.58421 6.64487 6.45087 5.42551C7.31754 4.20616 8.54249 3.28704 9.95553 2.79586C9.90081 2.45304 9.92112 2.10242 10.0151 1.7682C10.109 1.43398 10.2743 1.12412 10.4996 0.859998C10.7249 0.595874 11.0049 0.383776 11.3201 0.238336C11.6353 0.0928963 11.9784 0.0175781 12.3255 0.0175781C12.6727 0.0175781 13.0157 0.0928963 13.331 0.238336C13.6462 0.383776 13.9261 0.595874 14.1514 0.859998C14.3767 1.12412 14.5421 1.43398 14.636 1.7682C14.7299 2.10242 14.7503 2.45304 14.6955 2.79586C16.1063 3.28905 17.3287 4.20904 18.1931 5.42822C19.0575 6.6474 19.5211 8.10535 19.5195 9.59986V16.7999L23.1195 19.1999V20.3999H1.51953V19.1999L5.11953 16.7999V9.59986ZM14.7195 21.5999C14.7195 22.2364 14.4667 22.8468 14.0166 23.2969C13.5665 23.747 12.9561 23.9999 12.3195 23.9999C11.683 23.9999 11.0726 23.747 10.6225 23.2969C10.1724 22.8468 9.91953 22.2364 9.91953 21.5999H14.7195Z" fill="#9999CD"/>
                    {notifications && notifications.length > 0 && (
                      <circle cx="19.8203" cy="5.5" r="4" fill="#FF6060" stroke="#000059"/>
                    )}
                  </g>
                  <defs>
                    <clipPath id="clip0_694_260">
                      <rect width="24" height="24" fill="white" transform="translate(0.320312)"/>
                    </clipPath>
                  </defs>
                </svg>
                {showNotification && (
                  <div className='absolute left-[-275px] top-[40px] z-[25] text-left'
                    onMouseEnter={() => setShowNotification(true)}
                    onMouseLeave={() => setShowNotification(false)}
                  >
                    <div className="w-[300px] max-h-[530px] p-6 bg-white rounded-lg shadow flex-col justify-start items-start gap-6 inline-flex overflow-auto cursor-pointer">
                      {notifications && notifications.length > 0 ? notifications.map((item) =>(
                        <div className="self-stretch h-auto pb-2 border-b border-neutral-200 flex-col justify-start items-start gap-2 flex">
                          <div className="self-stretch h-auto flex-col justify-center items-start flex">
                            <div className="self-stretch text-zinc-700 text-base font-medium font-['Manrope']">
                              {item.message && getNotificationHtml(item.message, item.relativeLink)}
                            </div>
                            {/* <div className="text-sky-600 text-base font-medium font-['Manrope']">Penetration testing training</div> */}
                          </div>
                          <div className="self-stretch h-4 flex-col justify-center items-start flex">
                            <div className="self-stretch text-black text-opacity-20 text-xs font-medium font-['Manrope']">{dayjs(item.createdAt).format('DD MMMM YYYY')}</div>
                          </div>
                        </div>
                      )) : (
                        <div className="self-stretch h-auto flex-col justify-center items-start flex">Notification box is empty</div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {!hideHeaderIcons && (
                <div className="w-10 h-10 justify-center items-center flex relative" onClick={() => { setShowProfile(true); setShowNotification(false); }}>
                  <img className="w-10 h-10 rounded-full" src={profileIcon} alt='icon'/>
                    {showProfile && (
                      <div
                        className='absolute left-[-260px] top-[48px] z-[25] text-left'
                        onMouseEnter={() => setShowProfile(true)}
                        onMouseLeave={() => setShowProfile(false)}
                      >
                        <div className="w-[300px] h-[230px] bg-white rounded-lg shadow flex-col justify-start items-start gap-6 inline-flex">
                          <div className="w-full h-[74px] px-6 py-4 bg-gradient-to-b from-indigo-600 to-indigo-800 rounded-tl-lg rounded-tr-lg flex-col justify-start items-start gap-1 flex">
                            <div className="self-stretch text-white text-base font-medium font-['Manrope']">{user?.userName}</div>
                            <div className="self-stretch text-sky-50 text-xs font-medium font-['Manrope']">Total points: {user?.totalScore}</div>
                          </div>
                          <div className="self-stretch h-[22px] px-[24px] flex-col justify-start items-start gap-[24px] flex">
                            <div
                              className="self-stretch justify-start items-center gap-3 inline-flex cursor-pointer"
                              onClick={() => navigate(`/user/achievements/${user?._id}`)}
                            >
                              <div className="w-4 h-4 relative">
                                <img src={achievementIcon} alt='icon' />
                              </div>
                              <div className="grow shrink basis-0 text-zinc-700 text-base font-medium font-['Manrope']">Achievements</div>
                            </div>
                            <div
                              className="self-stretch justify-start items-center gap-3 inline-flex cursor-pointer"
                              onClick={() => navigate('/user/profile/my-profile')}
                            >
                              <div className="w-5 h-5 relative">
                                <img src={settingIcon} alt='icon' />
                              </div>
                              <div className="grow shrink basis-0 text-zinc-700 text-base font-medium font-['Manrope']">Settings</div>
                            </div>
                            <div
                              className="self-stretch justify-start items-center gap-3 inline-flex cursor-pointer"
                              onClick={() => {
                                resetAuth();
                                navigate('/');
                              }}
                            >
                              <div className="w-5 h-5 relative">
                                <img src={logoutIcon} alt='icon' />
                              </div>
                              <div className="grow shrink basis-0 text-zinc-700 text-base font-medium font-['Manrope']">Logout</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              )}
            </div>
          )}
        </div>
        {showUserName && (
          <>
            <div className="self-stretch h-[0px] border border-white border-opacity-20" />
            <div className="justify-start items-center gap-4 inline-flex z-20">
              <div className="text-white text-2xl font-bold font-['Manrope']">Welcome {userFullName}!</div>
            </div>
          </>
        )}
        {showEventOptions && (
          <>
            <div className="self-stretch h-[0px] border border-white border-opacity-20"></div>
            <div className="justify-start items-center gap-4 inline-flex z-20">
              <div className="text-white text-2xl font-bold font-['Manrope']">Welcome {sessionToken?.user?.userName}!</div>
              <div className="md:w-[60vw] lg:w-[622.86px] h-[38px] pl-4 py-2 rounded border border-indigo-800 bg-[#24249C] justify-start items-center gap-2 inline-flex">
                <div className="justify-start items-center gap-1 flex">
                  <div className="w-4 h-4 relative">
                    <img src={eventIcon} alt='icon' />
                  </div>
                  <div className="text-white text-opacity-80 text-base font-medium font-['Manrope']">Event</div>
                </div>
                <div className="w-full text-white text-base font-medium font-['Manrope'] z-10">
                  {/* Demystifying Reverse Engineering for Hackers */}
                  <SelectBox
                    name="eventSelected"
                    options={userEventList || []}
                    control={control}
                    customHeight="38px"
                    modifyCompleteStyle
                    noBorder
                    customLabel='name'
                    customValue='name'
                  />
                </div>
                {/* <div className="w-4 h-4 relative" /> */}
            </div>
            </div>
          </>
        )}
      </div>
  )
}

export default HeaderComp