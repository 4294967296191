// @ts-nocheck
/* eslint-disable */
import { useState, useRef, useEffect } from 'react';
import arrowDownIcon from '../../../assets/icons/dashboardScreen/arrowDownIcon.svg';
import uploadIcon from '../../../assets/icons/dashboardScreen/uploadIcon.svg';
import downloadIcon from '../../../assets/icons/downloadIcon.svg';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/Button';
import ModalPopup from '../../../components/Modal';
import closeIcon from '../../../assets/icons/closeIcon.svg';
import deleteIcon from '../../../assets/icons/deleteIcon.svg';
import { useChallenge } from '../../../hooks/useChallenge';
import { useAuthentication } from '../../../contexts/AuthContext';
import dayjs from 'dayjs';
import { notification } from 'antd';
import { useAsync } from 'react-use';
import { useTimer } from 'react-timer-hook';
import MarkdownView from '../../../components/MarkdownView/MarkdownView';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import './challengeDetails.css';
import { useCallbackWithLoading } from '../../../hooks/useCallbackWithLoading';
import ChatBox from '../../ChatBox';

const ChallengesDetails = () => {
  const [showDetails, setShowDetails] = useState({
    problemStatement: true,
    hint: false,
    comments: false
  });
  const [showUploadedFiles, setShowUploadedFiles] = useState(false);
  const [viewUploadedFiles, setViewUploadedFiles] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [hasNotifiedOfTime, setHasNotifiedOfTime] = useState(false);
  const inputFileRef = useRef(null);
  const navigate = useNavigate();
  const toggleDetails = (field) => {
    setShowDetails((prevSortOrder) => ({
      ...prevSortOrder,
      [field]: !prevSortOrder?.[field]
    }));
  };
  const { selectedEvent } = useAuthentication();
  const {
    getUserSingleChallengeSolution, setChallengeExpiredStatus, setSolutionHintSeen,
    submitSolution,
  } = useChallenge();
  const { challengeId } = useParams();
  const { seconds, minutes, hours, restart } = useTimer({
    expiryTimestamp: dayjs().toDate()
  });

  const [solution, setSolution] = useState('');

  const { value: challengeSolution, loading: isLoading } = useAsync(() => getUserSingleChallengeSolution(selectedEvent?._id, challengeId), [])

  const startTime = dayjs().toDate();

  const getProgress = remainingTime => {
    var totalTime = dayjs(dayjs(startTime).add(challengeSolution?.challenge?.duration, 'm')).diff(
      dayjs(startTime),
      'second'
    );
    const decimals = remainingTime / totalTime;
    const progress = 1 - parseFloat(decimals).toFixed(2);
    setProgress(progress * 100);
  };

  useEffect(() => {
    if (
      challengeSolution?.challenge.duration &&
      challengeSolution?.solution.startAt
    ) {
      restart(
        dayjs(challengeSolution?.solution.startAt)
          .add(challengeSolution?.challenge.duration, 'm')
          .toDate()
      );
    }
  }, [challengeSolution]);

  useEffect(() => {
    if (
      challengeSolution?.challenge?.duration
      && challengeSolution?.solution?.startAt
    ) {
      getProgress(
        dayjs(dayjs(challengeSolution?.solution?.startAt).add(challengeSolution?.challenge?.duration, 'm')).diff(
          dayjs(),
          'second'
        )
      );
    }
  }, [seconds]);

  const totalProgress = 100;
  const isTimeElapsed = !(seconds || minutes || hours);

  useEffect(() => {
    if (
      totalProgress - progress <= 10 &&
      !hasNotifiedOfTime &&
      !isTimeElapsed &&
      challengeSolution?.solution.status === 'notSubmitted' &&
      !isLoading
    ) {
      setHasNotifiedOfTime(true);

      notification.info({
        message: 'The time is about to be over. Submit your response now.',
        duration: 10
      });
    }
  }, [challengeSolution, hasNotifiedOfTime, isLoading, isTimeElapsed, progress]);

  useEffect(() => {
    const totalProgress = 100;
    if (
      progress >= totalProgress &&
      isTimeElapsed &&
      challengeSolution?.solution &&
      ['inProgress', 'notSubmitted'].includes(challengeSolution?.solution.status)
    ) {
      setChallengeExpiredStatus(challengeSolution._id, challengeSolution?.solution._id);
    }
  }, [challengeSolution, isTimeElapsed, progress, setChallengeExpiredStatus]);

  const onFileChange = e => {
    e?.preventDefault();
    if (e?.target?.files && e?.target?.files?.length > 0) {
      const uploadedFileList = Array?.from(e?.target?.files);
      const uniqueFileUploadedList = uploadedFileList?.filter((item) =>
        [
          'jpg', 'jpeg', 'png', 'svg', 'pdf', 'txt', 'mp4', 'wmv',
          'docx', 'doc', 'xls', 'xlsx', 'ppt', 'pptx', 'csv'
        ].includes(item?.name?.split('.')[1]?.toLowerCase())
      );
      setUploadedFiles((prev) => ([ ...prev, ...uniqueFileUploadedList ]));
    };
  };

  const onDropHandler = (e) => {
    e.preventDefault();
    if (e?.dataTransfer?.files && e?.dataTransfer?.files?.length > 0) {
      const uploadedFileList = Array?.from(e?.dataTransfer?.files);
      const uniqueFileUploadedList = uploadedFileList?.filter((item) =>
        [
          'jpg', 'jpeg', 'png', 'svg', 'pdf', 'txt', 'mp4', 'wmv',
          'docx', 'doc', 'xls', 'xlsx', 'ppt', 'pptx', 'csv'
        ].includes(item?.name?.split('.')[1]?.toLowerCase())
      );
      setUploadedFiles((prev) => ([ ...prev, ...uniqueFileUploadedList ]));
    };
  };


  const modalFormSchema = yup.object({
    comments: yup.string().required('Comments is required field').nullable()
  });

  const {
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(modalFormSchema)
  });

  const handleUploadedFileDelete = (index) => {
    const updatedFiles = [...uploadedFiles];
    updatedFiles.splice(index, 1);
    setUploadedFiles(updatedFiles);
    if (!updatedFiles?.length) {
      setShowUploadedFiles(false);
    }
  };

  const onHintClick = async () => {
    if (!challengeSolution?.solution.seenHint && !showDetails.hint) {
      await setSolutionHintSeen(challengeSolution?.solution._id)
    }
    toggleDetails('hint');
  };

  const onSubmitSolution = useCallbackWithLoading(async () => {
    try {
      const formData = new FormData();
      formData.append('challengeId', challengeSolution?._id);
      formData.append('stopAt', dayjs());
      formData.append('solutionDescription', solution);
      formData.append('solutionId', challengeSolution?.solution._id);

      for (let file of uploadedFiles) {
        if (
          [
            'jpg', 'jpeg', 'png', 'svg', 'pdf', 'txt','docx',
            'doc', 'xls', 'xlsx', 'ppt', 'pptx', 'csv'
          ].includes(file?.name?.split('.')[1]?.toLowerCase())
        ) {
          formData.append('images', file);
        } else {
          formData.append('video', file);

        }
      }
      
      await submitSolution(formData);

      notification.success({
        message: 'Successfully submitted your solution',
        duration: 3
      });

      navigate('/user/challenges')
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || error?.message,
        duration: 3
      });
    }
  }, [solution, challengeSolution, uploadedFiles])
  
  const getFileName = (fileData) => {
    const fileValue = fileData?.file.split('/');
    return fileValue[fileValue.length - 1];
  };

  return (
    <div className="w-full min-h-screen h-full pt-4 pb-12 bg-purple-50 flex-col justify-start items-start gap-8 inline-flex">
      {showUploadedFiles && (
        <ModalPopup>
          <div
            className="w-full h-full p-8 rounded-lg shadow flex-col justify-start items-start
              gap-6 inline-flex relative"
          >
            <div className="w-6 h-6 absolute right-2 top-2 cursor-pointer" onClick={() => setShowUploadedFiles(false)}>
              <img src={closeIcon} alt="icon" />
            </div>
            <div className="text-black text-xl font-medium font-['Manrope']">
              Uploaded Files
            </div>
            <div className="self-stretch text-left gap-5 overflow-auto max-h-[65vh]">
              {uploadedFiles?.map((item, index) => (
                <div className="w-full my-2 p-3 gap-5 rounded-md border-[2px] border-solid border-[#E4E4FF]">
                  <div className="grid grid-cols-12 gap-5">
                    <span className="col-span-10">
                      {item?.name}
                    </span>
                    <span
                      className="col-span-2 cursor-pointer flex justify-center items-center"
                      onClick={() => handleUploadedFileDelete(index)}
                    >
                      <img
                        className="w-[15px] h-[20px]"
                        src={deleteIcon}
                        alt='icon'
                        width="15px"
                        height="20px"
                      />
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </ModalPopup>
      )}
       {viewUploadedFiles && (
        <ModalPopup>
          <div
            className="w-full h-full p-8 rounded-lg shadow flex-col justify-start items-start
              gap-6 inline-flex relative"
          >
            <div className="w-6 h-6 absolute right-2 top-2 cursor-pointer" onClick={() => setViewUploadedFiles(false)}>
              <img src={closeIcon} alt="icon" />
            </div>
            <div className="text-black text-xl font-medium font-['Manrope']">
              Uploaded Files
            </div>
            <div className="self-stretch text-left gap-5 overflow-auto max-h-[65vh]">
              {uploadedFiles?.map((item, index) => (
                <div className="w-full my-2 p-3 gap-5 rounded-md border-[2px] border-solid border-[#E4E4FF]">
                  <div className="grid grid-cols-12 gap-5">
                    <span className="col-span-10">
                      <a href={item.file} target="_blank">{getFileName(item)}</a>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </ModalPopup>
      )}
      <div className="self-stretch px-12 justify-between items-center inline-flex">
        <div className="w-[128.42px] h-5 relative">
          <div className="w-[128.42px] h-5 left-0 top-0 absolute">
            <div className="w-[20.18px] h-5 left-0 top-0 absolute">
            </div>
            <div className="w-[105.26px] h-[9.31px] left-[23.16px] top-[7.64px] absolute">
            </div>
          </div>
        </div>
        <div className="text-indigo-600 text-xl font-semibold font-['Manrope']">{challengeSolution?.challenge.title}</div>
        <Button
          onClick={() => navigate('/user/challenges')}
          variant='primary-outline'
        >
          <div className="text-indigo-600 text-base font-bold font-['Manrope']">Exit Challenge</div>
        </Button>
      </div>
      <div className="self-stretch px-12 justify-start items-start gap-[26px] grid grid-cols-12 w-full h-full">
        <div className="col-span-12 lg:col-span-3 text-left w-full flex-col justify-start items-start gap-[26px] inline-flex">
          <div className="self-stretch h-[88px] p-4 bg-gradient-to-b from-indigo-600 to-indigo-800 rounded-lg flex-col justify-center items-start gap-3 flex">
            <div className="self-stretch justify-between items-center inline-flex">
              <div className="text-white text-[12px] font-normal font-['Manrope']">Max Points: {challengeSolution?.challenge?.score}</div>
              <div className="text-white text-[12px] font-normal font-['Manrope']">Duration: {challengeSolution?.challenge?.duration <= 0 ? "No Limit": `${challengeSolution?.challenge?.duration} minutes`}</div>
            </div>
            {(!['pending' ,'reviewed'].includes(challengeSolution?.solution.status) && challengeSolution?.challenge?.duration) && (
            <div className="self-stretch justify-start items-center gap-3 inline-flex">
              <div className="text-white text-[12px] font-normal font-['Manrope']">Time remaining</div>
              <div className="grow shrink basis-0 h-3 relative">
                <div class="w-full h-3 left-0 top-0 absolute bg-gradient-to-b from-blue-200 to-indigo-300 rounded-3xl">
                  <div class="w-full h-3 left-0 top-0 absolute bg-gradient-to-b from-indigo-500 to-sky-400 rounded-3xl" style={{ width: `${progress}%` }}></div>
                </div>
              </div>
              <div className="text-white text-[12px] font-normal font-['Manrope']">{`${hours}:${minutes}:${seconds}`}</div>
            </div>
            )}
          </div>
          <div className="self-stretch h-auto p-6 bg-white rounded-lg flex-col justify-start items-start gap-8 flex overflow-auto">
            <div className="self-stretch h-auto flex-col justify-start items-start gap-4 flex">
              <div
                className="self-stretch pb-3 border-b border-neutral-200 justify-start items-center gap-3 inline-flex cursor-pointer"
                onClick={() => toggleDetails('problemStatement')}
              >
                <div
                  className={cn("w-6 h-6 relative", showDetails?.problemStatement && 'rotate-180')}
                >
                  <img src={arrowDownIcon} alt='icon' />
                </div>
                <div className="text-indigo-600 text-base font-medium font-['Manrope'] text-left">Problem Statement</div>
              </div>
              {showDetails?.problemStatement && (
                <div className="self-stretch text-zinc-700 text-base font-medium font-['Manrope']">
                 <MarkdownView value={challengeSolution?.challenge?.detail} />
                </div>
              )}
            </div>
            <div className="self-stretch h-auto flex-col justify-start items-start gap-2 flex">
              <div
                className="self-stretch pb-3 border-b border-neutral-200 justify-start items-center gap-3 inline-flex cursor-pointer"
                onClick={() => onHintClick()}
              >
                <div
                  className={cn("w-6 h-6 relative", showDetails?.hint && 'rotate-180')}
                >
                  <img src={arrowDownIcon} alt='icon' />
                </div>
                <div className="text-indigo-600 text-base font-medium font-['Manrope']">Hint</div>
              </div>
              {showDetails?.hint && (
                <div className="self-stretch">
                  <span className="text-zinc-700 text-base font-medium font-['Manrope']">
                    <MarkdownView value={challengeSolution?.challenge?.hint} />
                  </span>
                </div>
              )}
            </div>
            {!(((!isTimeElapsed && !!challengeSolution?.challenge.duration) || !challengeSolution?.challenge.duration) && !!selectedEvent?.active && !['pending' ,'reviewed'].includes(challengeSolution?.solution.status)) && (
            <div className="self-stretch h-auto rounded-lg flex-col justify-start items-start gap-4 flex">
              <div
                className="self-stretch pb-3 border-b border-neutral-200 justify-start items-start gap-3 inline-flex cursor-pointer"
                onClick={() => toggleDetails('comments')}
              >
                <div
                  className={cn("w-6 h-6 relative", showDetails?.comments && 'rotate-180')}
                >
                  <img src={arrowDownIcon} alt='icon' />
                </div>
                <div className="text-indigo-600 text-base font-medium font-['Manrope']">Comments</div>
              </div>
              {showDetails?.comments && (
                <>
                  <ChatBox
                    solutionId={challengeSolution?.solution?._id}
                    commentType="CHALLENGE_SOLUTION"
                  />
                </>
              )}
            </div>
            )}
          </div>
        </div>
        {(((!isTimeElapsed && !!challengeSolution?.challenge.duration) || !challengeSolution?.challenge.duration) && !!selectedEvent?.active && !['pending' ,'reviewed'].includes(challengeSolution?.solution.status)) ? (
        <div className="col-span-12 lg:col-span-9 grow shrink basis-0 p-6 bg-white rounded-lg flex-col justify-start items-start gap-6 inline-flex challenge-details">
            <div className="self-stretch flex-col justify-start items-start gap-4 flex">
              <div className="text-black text-base font-normal font-['Mandali']">Solutions</div>
              {/* <img className="self-stretch h-[223px]" src="https://via.placeholder.com/784x223" /> */}
              <div className='self-stretch w-full'>
                <MDEditor
                  previewOptions={{
                    rehypePlugins: [[rehypeSanitize]],
                    source: (
                      <MarkdownView
                        value={solution || "You didn't submit a solution yet"}
                      />
                    )
                  }}
                  placeholder={'...'}
                  rows={10}
                  value={solution}
                  onChange={setSolution}
                  style={{minHeight: '300px', backgroundColor: '#FFF', color: '#000'}}
                />
              </div>
            </div>
            <div className="w-full flex-col justify-start items-start gap-4 flex">
              <div className="text-black text-base font-normal font-['Mandali']">Upload your proof of concept</div>
              <div
                className="w-full h-[140px] relative flex justify-center items-center cursor-pointer"
                onDrop={onDropHandler}
                onClick={() => { inputFileRef.current.click(); }}
                onDragOver={(e) => e.preventDefault()}
              >
                <div className="w-full h-[140px] left-0 top-0 absolute rounded-lg border border-dashed border-black border-opacity-20" />
                <div className="w-full top-[23.50px] absolute flex-col justify-start items-center gap-2 inline-flex">
                  <div className="w-8 h-8 relative">
                    <img src={downloadIcon} alt='icon' />
                  </div>
                  <div className=''>
                    <span className="text-zinc-700 text-base font-normal font-['Manrope'] break-words">
                      Drop your files here, or </span>
                    <span
                      className="text-indigo-600 text-base font-normal font-['Manrope'] cursor-pointer"
                    >
                      browse
                    </span>
                  </div>
                  <div className="text-black text-opacity-20 text-sm font-normal font-['Manrope']">
                    Supports PNG, JPG, SVG, TXT, PDF, MP4, WMV, DOC, XLS
                  </div>
                  <input
                    type='file'
                    onChange={onFileChange}
                    onDrop={onFileChange}
                    ref={inputFileRef}
                    name='files'
                    id='file'
                    className='hidden'
                    accept=".jpg, .jpeg, .png, .svg, .pdf, .txt, .mp4, .wmv, .docx, .doc, .xls, .xlsx, .ppt, .pptx, .csv"
                    // multiple
                  />
                </div>
              </div>
              {uploadedFiles?.length > 0 && (
                <div
                  className={cn("justify-start items-center gap-2 inline-flex cursor-pointer",
                    !uploadedFiles?.length && '!cursor-not-allowed opacity-50')}
                  onClick={() => uploadedFiles?.length && setShowUploadedFiles(true)}
                >
                <div className="w-5 h-5 relative">
                  <img src={uploadIcon} alt='icon' />
                </div>
                <div className="text-sky-600 text-base font-semibold font-['Manrope']">View Uploads</div>
                </div>
              )}
            </div>
            <Button
              disabled={!solution}
              type='button'
              variant='primary'
              onClick={() => onSubmitSolution()}
            >
              <div className="text-white text-base font-bold font-['Manrope']">Submit Solution</div>
            </Button>
        </div>
      ) : challengeSolution?.solution.solutionDescription ? (
        <div className="col-span-12 lg:col-span-9 grow shrink basis-0 p-6 bg-white rounded-lg flex-col justify-start items-start gap-6 inline-flex h-full">
          <div className="flex-col justify-start items-start gap-2 flex">
            <div className="text-black text-base font-medium font-['Manrope'] break-words">
            Review Status
            </div>
            <div className={cn("text-zinc-700 text-base font-medium font-['Manrope'] capitalize",
              challengeSolution?.solution?.status === "reviewed" && '!text-[#219653]',
              challengeSolution?.solution?.status === "pending" && '!text-[#EB5757]',
            )}>
              {challengeSolution?.solution?.status}
            </div>
          </div>
          <div className="self-stretch h-[52px] flex-col justify-start items-start gap-2 flex">
            <div className="text-black text-base font-medium font-['Manrope']">
              Marks Awarded
            </div>
            <div className="text-zinc-700 text-base font-normal font-['Manrope']">
              {challengeSolution?.solution?.score}
            </div>
          </div>
          <div className="self-stretch flex-col justify-start items-start gap-2 flex">
            <div className="text-black text-base font-medium font-['Manrope']">
              Your Solution
            </div>
            <div className="self-stretch text-zinc-700 text-base font-normal font-['Manrope'] break-all">
              <MarkdownView
                linkTarget="_blank"
                value={challengeSolution?.solution?.solutionDescription}
              />
            </div>
            {challengeSolution?.solution?.files?.length > 0 && (
              <div
                className={cn("justify-start items-center gap-2 inline-flex cursor-pointer",
                  !challengeSolution?.solution?.files?.length && '!cursor-not-allowed opacity-50')}
                onClick={() => {
                  if (challengeSolution?.solution?.files?.length){
                    setUploadedFiles(challengeSolution?.solution?.files)
                    setViewUploadedFiles(true)
                  }
                }}
              >
                <div className="w-5 h-5 relative">
                  <img src={uploadIcon} alt='icon' />
                </div>
                <div className="text-sky-600 text-base font-semibold font-['Manrope']">
                  View Uploads
                </div>
              </div>
            )}
          </div>
          <div className="self-stretch flex-col justify-start items-start gap-2 flex">
            <div className="text-black text-base font-medium font-['Manrope']">
              Feedback
            </div>
            <div className="self-stretch text-zinc-700 text-base font-normal font-['Manrope'] break-all">
              <MarkdownView
                value={challengeSolution?.solution?.feedback || 'No feedback given'}
              />
            </div>
          </div>
        </div>
        ) : (
          <div className='col-span-12 lg:col-span-9 grow shrink basis-0 p-6 bg-white rounded-lg flex-col justify-start items-start gap-6 inline-flex h-full'>
            <span className='text-red-500'>
            The time available for this challenge has elapsed. You can no longer submit a solution to this challenge. In case you feel that more time should have been provided for this challenge, please reach out to Enciphers team through the comment option on the left side, and ask for a reset of this challenge.
            </span>
          </div>
        )
        }
      </div>
    </div>
  )
}

export default ChallengesDetails;
