// @ts-nocheck
import ModalPopup from '../../../../components/Modal';
import closeIcon from '../../../../assets/icons/adminScreen/closeIcon.svg';
import downloadIcon from '../../../../assets/icons/downloadIcon.svg';
import { useForm } from 'react-hook-form';
import TextField from '../../../../components/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '../../../../components/Button';
import { useCallback, useRef, useState } from 'react';
import { notification } from 'antd';
import { useCategories } from '../../../../hooks/useCategory';
import { useHelperApi } from '../../../../hooks/useHelperApi';

const CategoryForm = (props) => {
  const inputFileRef = useRef(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [generatedUrl, setGeneratedUrl] = useState();
  const {
    formPopup,
    setFormPopup,
    refreshCategories,
  } = props;

  const { createCategory, editCategory } = useCategories();
  const { generateFileUrl } = useHelperApi();

  const schema = yup.object({
    title: yup.string().required('Title is required field').nullable(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...formPopup?.formData,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const request = {
        ...data,
        id: formPopup.formData?._id,
        url: generatedUrl || formPopup.formData?.url,
      };

      let response;

      if (formPopup.formData) {
        response = await editCategory(request);
      } else {
        response = await createCategory(request);
      }
      notification.success({
        message: response?.message || `Successfully ${formPopup.formData ? 'updated' : 'category'} challenge`,
        duration: 3,
      });
      if (refreshCategories) {
        refreshCategories();
      }
      setFormPopup({ formData: null, openPopup: false });
    } catch (error) {
      notification.error({
        message: error?.message || `Failed to ${formPopup.formData ? 'edit' : 'create'} category`,
        duration: 3,
      });
    }
  };

  const onFileChange = (e) => {
    e.preventDefault();

    if (e?.target?.files && e?.target?.files?.length > 0) {
      const uploadedFileList = Array.from(e?.target?.files);
      const uniqueFileUploadedList = uploadedFileList.filter((item) =>
       [
        'jpg', 'jpeg', 'png', 'svg', 'pdf', 'txt', 'mp4', 'wmv',
        'docx', 'doc', 'xls', 'xlsx', 'ppt', 'pptx', 'csv'
        ].includes(item?.name?.split('.')[1]?.toLowerCase()),
      );

      // Use the spread operator to create a new array with the updated files
      const tempUploadedFiles = [...uniqueFileUploadedList];
      setUploadedFiles(tempUploadedFiles);
    }
  };

  const onDropHandler = (e) => {
    e.preventDefault();

    if (e?.dataTransfer?.files && e?.dataTransfer?.files?.length > 0) {
      const uploadedFileList = Array.from(e?.dataTransfer?.files);
      const uniqueFileUploadedList = uploadedFileList.filter((item) =>
        [
        'jpg', 'jpeg', 'png', 'svg', 'pdf', 'txt', 'mp4', 'wmv',
        'docx', 'doc', 'xls', 'xlsx', 'ppt', 'pptx', 'csv'
        ].includes(item?.name?.split('.')[1]?.toLowerCase()),
      );
      // Use the spread operator to create a new array with the updated files
      const tempUploadedFiles = [...uniqueFileUploadedList];
      setUploadedFiles(tempUploadedFiles);
    }
  };

  const generateUrl = useCallback(async () => {
    try {
      const formData = new FormData();
      formData.append('images', uploadedFiles[0]);
      const response = await generateFileUrl(formData);
      notification.success({ message: 'Successfully generated url' });
      setGeneratedUrl(response.url);
      setUploadedFiles([]);
    } catch (error) {
      notification.error({ message: error?.message || 'Failed to generate URL' });
    }
  }, [generateFileUrl, uploadedFiles]);

  return (
    <ModalPopup fullScreen>
      <div
        className="w-full h-screen pt-8 pb-12 bg-purple-50 flex-col justify-start
          items-start gap-8 inline-flex overflow-auto"
      >
        <div className="self-stretch px-12 justify-between items-center inline-flex">
          <div
            className="text-indigo-600 text-xl font-semibold font-['Manrope']"
          >
            {formPopup?.formData ? 'Edit Category' : 'New Category'}
          </div>
          <div
            className="w-8 h-8 relative cursor-pointer"
            onClick={() => setFormPopup({ ...formPopup, openPopup: false })}
          >
            <img src={closeIcon} alt="icon" />
          </div>
        </div>
        <div className="self-stretch h-auto px-12 flex-col justify-start items-start gap-[26px] flex">
          <div
            className="self-stretch h-auto p-6 bg-white rounded-lg flex-col justify-center items-start gap-4 flex"
          >
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="w-full gap-6">
              <div className="grid grid-cols-12 gap-6 w-full">
                <div className="col-span-12">
                  <TextField
                    name="title"
                    type="text"
                    label="Title"
                    control={control}
                    errors={errors}
                    customHeight="40px"
                    customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                  />
                </div>
                <div className="col-span-8">
                  <div
                    className="self-stretch h-auto bg-white rounded-lg flex-col
                      justify-center items-start gap-4 flex"
                  >
                    <div className="self-stretch h-[186px] flex-col justify-end items-start gap-6 flex">
                      <div
                        className="text-zinc-700 text-base font-semibold font-['Manrope']"
                      >
                        Generate Image URL
                      </div>
                      <div
                        className="w-full h-[140px] relative flex justify-center items-center cursor-pointer"
                        onDrop={onDropHandler}
                        onClick={() => { inputFileRef.current.click(); }}
                        onDragOver={(e) => e.preventDefault()}
                      >
                        <div
                          className="w-full h-[140px] left-0 top-0 absolute rounded-lg border border-dashed
                            border-black border-opacity-20"
                        />
                        <div
                          className="w-full top-[23.50px] absolute flex-col justify-start
                            items-center gap-2 inline-flex"
                        >
                          <div className="w-8 h-8 relative">
                            <img src={downloadIcon} alt="icon" />
                          </div>
                          <div className="">
                            <span className="text-zinc-700 text-base font-normal font-['Manrope'] break-words">
                              Drop your files here, or
                              {' '}

                            </span>
                            <span
                              className="text-indigo-600 text-base font-normal font-['Manrope'] cursor-pointer"
                            >
                              browse
                            </span>
                          </div>
                          <div className="text-black text-opacity-20 text-sm font-normal font-['Manrope']">
                            Supports PNG, JPG, SVG, TXT, PDF, MP4, WMV, DOC, XLS
                          </div>
                          <input
                            type="file"
                            onChange={onFileChange}
                            onDrop={onFileChange}
                            ref={inputFileRef}
                            name="files"
                            id="file"
                            className="hidden"
                            accept=".jpg, .jpeg, .png, .svg, .pdf, .txt, .mp4, .wmv, .docx, .doc, .xls, .xlsx, .ppt, .pptx, .csv"
                          />
                        </div>
                      </div>
                    </div>
                    {uploadedFiles?.length > 0 && uploadedFiles?.map((item, index) => (
                      <div
                        key={index}
                        className="w-full my-2 p-3 gap-5 rounded-md border-[2px] border-solid border-black"
                      >
                        <div className="grid grid-cols-6 gap-5">
                          <span className="col-span-12 flex justify-start items-center">
                            {item?.name}
                          </span>
                        </div>
                      </div>
                    ),
                    )}
                    <Button
                      disabled={uploadedFiles?.length <= 0}
                      type="button"
                      variant="primary-outline"
                      onClick={() => generateUrl()}
                    >
                      <div className="text-indigo-600 text-base font-bold font-['Manrope']">Generate URL</div>
                    </Button>
                    {generatedUrl && (
                    <div className="text-indigo-600 rounded-md border-[2px] mb-11 p-[20px]">
                      <span><b>Generated Url:</b></span>
                      {' '}
                      {generatedUrl}
                    </div>
                    )}
                  </div>
                </div>
                <div className="col-span-12 flex justify-start pt-auto">
                  <Button
                    type="submit"
                  >
                    <div className="text-white text-base font-bold font-['Manrope']">
                      {formPopup?.formData ? 'Save' : 'Create'}
                    </div>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};

export default CategoryForm;
