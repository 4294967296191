import { useAuthentication } from '../contexts/AuthContext';
import VDPReportService from '../services/VDPReportService';
import {
  IEvaluateVDPReportRequest,
  IEvaluateVDPReportResponse,
  IGetAllReports, IVDPReport, IVDPReportHighlights, VDPReportStatus,
} from '../services/VDPReportService/types';
import { useCallbackWithLoading } from './useCallbackWithLoading';

interface IUseVDPReport {
	getVDPReportsByEvent: (eventId: string) => Promise<IVDPReport[]>;
  getVDPReportHighlightsByEvent: (eventId: string) => Promise<IVDPReportHighlights>
  getVDPReportById: (eventId: string, vdpReportId: string) => Promise<IVDPReport>;
  submitReport: (payload: any) => Promise<any>;
  getAllVDPReports: (params: {
      page: number;
      limit: number;
      status: VDPReportStatus;
    }) => Promise<IGetAllReports>;
  evaluateVDPReport: (payload: IEvaluateVDPReportRequest) => Promise<IEvaluateVDPReportResponse>;
}

export const useVDPReport = (): IUseVDPReport => {
  const { sessionToken } = useAuthentication();

  const getVDPReportsByEvent = useCallbackWithLoading(async (eventId) => {
    const vdpReportsByEvent = await VDPReportService.getVDPReportsByEvent(eventId, sessionToken?.rawValue);
    return vdpReportsByEvent;
  }, [sessionToken?.rawValue]);

  const getVDPReportHighlightsByEvent = useCallbackWithLoading(async (eventId) => {
    const reportHilights = await VDPReportService.getVDPReportHighlightsByEvent(eventId, sessionToken?.rawValue);
    return reportHilights;
  }, [sessionToken?.rawValue]);

  const getVDPReportById = useCallbackWithLoading(async (eventId, vdpReportId) => {
    const vdpReport = await VDPReportService.getVDPReportById(eventId, vdpReportId, sessionToken?.rawValue);
    return vdpReport;
  }, [sessionToken?.rawValue]);

  const submitReport = useCallbackWithLoading(async (payload) => {
    const solutionResponse = await VDPReportService.submitReport(
      payload, sessionToken?.rawValue,
    );
    return solutionResponse;
  }, [sessionToken?.rawValue]);

  const getAllVDPReports = useCallbackWithLoading(async (
    params: {page: number, limit: number, status: VDPReportStatus},
  ) => {
    const allVDPReports = await VDPReportService.getAllVDPReports(
      params, sessionToken?.rawValue,
    );
    return allVDPReports;
  }, [sessionToken?.rawValue]);

  const evaluateVDPReport = useCallbackWithLoading(async (payload: IEvaluateVDPReportRequest) => {
    const response = await VDPReportService.evaluateVDPReport(
      payload, sessionToken?.rawValue,
    );
    return response;
  }, [sessionToken?.rawValue]);

  return {
    getVDPReportsByEvent,
    getVDPReportHighlightsByEvent,
    getVDPReportById,
    submitReport,
    getAllVDPReports,
    evaluateVDPReport,
  };
};
