/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Controller } from 'react-hook-form';
import cn from 'classnames';
import styles from './textArea.module.css';

function TextArea({ ...props }) {
  const {
    name,
    rules,
    label,
    placeholder,
    errors,
    customClass,
    rows,
    cols,
    readOnly,
    defaultValue,
    control,
    customlabelclass,
  } = props;

  const customStylings = {
    width: '100%',
    borderRadius: '10px',
    border: '2px solid #0000001A',
    margin: 'auto',
    fontSize: '16px',
    padding: '16px',
    background: readOnly && '#e1e6ea',
    cursor: readOnly && 'not-allowed',
    color: '#464646CC',
  };
  return (
    <div className={cn('w-full', styles.textArea_container)}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <>
            <label
              className={cn(field.value && styles.filled,
                customlabelclass || 'font-[500] text-[20px] text-[#030303] leading-[61.07px]')}
            >
              {label}
            </label>
            <textarea
              {...field}
              style={customStylings}
              className={customClass}
              id={name}
              name={name}
              rows={rows || 4}
              cols={cols || 20}
              readOnly={readOnly}
              placeholder={placeholder}
              defaultValue={defaultValue}
            />
          </>
        )}
      />
      {errors && errors[name]?.message && (
        <p className="text-danger text-left p-[5px]" style={{ color: 'red' }}>{errors[name].message}</p>
      )}
    </div>
  );
}

export default TextArea;
