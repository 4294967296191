import { notification } from 'antd';
import {
  createContext,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { io } from 'socket.io-client';
import CONSTANTS from '../constants';
import { useNotification } from '../hooks/useNotification';

import { INotification } from '../services/NotificationService/types';
import { UserRole } from '../services/UserService/types';
import { useAuthentication } from './AuthContext';

export interface ISessionTokenContext {
	notifications: INotification[];
	setNotifications: React.Dispatch<SetStateAction<INotification[]>>;
  socket: any;
}

export const NotificationContext = createContext<ISessionTokenContext>(
	undefined as any,
);

export const NotificationProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [notifications, setNotifications] =	useState<INotification[]>([]);
  const { getAllNotifications } = useNotification();
  const { sessionToken } = useAuthentication();
  const socket = useRef<any>();

  const getNotifications = useCallback(async () => {
    try {
      const response = await getAllNotifications({ page: 1, limit: CONSTANTS.USER_NOTIFICATION_LIMIT });
      setNotifications(response);
    } catch (error: any) {
      notification.error({ message: error?.message || 'Failed to get notifications' });
    }
  }, [getAllNotifications]);

  useEffect(() => {
    if (sessionToken?.user && sessionToken?.user.role === UserRole.User) {
      getNotifications();
    }
  }, [getNotifications, sessionToken?.user]);

  useEffect(() => {
    if (sessionToken?.user) {
      socket.current = io(process.env.REACT_APP_SOCKET_URL, {
        path: '/api/socket.io',
        transports: ['websocket', 'polling'],
        secure: true,
      });
      socket.current.emit('add-user', sessionToken?.user._id);

      socket.current.on('receive-notification', (notificationReceived: any) => {
        if (notificationReceived && notificationReceived.receiver === sessionToken?.user._id) {
          const isNotificationExistAlready = !notifications.findIndex((ele) => ele._id === notificationReceived._id);

          if (!isNotificationExistAlready) {
            setNotifications((prev) => [notificationReceived, ...prev]);
          }
        }
      });

      socket.current.on('admin-edit-event', (notificationReceived: any) => {
        setNotifications(notificationReceived.notify);
        socket.current.emit('send-notification', {
          to: notificationReceived.receiverUserId,
          msg: notificationReceived.notify,
        });
      });
    }
  }, [notifications, sessionToken?.user]);

  return (
    <NotificationContext.Provider
      value={{
			  notifications,
        setNotifications,
        socket: socket.current,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useSocketNotification = () => useContext(NotificationContext);
