import {
  FC,
  createContext,
  useEffect,
  useCallback,
  useState,
  useMemo,
  PropsWithChildren,
  useContext,
} from 'react';
import { SetValueAction, useStorage } from '../hooks/useStorage';
import { useSessionToken } from './SessionContext';
import JsonWebTokenService from '../services/JsonWebTokenService';
import { ISessionToken } from '../services/JsonWebTokenService/types';
import SessionService from '../services/SessionService';
import { useCallbackWithLoading } from '../hooks/useCallbackWithLoading';
import { SignUpProfilePayload } from '../services/SessionService/types';
import { IEvent } from '../services/EventService.ts/types';

export enum AuthStatus {
	NOT_AUTHENTICATED = 1,
	AUTHENTICATED = 2,
	AUTHENTICATION_EXPIRED = 3,
}

export interface IAuthContext {
	status: AuthStatus;
	sessionToken?: ISessionToken;
	setSessionToken: SetValueAction<ISessionToken | undefined>;
	resetAuth: () => Promise<void>;
	getSessionToken: (email: string, password: string) => Promise<void>;
	signUpSession: (payload: SignUpProfilePayload) => Promise<void>;
  selectedEvent: IEvent | undefined;
  setSelectedEvent: SetValueAction<IEvent | undefined>;
}

export const AuthContext = createContext<IAuthContext>(undefined as any);

const { isExpired } = JsonWebTokenService;

const isTokenValid = (token?: ISessionToken): boolean =>
  !!token && !!token.rawValue && !isExpired(token);

export const AuthProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [status, setStatus] = useState<AuthStatus>(AuthStatus.AUTHENTICATED);

  const { sessionToken, setSessionToken, deleteSessionToken } = useSessionToken();
  const [selectedEvent, setSelectedEvent, deleteSelectedEvent] = useStorage<IEvent>('selected-event');

  const isSessionTokenValid: boolean = useMemo(() => isTokenValid(sessionToken), [sessionToken]);

  const resetAuth = useCallback(async () => {
    deleteSessionToken();
    setSessionToken(undefined);
    deleteSelectedEvent();
  }, [deleteSelectedEvent, deleteSessionToken, setSessionToken]);

  const getSessionToken = useCallbackWithLoading(async (email: string, password: string) => {
    const newSessionToken = await SessionService.login(email, password);
    setSessionToken(newSessionToken);
  }, [setSessionToken]);

  const signUpSession = useCallbackWithLoading(async (payload: SignUpProfilePayload) => {
    const newSessionToken = await SessionService.signUp(payload);
    setSessionToken(newSessionToken);
  }, [setSessionToken]);

  useEffect(() => {
    if (sessionToken && sessionToken.user && isSessionTokenValid) {
      setStatus(AuthStatus.AUTHENTICATED);
    } else if (sessionToken && !isSessionTokenValid) {
      setStatus(AuthStatus.AUTHENTICATION_EXPIRED);
      resetAuth();
    } else {
      setStatus(AuthStatus.NOT_AUTHENTICATED);
    }
  }, [sessionToken, isSessionTokenValid, resetAuth]);

  return (
    <AuthContext.Provider
      value={{
			  status,
			  sessionToken,
        selectedEvent,
        setSelectedEvent,
			  setSessionToken,
			  resetAuth,
			  getSessionToken,
        signUpSession,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuthentication() {
  return useContext(AuthContext);
}
