// @ts-nocheck
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import DynamicTable from '../../../components/DynamicTable';
import moment from 'moment';
import EvaluateReport from './EvaluateReport';
import SelectBox from '../../../components/SelectBox';
import CONSTANTS from '../../../constants';
import { useEvent } from '../../../hooks/useEvent';
import { useVDPReport } from '../../../hooks/useVDPReport';
import { useUser } from '../../../hooks/useUser';
import { useAsync, useAsyncRetry } from 'react-use';

const PocComponent = () => {
  const [evaluateReport, setEvaluateReport] = useState();
  const [page, setPage] = useState(1);
  const { getAllEvents } = useEvent();
  const { getAllVDPReports } = useVDPReport();
  const { getAllUsers } = useUser();

  const {
    control,
    watch,
    formState: { errors },
  } = useForm();

  const { value: eventListData } = useAsync(() => getAllEvents(), []);
  const { value: usersList } = useAsync(() => getAllUsers(), []);
  const search = watch({ control });
  const { user, event } = search;

  useEffect(() => { setPage(1); }, [user, event]);

  const { value: reportsListData, retry: refreshReports } = useAsyncRetry(() =>
    getAllVDPReports({
      page, userId: user?._id, eventId: event?._id, limit: CONSTANTS.PAGE_LIMIT,
    }),
  [page, user, event]);

  const handleStatusData = (data) => {
    switch (data?.status) {
      case 'Pending': return (
        <div
          className="w-auto h-auto px-3 py-1 bg-blue-50 rounded-[32px] border border-blue-900
            justify-center items-center gap-2.5 inline-flex"
        >
          <div
            className="text-center text-blue-900 text-sm font-normal
              font-['Manrope'] tracking-tight"
          >
            Pending
          </div>
        </div>
      );
      case 'Not Accepted': return (
        <div
          className="w-auto h-auto px-3 py-1 bg-red-50 rounded-[32px] border border-red-600
            justify-center items-center gap-2.5 inline-flex"
        >
          <div className="text-center text-red-600 text-sm font-normal font-['Manrope'] tracking-tight">
            Not Accepted
          </div>
        </div>
      );
      case 'Accepted': return (
        <div
          className="w-auto h-auto px-3 py-1 bg-emerald-50 rounded-[32px] border border-emerald-800
            justify-center items-center gap-2.5 inline-flex"
        >
          <div className="text-center text-emerald-800 text-sm font-normal font-['Manrope'] tracking-tight">
            Accepted
          </div>
        </div>
      );
      default: break;
    }
  };

  const columns = [
    {
      title: 'S. No',
      dataIndex: '_id',
      key: '_id',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Event',
      dataIndex: 'event',
      key: 'event',
      render: (event) => event?.name,
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (user) => user?.userName,
    },
    {
      title: 'Report Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Submitted On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => moment(createdAt).format('DD MMM, YYYY'),
    },
    {
      title: 'Status',
      dataIndex: '',
      key: '',
      render: (data) => (
        <div
          className="cursor-pointer"
          onClick={() => setEvaluateReport(data)}
        >
          {handleStatusData(data)}
        </div>
      ),
    },
  ];

  return (
    <div className="w-full h-auto flex-col justify-start items-start gap-8 inline-flex p-8">
      {evaluateReport && (
        <EvaluateReport
          evaluateReport={evaluateReport}
          setEvaluateReport={setEvaluateReport}
          refreshReports={refreshReports}
        />
      )}
      <div className="self-stretch justify-start items-start gap-8 grid grid-cols-12">
        <div
          className="col-span-12 grow shrink basis-0 self-stretch p-6 bg-white rounded-lg flex-col
            justify-center items-start gap-4 inline-flex"
        >
          <form autoComplete="off" className="w-full">
            <div className="grid grid-cols-12 gap-6 w-full">
              <div className="col-span-6">
                <SelectBox
                  name="user"
                  placeholder="User"
                  options={usersList?.users || []}
                  label="Select User"
                  control={control}
                  errors={errors}
                  customLabel="userName"
                  customValue="_id"
                  customHeight="40px"
                  isClearable
                  customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope']"
                />
              </div>
              <div className="col-span-6">
                <SelectBox
                  name="event"
                  placeholder="Event Name"
                  options={eventListData?.events || []}
                  label="Select Event"
                  control={control}
                  errors={errors}
                  customLabel="name"
                  customValue="_id"
                  customHeight="40px"
                  isClearable
                  customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope']"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="self-stretch h-auto w-full p-6 bg-white rounded-lg flex-col justify-start items-start flex">
        <DynamicTable
          dataList={reportsListData?.adminVDPReports}
          columns={columns}
          totalCount={reportsListData?.totalAdminVDPReports}
          pageSize={CONSTANTS.PAGE_LIMIT}
          onPageChange={setPage}
          currentPage={page}
        />
      </div>
    </div>
  );
};

export default PocComponent;
