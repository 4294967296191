// @ts-nocheck
import participantsIcon from '../../../assets/icons/dashboardScreen/participantsIcon.svg';
import challengeIcon from '../../../assets/icons/dashboardScreen/challengeIcon.svg';
import adminIcon from '../../../assets/icons/adminScreen/admin_icon.svg';
import LiveFeed from './LiveFeed';
import UserComments from './UserComments';
import EventDetails from './EventDetails';
import { useAsync, useAsyncRetry } from 'react-use';
import { useAdmin } from '../../../hooks/useAdmin';
import { useChallenge } from '../../../hooks/useChallenge';
import { IGetAllSolutionsResponse, SolutionStatus } from '../../../services/ChallengeService/types';
import { useVDPReport } from '../../../hooks/useVDPReport';
import { IGetAllReports, VDPReportStatus } from '../../../services/VDPReportService/types';

const Dashboard = () => {
  const { getAdminDashboard } = useAdmin();
  const { getAllSolutions } = useChallenge();
  const { getAllVDPReports } = useVDPReport();
  const { value: adminDashboard } = useAsync(() => getAdminDashboard(), []);
  const { value, loading } = useAsyncRetry(() =>
    Promise.all([
      getAllSolutions({ page: 1, limit: 99999999, status: SolutionStatus.PENDING }),
      getAllVDPReports({ page: 1, limit: 99999999, status: VDPReportStatus.PENDING }),
    ]),
  []);

  const [solutions, vdpReports] = (value as [IGetAllSolutionsResponse, IGetAllReports] | undefined) || [];

  return (
    <div className="p-8">
      <div className="grid grid-cols-12 gap-8">
        <div className="col-span-8">
          <div className="w-full h-[271px] p-6 bg-white rounded-lg flex-col
            justify-start items-start gap-4 inline-flex"
          >
            <div className="self-stretch opacity-80 text-black text-xl font-bold font-['Manrope'] tracking-tight">
              Highlights
            </div>
            <div className="self-stretch justify-start items-start gap-4 inline-flex">
              <div className="aspect-square grow shrink basis-0 max-h-[180px] p-6 bg-sky-100 rounded-2xl flex-col
                justify-between items-start inline-flex"
              >
                <div className="self-stretch grow shrink basis-0 flex-col justify-between items-start flex">
                  <div className="w-8 h-8 relative">
                    <img src={participantsIcon} alt="icon" />
                  </div>
                  <div className="self-stretch h-[70px] flex-col justify-start items-start gap-2 flex">
                    <div className="self-stretch text-indigo-600 text-[40px] font-normal
                      font-['Poppins'] leading-[42px]"
                    >
                      {adminDashboard?.totalUsers}
                    </div>
                    <div className="self-stretch opacity-60 text-black text-sm font-normal
                      font-['Poppins'] leading-tight"
                    >
                      Users
                    </div>
                  </div>
                </div>
              </div>
              <div className="aspect-square grow shrink basis-0 max-h-[180px] p-6 bg-indigo-100 rounded-2xl
                flex-col justify-between items-start inline-flex"
              >
                <div className="self-stretch grow shrink basis-0 flex-col justify-between items-start flex">
                  <div className="self-stretch grow shrink basis-0 flex-col justify-between items-start flex">
                    <div className="w-8 h-8 relative">
                      <img src={adminIcon} alt="icon" />
                    </div>
                    <div className="self-stretch h-[70px] flex-col justify-start items-start gap-2 flex">
                      <div className="self-stretch text-indigo-600 text-[40px] font-normal
                        font-['Poppins'] leading-[42px]"
                      >
                        {adminDashboard?.totalAdmins}
                      </div>
                      <div
                        className="self-stretch opacity-60 text-black text-sm font-normal font-['Poppins']
                          leading-tight"
                      >
                        Admins
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="aspect-square grow shrink basis-0 max-h-[180px] p-6 bg-cyan-100 rounded-2xl flex-col
                justify-between items-start inline-flex"
              >
                <div className="self-stretch grow shrink basis-0 flex-col justify-between items-start flex">
                  <div className="self-stretch grow shrink basis-0 flex-col justify-between items-start flex">
                    <div className="w-8 h-8 relative">
                      <img src={challengeIcon} alt="icon" />
                    </div>
                    <div className="self-stretch h-[70px] flex-col justify-start items-start gap-2 flex">
                      <div className="self-stretch text-indigo-600 text-[40px] font-normal
                        font-['Poppins'] leading-[42px]"
                      >
                        {solutions?.totalSolution}
                      </div>
                      <div className="self-stretch opacity-60 text-black text-sm font-normal
                        font-['Poppins'] leading-tight"
                      >
                        Challenge to review
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <EventDetails />
        </div>
        <div className="col-span-4 gap-8">
          <LiveFeed solutions={(!loading && solutions && vdpReports)
            ? solutions.solution.concat(vdpReports.adminVDPReports) : []}
          />
          <UserComments />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
