// @ts-nocheck
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import TextField from '../../../../components/TextField';
import Button from '../../../../components/Button';
import DynamicTable from '../../../../components/DynamicTable';
import moment from 'moment';
import editIcon from '../../../../assets/icons/adminScreen/editIcon.svg';
import deleteIcon from '../../../../assets/icons/adminScreen/deleteIcon.svg';
import DeletePop from '../../CommonPopup/DeleteDataPopup';
import EditUser from '../../CommonPopup/EditUser';
import CreateAdmin from './CreateAdmin';
import CONSTANTS from '../../../../constants';
import { useAsyncRetry } from 'react-use';
import { useAdmin } from '../../../../hooks/useAdmin';

const AdminComponent = () => {
  const [page, setPage] = useState(1);
  const [deleteData, setDeleteData] = useState(null);
  const [editUser, setEditUser] = useState(null);
  const [createAdmin, setCreateAdmin] = useState(false);
  const { getAllAdmins } = useAdmin();

  const {
    control,
    watch,
    formState: { errors },
  } = useForm();

  const adminSearchTerm = watch('adminSearchTerm');

  useEffect(() => { setPage(1); }, [adminSearchTerm]);

  const { value: allAdminUsers, retry } = useAsyncRetry(() =>
    getAllAdmins({ page, adminSearchTerm, limit: CONSTANTS.PAGE_LIMIT }), [adminSearchTerm, page]);

  const columns = [
    {
      title: 'S. No',
      dataIndex: '_id',
      key: '_id',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'User Name',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: 'Email Id',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Registered On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => moment(createdAt).format('DD MMM, YYYY'),
    },
    {

      title: '',
      dataIndex: '',
      key: '',
      render: (data) => (
        <div className="justify-start items-center gap-4 flex">
          <div
            className="justify-center items-center gap-1.5 flex cursor-pointer"
            onClick={() => { setEditUser(data); }}
          >
            <div className="w-4 h-4 relative">
              <img src={editIcon} alt="icon" />
            </div>
            <div className="text-sky-600 text-sm font-medium font-['Manrope'] leading-normal">Edit</div>
          </div>
          <div
            className="justify-start items-center gap-1.5 flex cursor-pointer"
            onClick={() => { setDeleteData(data); }}
          >
            <div className="w-4 h-4 relative">
              <img src={deleteIcon} alt="icon" />
            </div>
            <div className="text-sky-600 text-sm font-medium font-['Manrope'] leading-normal">Delete</div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full h-auto flex-col justify-start items-start gap-8 inline-flex">
      {deleteData && (
        <DeletePop
          deleteData={deleteData}
          setDeleteData={setDeleteData}
          getAdmins={retry}
        />
      )}
      {editUser && (
        <EditUser
          setEditUser={setEditUser}
          editUser={editUser}
        />
      )}
      {createAdmin && (
        <CreateAdmin
          setCreateAdmin={setCreateAdmin}
          getAdmins={retry}
        />
      )}
      <div className="self-stretch justify-start items-start gap-8 grid grid-cols-12">
        <div
          className="col-span-8 grow shrink basis-0 self-stretch p-6 bg-white rounded-lg flex-col
            justify-center items-start gap-4 inline-flex"
        >
          <form autoComplete="off" className="w-full">
            <div className="grid grid-cols-12 gap-6 w-full">
              <div className="col-span-12">
                <TextField
                  name="adminSearchTerm"
                  placeholder="Search by Admin Name"
                  label="Admin Name"
                  control={control}
                  errors={errors}
                  customHeight="40px"
                  customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] pb-2"
                  isSearchBtn
                />
              </div>
            </div>
          </form>
        </div>
        <div className="col-span-4 p-6 bg-white rounded-lg flex-col justify-center items-start gap-3 inline-flex">
          <div
            className="self-stretch text-black text-opacity-60 text-sm font-normal
              font-['Manrope'] tracking-tight"
          >
            New Admin
          </div>
          <div className="self-stretch justify-start items-center gap-4 inline-flex">
            <Button
              customClass="w-full"
              variant="primary-outline"
              onClick={() => { setCreateAdmin(true); }}
            >
              <div className="text-indigo-600 text-base font-bold font-['Manrope']">Create New Admin</div>
            </Button>
          </div>
        </div>
      </div>
      <div className="self-stretch h-auto w-full p-6 bg-white rounded-lg flex-col justify-start items-start flex">
        <DynamicTable
          dataList={allAdminUsers?.users}
          columns={columns}
          pageSize={CONSTANTS.PAGE_LIMIT}
          totalCount={allAdminUsers?.totalUser}
          onPageChange={setPage}
          currentPage={page}
        />
      </div>
    </div>
  );
};

export default AdminComponent;
