import { useAuthentication } from '../contexts/AuthContext';
import UserService from '../services/UserService';
import {
  IUserEventEnrollmentRequest, IUser, IUserChangePasswordRequest,
  UserPaymentSettingsRequest, GetAllUserResponse, IAllUserEnrollmentsResponse,
  EditUserEnrollmentRequest, IAddRemoveEventOnUserResponse,
} from '../services/UserService/types';
import { useCallbackWithLoading } from './useCallbackWithLoading';

interface IUseUser {
	updatePaymentSettings: (payload: UserPaymentSettingsRequest) => Promise<IUser>;
  getUserInfo: () => Promise<IUser>;
  updateUserPassword: (payload: IUserChangePasswordRequest) => Promise<IUser>;
  createEventEnrollmentRequest: (payload: IUserEventEnrollmentRequest) => Promise<any>;
  getAllUsers: (queryParams?: {
      page: number;
      limit: number;
      userName?: string;
      userEmail?: string;
      eventId?: string;
    }) => Promise<GetAllUserResponse>;
  getAllUserEnrollmentRequests: (queryParams?: {
      page: number;
      limit: number;
      searchTerm?: string;
    }) => Promise<IAllUserEnrollmentsResponse>;
  editUserEnrollmentRequest: (payload: EditUserEnrollmentRequest) => Promise<{
      message: string;
    }>;
  deleteUserEnrollmentRequest: (enrollmentId: string) => Promise<{
      message: string;
    }>;
  addUserToEvent: (payload: {
      userId: string;
      eventId: string;
    }) => Promise<IAddRemoveEventOnUserResponse>;
  generateInviteCodes: (payload: {
      codeNumber: number;
      eventId: string;
    }) => Promise<string>;
  deleteUserOrAdmin: (userId: string) => Promise<{message: string}>;
  removeUserFromEvent: (payload: {
      userId: string;
      eventId: string;
    }) => Promise<IAddRemoveEventOnUserResponse>;
  updateUserProfileSettings: (payload: {
      userFullName?: string;
      hasPublicProfile?: boolean;
    }, sessionToken?: string) => Promise<{
      message: string;
    }>
}

export const useUser = (): IUseUser => {
  const { sessionToken } = useAuthentication();

  const updatePaymentSettings = useCallbackWithLoading(async (payload) => {
    const user = await UserService.updatePaymentSettings(payload, sessionToken?.rawValue);
    return user;
  }, [sessionToken?.rawValue]);

  const getUserInfo = useCallbackWithLoading(async () => {
    const user = await UserService.getUserInfo(sessionToken?.rawValue);
    return user;
  }, [sessionToken?.rawValue]);

  const updateUserPassword = useCallbackWithLoading(async (payload) => {
    const user = await UserService.updatePassword(payload, sessionToken?.rawValue);
    return user;
  }, [sessionToken?.rawValue]);

  const createEventEnrollmentRequest = useCallbackWithLoading(async (payload) => {
    const status = await UserService.createEventEnrollmentRequest(payload, sessionToken?.rawValue);
    return status;
  }, [sessionToken?.rawValue]);

  const getAllUsers = useCallbackWithLoading(async (
    queryParams?: {
      page: number, limit: number, userName?: string,
      userEmail?: string, eventId?: string,
    },
  ) => {
    const allUsersWithCount = await UserService.getAllUsers(queryParams, sessionToken?.rawValue);
    return allUsersWithCount;
  }, [sessionToken?.rawValue]);

  const getAllUserEnrollmentRequests = useCallbackWithLoading(async (
    queryParams?: {
      page: number, limit: number, searchTerm?: string,
    },
  ) => {
    const allEnrollmentRequests = await UserService.getAllUserEnrollmentRequests(queryParams, sessionToken?.rawValue);
    return allEnrollmentRequests;
  }, [sessionToken?.rawValue]);

  const editUserEnrollmentRequest = useCallbackWithLoading(async (
    payload: EditUserEnrollmentRequest,
  ) => {
    const editEnrollmentResponse = await UserService.editUserEnrollmentRequest(payload, sessionToken?.rawValue);
    return editEnrollmentResponse;
  }, [sessionToken?.rawValue]);

  const deleteUserEnrollmentRequest = useCallbackWithLoading(async (
    enrollmentId: string,
  ) => {
    const deleteEnrollmentResponse = await UserService.deleteUserEnrollmentRequest(
      enrollmentId, sessionToken?.rawValue,
    );
    return deleteEnrollmentResponse;
  }, [sessionToken?.rawValue]);

  const addUserToEvent = useCallbackWithLoading(async (
    payload: { userId: string, eventId: string},
  ) => {
    const response = await UserService.addUserToEvent(
      payload, sessionToken?.rawValue,
    );
    return response;
  }, [sessionToken?.rawValue]);

  const generateInviteCodes = useCallbackWithLoading(async (
    payload: { codeNumber: number, eventId: string},
  ) => {
    const response = await UserService.generateInviteCodes(
      payload, sessionToken?.rawValue,
    );
    return response;
  }, [sessionToken?.rawValue]);

  const deleteUserOrAdmin = useCallbackWithLoading(async (userId: string) => {
    const response = await UserService.deleteUserOrAdmin(
      userId, sessionToken?.rawValue,
    );
    return response;
  }, [sessionToken?.rawValue]);

  const removeUserFromEvent = useCallbackWithLoading(async (
    payload: { userId: string, eventId: string},
  ) => {
    const response = await UserService.removeUserFromEvent(
      payload, sessionToken?.rawValue,
    );
    return response;
  }, [sessionToken?.rawValue]);

  const updateUserProfileSettings = useCallbackWithLoading(async (
    payload: { userFullName?: string, hasPublicProfile?: boolean },
  ) => {
    const response = await UserService.updateUserProfileSettings(
      payload, sessionToken?.rawValue,
    );
    return response;
  }, [sessionToken?.rawValue]);

  return {
    updatePaymentSettings,
    getUserInfo,
    updateUserPassword,
    createEventEnrollmentRequest,
    getAllUsers,
    getAllUserEnrollmentRequests,
    editUserEnrollmentRequest,
    deleteUserEnrollmentRequest,
    addUserToEvent,
    generateInviteCodes,
    deleteUserOrAdmin,
    removeUserFromEvent,
    updateUserProfileSettings,
  };
};
