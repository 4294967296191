/* eslint-disable quotes */
import LoginFormSection from './LoginFormSection';
import EventSection from './EventsSection';
import FooterSection from './FooterSection';
import cn from 'classnames';
import CourseDetailSection from './CourseDetailSection';

const LoginComponents = () => (
  <div className="font-Manrope">
    <div className="flex min-h-[700px] justify-center relative font-Manrope">
      <LoginFormSection />
    </div>
    <div
      className="font-Manrope flex flex-col min-h-[956px] w-full
       bg-[#F7F9FC] snap-center relative px-[15vw] pt-[5vh]"
    >
      <EventSection />
    </div>
    <div
      className={cn(
        'font-Manrope flex flex-col min-h-[838px] w-full px-[15vw] pt-[5vh]',
        'bg-cover bg-center snap-center relative',
        `bg-[url(assets/images/loginCourseBgImage.svg)]`,
      )}
    >
      <CourseDetailSection />
    </div>
    <div className="w-full h-auto bg-[#EFFAFE] pt-12 pb-5 px-[15vw]">
      <FooterSection />
    </div>
  </div>
);

export default LoginComponents;
