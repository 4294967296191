// @ts-nocheck
/* eslint-disable */
import { useForm, useWatch } from 'react-hook-form';
import TextField from '../../../components/TextField';
import SelectBox from '../../../components/SelectBox';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '../../../components/Button';
import { useUser } from '../../../hooks/useUser';
import { useAsync, useAsyncRetry } from 'react-use';
import { notification } from 'antd';
import { useEffect } from 'react';

const shirtSizeOptions = ['S', 'M', 'L', 'XL', 'XXL', 'XXXL'].map(value => ({
  label: value,
  value
}));

const PaymentSettings = () => {
  const paymentFormSchema = yup.object({
    paymentEmail: yup.string().required('Payment Email is required field').nullable(),
    fullAddress: yup.string().required('Full Address is required field').nullable(),
    panNumber: yup.string().required('PAN Number is required field').nullable(),
    password: yup.string().required('Password is required field').nullable(),
    shirtSize: yup.object().required('T-ShirtSize is required field'),
  });

  const { getUserInfo, updatePaymentSettings } = useUser();

  const { value: user, retry } = useAsyncRetry(() => getUserInfo(), []);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(paymentFormSchema),
    defaultValues: {
      paymentEmail: user?.paymentEmail,
      fullAddress: user?.fullAddress,
      panNumber: user?.panNumber,
      password: user?.password,
      shirtSize: user?.shirtSize,
    }
  });

  useEffect(() => {
    if (user) {
      Object.keys(user).forEach((key) => {
        if (key === 'shirtSize') {
          setValue(key, { label: user[key], value: user[key]});
        } else {
          setValue(key, user[key]);
        }
      });
    }
  }, [user])

  const onSubmit = async (data) => {
    try {
      await updatePaymentSettings({ ...data, shirtSize: data.shirtSize.value });
      notification.success({
        message: "Successfully updated payment settings",
        duration: 3,
      });
      retry();
    } catch (error) {
      notification.error({
        message: error?.message || 'Failed to save payment settings, try again',
        duration: 3,
      });
    }
  };

  return (
    <div className="w-full h-full rounded-lg flex-col justify-start items-start gap-6 inline-flex z-10">
      <div className="flex-col justify-start items-start gap-4 flex">
        <div className="w-full opacity-80 text-black text-xl text-left font-bold font-['Manrope'] tracking-tight">Payment Settings</div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="h-auto w-[560px] flex-col justify-start items-start gap-8 flex">
          <TextField
            name="paymentEmail"
            type="text"
            label="Payment Email (PayPal)"
            control={control}
            errors={errors}
            isrequired
            customHeight="56px"
            customlabelclass={`!text-zinc-700 !text-base !font-medium !font-['Manrope'] !leading-normal !pb-2`}
          />
          <TextField
            name="fullAddress"
            type="text"
            label="Full Address"
            control={control}
            errors={errors}
            isrequired
            customHeight="56px"
            customlabelclass={`!text-zinc-700 !text-base !font-medium !font-['Manrope'] !leading-normal !pb-2`}
          />
          <TextField
            name="panNumber"
            type="text"
            label="PAN Number"
            control={control}
            errors={errors}
            isrequired
            customHeight="56px"
            customlabelclass={`!text-zinc-700 !text-base !font-medium !font-['Manrope'] !leading-normal !pb-2`}
          />
          <SelectBox
            name="shirtSize"
            control={control}
            label="T-Shirt Size"
            isClearable
            options={shirtSizeOptions}
            errors={errors}
            customHeight="56px"
            isrequired
            customlabelclass={`!text-zinc-700 !text-base !font-medium !font-['Manrope'] !leading-normal !pb-2`}
          />
          <TextField
            name="password"
            type="password"
            label="password"
            control={control}
            errors={errors}
            isrequired
            isTogglepassword
            customHeight="56px"
            customlabelclass={`!text-zinc-700 !text-base !font-medium !font-['Manrope'] !leading-normal !pb-2`}
          />
        </div>
        <div className='flex justify-start items-center pt-8'>
          <Button
            type='submit'
            variant='primary'
          >
            <div className="text-white text-base font-bold font-['Manrope']">
              Save
            </div>
          </Button>
        </div>
      </form>
  </div>
  )
}

export default PaymentSettings;