// @ts-nocheck
import { useState } from 'react';
import participantsIcon from '../../../assets/icons/dashboardScreen/participantsIcon.svg';
import adminIcon from '../../../assets/icons/adminScreen/admin_icon.svg';
import unSelectedParticipantsIcon from '../../../assets/icons/adminScreen/unSelectedParticipantsIcon.svg';
import unSelectedAdminIcon from '../../../assets/icons/adminScreen/unSelectedAdminIcon.svg';
import cn from 'classnames';
import UserComponent from './Users';
import AdminComponent from './Admin';

const UserAndAdmin = () => {
  const [selectedTab, setSelectedTab] = useState('User');
  return (
    <div className="w-full h-auto flex-col justify-start items-start gap-8 inline-flex p-8">
      <div className="justify-start items-start gap-4 inline-flex">
        <div
          className={cn('p-3 rounded-[32px] justify-start items-start gap-2 flex cursor-pointer',
            selectedTab === 'User' && 'bg-gradient-to-b from-blue-200 to-indigo-300',
          )}
          onClick={() => setSelectedTab('User')}
        >
          <div className="w-5 h-5 relative">
            <img
              src={selectedTab === 'User' ? participantsIcon : unSelectedParticipantsIcon}
              alt="icon"
            />
          </div>
          <div
            className={cn("text-base font-semibold font-['Manrope']",
              selectedTab === 'User' ? 'text-indigo-600' : 'text-slate-400')}
          >
            Users
          </div>
        </div>
        <div
          className={cn('p-3 rounded-[32px] justify-start items-start gap-2 flex cursor-pointer',
            selectedTab === 'Admin' && 'bg-gradient-to-b from-blue-200 to-indigo-300',
          )}
          onClick={() => setSelectedTab('Admin')}
        >
          <div className="w-5 h-5 relative">
            <img src={selectedTab === 'Admin' ? adminIcon : unSelectedAdminIcon} alt="icon" />
          </div>
          <div
            className={cn("text-base font-semibold font-['Manrope']",
              selectedTab === 'Admin' ? 'text-indigo-600' : 'text-slate-400')}
          >
            Admin
          </div>
        </div>
      </div>
      {selectedTab === 'User' ? (
        <UserComponent />
      ) : (
        <AdminComponent />
      )}
    </div>
  );
};

export default UserAndAdmin;
