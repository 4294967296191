import { isAxiosError } from 'axios';
import HttpService from '../HttpService';
import { ICreateAchievementRequest, IEditAchievementRequest, IGetAllAchievementsResponse, IUserWihAchievements } from './types';

const getAllAchievements = async (
  queryParams?: {page: number, limit: number, eventId?: string, userId?: string},
  sessionToken?: string,
) => {
  try {
    const { data } = await HttpService.get<IGetAllAchievementsResponse>(
      'achievement',
      sessionToken,
      queryParams,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

const createAchievement = async (
  payload: ICreateAchievementRequest,
  sessionToken?: string,
) => {
  try {
    const { data } = await HttpService.post<{message: string}>(
      'achievement/save',
      payload,
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

const editAchievement = async (
  payload: IEditAchievementRequest,
  sessionToken?: string,
) => {
  try {
    const { data } = await HttpService.patch<{message: string}>(
      'achievement/edit',
      payload,
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

const deleteAchievement = async (
  id: string,
  sessionToken?: string,
) => {
  try {
    const { data } = await HttpService.delete<{message: string}>(
      'achievement/delete',
      sessionToken,
      { id },
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

const getUserWithAchievements = async (
  userId: string,
  sessionToken?: string,
) => {
  try {
    const { data } = await HttpService.get<IUserWihAchievements>(
      `achievement/user-with-achievements/${userId}`,
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

const AchievementService = {
  getAllAchievements,
  createAchievement,
  editAchievement,
  deleteAchievement,
  getUserWithAchievements,
};

export default AchievementService;
