// @ts-nocheck
import React, { useState } from 'react';
import ReactSelect, { components } from 'react-select';
import Creatable from 'react-select/creatable';
import { Controller } from 'react-hook-form';
import cn from 'classnames';

const { ValueContainer, Placeholder } = components;

function CustomValueContainer({ children, ...data }) {
  return (
    <ValueContainer {...data}>
      <Placeholder {...data} isFocused={data.isFocused}>
        {data.selectProps?.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
    </ValueContainer>
  );
}

function SelectBox({
  id,
  name,
  control,
  label,
  isMulti,
  options,
  rules,
  errors,
  customClass,
  isClearable,
  customWidth,
  customHeight,
  customLabel,
  customValue,
  defaultInputValue,
  placeholder,
  onChange,
  noBorder,
  customlabelclass,
  isrequired,
  labelInside,
  modifyCompleteStyle,
  isCreatable,
  formikSetValue,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [optionList, setOptionList] = useState(options);
  const [value, setValue] = useState(isCreatable ? options: null);
  const [inputValue, setInputValue] = useState(''); // Track input value for creating options

  const customStyles = {
    menu: (defaultStyles) => ({
      ...defaultStyles,
      textAlign: 'start',
      zIndex: '20',
    }),
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: (modifyCompleteStyle && '#ffffff') || (state.isSelected && '#ffffff'),
      backgroundColor: modifyCompleteStyle
        ? (state.isSelected ? '#000059' : '#24249C') : (state.isSelected ? '#24249C' : 'white'),
    }),
    control: (defaultStyles) => ({
      ...defaultStyles,
      textAlign: 'start',
      width: customWidth || '100%',
      height: customHeight || '52px',
      borderRadius: !modifyCompleteStyle && '8px',
      border: !noBorder && '2px solid #0000001A',
      color: 'white',
      fontSize: '16px',
      backgroundColor: modifyCompleteStyle ? '#24249C' : 'white',
      borderColor: modifyCompleteStyle ? '#24249C' : '#6b7b87',
    }),
    valueContainer: (defaultStyles) => ({
      ...defaultStyles,
      height: customHeight ? `calc(${customHeight} - 2px)` : '50px',
      overflow: 'auto',
      backgroundColor: modifyCompleteStyle && '#24249C',
      left: '5px',
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      alignText: 'center',
      fontSize: 16,
      color: modifyCompleteStyle ? 'white' : '#000000',
      position: 'relative',
      top: label && labelInside ? '10%' : '',
    }),
    multiValue: (defaultStyles) => ({
      ...defaultStyles,
      fontSize: 16,
      color: modifyCompleteStyle ? 'white' : '#000000',
      backgroundColor: modifyCompleteStyle ? '#24249C' : '#6f81a55e',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      display: (state.hasValue || state.selectProps.inputValue) && 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      color: modifyCompleteStyle && 'white',
      backgroundColor: modifyCompleteStyle && '#24249C',
    }),
    input: (provided) => ({
      ...provided,
      color: modifyCompleteStyle ? 'white' : 'black',
    }),
  };

  const handleCreate = (inputValue) => {
    setIsLoading(true);
    setTimeout(() => {
      const label = customLabel || 'label';
      const value = customValue || 'value';
      const newOption = {
        [label]: inputValue,
        [value]: inputValue,
      };
      setIsLoading(false);
      setInputValue('');
      setOptionList((prev) => [...prev, newOption]);
      setValue([...optionList, newOption]);
      formikSetValue(name, [...optionList, newOption])
      if (onChange) onChange(newOption);
    }, 500);
  };

  const SelectComponent = isCreatable ? Creatable : ReactSelect;

  return (
    <div className="w-full">
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <>
            <label
              className={cn(customlabelclass)}
              style={{
                lineHeight: 1,
                display: 'flex',
                justifyContent: 'start',
                color: modifyCompleteStyle && '#6f81a5',
                paddingLeft: '5px',
              }}
            >
              {label}
              {isrequired
                && <span className="text-rose-500 text-base font-medium font-['Manrope'] leading-normal">*</span>}
            </label>
            <SelectComponent
              {...field}
              id={id || name}
              isMulti={isMulti}
              defaultInputValue={defaultInputValue}
              options={isCreatable ? optionList : options}
              className={customClass}
              placeholder={placeholder || ''}
              styles={customStyles}
              isClearable={isClearable}
              isDisabled={isLoading}
              isLoading={isLoading}
              onChange={(newValue) => { field.onChange(newValue); if (onChange) onChange(newValue); setValue(newValue); }}
              onCreateOption={isCreatable ? handleCreate : undefined}
              inputValue={inputValue}
              onInputChange={setInputValue} 
              getOptionLabel={(option) => (customLabel ? option[customLabel] || option.label : option.label)}
              getOptionValue={(option) => (customValue ? option[customValue] : option.value)}
              value={isCreatable ? value : field?.value}
              components={{
                ValueContainer: CustomValueContainer,
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: 'gray',
                },
              })}
            />
          </>
        )}
      />
      {errors && errors[name]?.message && (
        <p className="text-danger text-left p-[5px]" style={{ color: 'red' }}>{errors[name].message}</p>
      )}
    </div>
  );
}

export default SelectBox;
