// @ts-nocheck
import ModalPopup from '../../../../../components/Modal';
import closeIcon from '../../../../../assets/icons/closeIcon.svg';
import { useForm } from 'react-hook-form';
import SelectBox from '../../../../../components/SelectBox';
import Button from '../../../../../components/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import deleteIcon from '../../../../../assets/icons/adminScreen/deleteIcon.svg';
import { useState, useEffect } from 'react';
import { useUser } from '../../../../../hooks/useUser';
import { useEvent } from '../../../../../hooks/useEvent';
import { useAsync } from 'react-use';
import { notification } from 'antd';
import arrowDownIcon from '../../../../../assets/icons/adminScreen/arrowDownIcon.svg';
import cn from 'classnames';
import { useChallenge } from '../../../../../hooks/useChallenge';
import { useSocketNotification } from '../../../../../contexts/NotificationContext';

const ViewUserDetails = (props) => {
  const {
    setViewUser,
    viewUser,
    getUsers,
  } = props;

  const { addUserToEvent, removeUserFromEvent } = useUser();
  const { resetChallenge } = useChallenge();
  const { getAllEvents } = useEvent();
  const [user, setUser] = useState(viewUser);
  const [userEvents, setUserEvents] = useState();
  const [selectedEventToRemove, setSelectedEventToRemove] = useState();
  const [selectedChallengesToReset, setSelectedChallengesToReset] = useState(null);
  const { socket } = useSocketNotification();

  useEffect(() => {
    if (user?.events) {
      const eventDetails = user?.events?.map((item) => (
        {
          ...item,
          showChallenge: false,
        }
      ));
      setUserEvents(eventDetails);
    }
  }, [user]);

  const { value: allEvents } = useAsync(() => getAllEvents(), []);

  const schema = yup.object({
    selectEvent: yup.object().required('Event Selection is required').nullable(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const response = await addUserToEvent({ eventId: data.selectEvent._id, userId: viewUser._id });
      notification.success({ message: 'Successfully added user to event' });
      setUser(response.user);
      getUsers();
      // send notification
      socket.emit('send-notification', {
        to: response.user._id,
        msg: response.notify,
      });
    } catch (error) {
      notification.error({ message: error?.message || ' Failed to add user to event' });
    }
  };

  const onResetChallenge = async (data) => {
    try {
      const response = await resetChallenge({
        eventId: selectedChallengesToReset?.event?._id,
        userId: viewUser?._id,
        challengeId: selectedChallengesToReset?.challenge?._id,
      });
      notification.success({ message: response?.message || 'Successfully reset user challenge' });
      setSelectedChallengesToReset(null);
      // send notification
    } catch (error) {
      notification.error({ message: error?.message || ' Failed to reset user challenge' });
    }
  };

  const removeFromEvent = async (data) => {
    try {
      const response = await removeUserFromEvent({ eventId: data._id, userId: viewUser._id });
      notification.success({ message: 'Successfully removed user from event' });
      setUser(response.user);
      setSelectedEventToRemove(null);
      getUsers();
      // send notification
      socket.emit('send-notification', {
        to: response.user._id,
        msg: response.notify,
      });
    } catch (error) {
      notification.error({ message: error?.message || ' Failed to removed user from event' });
    }
  };

  const handleUpdate = (index) => {
    const tempListData = userEvents?.map((item, i) =>
      (index !== i ? { ...item, showChallenge: false } : { ...item, showChallenge: !(item.showChallenge) }));
    setUserEvents(tempListData);
  };

  const handleUserEventChallenges = (data) => (
    <>
      <div className="text-black text-md font-semibold pl-3 leading-6">Challenges</div>
      <hr />
      {data?.challenges?.length > 0 ? (
        <>
          {data?.challenges?.map((item, index) => (
            <div
              key={index}
              className="even:bg-gray-50 odd:bg-white grid grid-cols-12 h-[48px] gap-2
                  justify-center items-center py-3 px-2 w-full"
            >
              <div
                className="col-span-9 w-full opacity-80 text-black text-sm font-normal
                font-['Manrope'] tracking-tight"
              >
                {item?.title}
              </div>
              <div
                className="col-span-3 justify-end items-center gap-4 flex cursor-pointer"
                onClick={() => setSelectedChallengesToReset({ challenge: item, event: { _id: data?._id } })}
              >
                <div className="justify-start items-center gap-1.5 flex">
                  <div className="text-sky-600 text-sm font-medium font-['Manrope'] leading-normal">Reset</div>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="w-full flex justify-start items-center p-3 font-['Manrope']">
          No Challenges Found
        </div>
      )}
    </>
  );

  return (
    <ModalPopup>
      <div
        className="w-full h-auto p-8 bg-white rounded-lg shadow flex-col
          justify-start items-start gap-6 inline-flex"
      >
        <div className="w-6 h-6 absolute right-2 top-2 cursor-pointer" onClick={() => setViewUser(null)}>
          <img src={closeIcon} alt="icon" />
        </div>
        <div
          className="text-black text-xl font-bold font-['Manrope'] tracking-tight"
        >
          {viewUser?.userName}
        </div>
        <div className="self-stretch text-zinc-700 text-sm font-normal text-left font-['Manrope'] leading-normal">
          Email ID:
          {' '}
          {viewUser?.email}
          <br />
          Payment Email:
          {' '}
          {viewUser?.paymentEmail || 'N/A'}
          <br />
          PAN Number:
          {' '}
          {viewUser?.panNumber || 'N/A'}
          <br />
          Full Address:
          {' '}
          {viewUser?.fullAddress || 'N/A'}
          <br />
          T-Shirt Size:
          {' '}
          {viewUser?.shirtSize || 'N/A'}
        </div>
        <div className="text-left self-stretch h-[108px] flex-col justify-start items-start flex">
          <div className="self-stretch p-2 bg-cyan-100 justify-start items-start gap-2.5 inline-flex">
            <div
              className="grow shrink basis-0 text-zinc-700 text-base font-semibold font-['Manrope']"
            >
              Add User To An Event
            </div>
          </div>
          <div className="self-stretch py-3 justify-start items-center inline-flex">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="w-full">
              <div className="grid grid-cols-12 gap-2 w-full">
                <div className="col-span-8">
                  <SelectBox
                    name="selectEvent"
                    placeholder="Select Event"
                    options={allEvents?.events || []}
                    control={control}
                    errors={errors}
                    customLabel="name"
                    customValue="name"
                    customHeight="40px"
                    isClearable
                    customlabelclass="text-zinc-700 !text-sm !font-normal font-['Manrope'] !pb-2"
                  />
                </div>
                <div className="col-span-4">
                  <Button
                    customClass=""
                    variant="primary"
                    type="submit"
                  >
                    <div className="text-white text-base font-bold font-['Manrope']">
                      Add User
                    </div>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="text-left self-stretch h-auto flex-col justify-start items-start flex">
          <div className="self-stretch p-2 bg-cyan-100 justify-start items-start gap-2.5 inline-flex">
            <div
              className="grow shrink basis-0 text-zinc-700 text-base font-semibold font-['Manrope']"
            >
              User Assigned Events
            </div>
          </div>
          <div className="self-stretch h-auto flex-col justify-start items-start flex max-h-[60vh] overflow-scroll">
            {userEvents?.length > 0
              ? userEvents?.map((item, index) => (
                <>
                  <div
                    key={index}
                    onClick={() => handleUpdate(index)}
                    className={cn('grid grid-cols-12 min-h-[48px] gap-2',
                      'justify-center items-center py-3 px-2 w-full cursor-pointer',
                      index % 2 ? 'bg-purple-50' : 'bg-white',
                    )}
                  >
                    <div
                      className="col-span-9 w-full opacity-80 text-black text-sm font-normal
                        font-['Manrope'] tracking-tight"
                    >
                      <div className="grid grid-cols-12 w-full gap-2">
                        <span className="col-span-1">
                          <img src={arrowDownIcon} alt="icon" className={cn(item?.showChallenge && 'rotate-180')} />
                        </span>
                        <span className="col-span-11">
                          {item?.name}
                        </span>
                      </div>
                    </div>
                    <div
                      className="col-span-3 justify-end items-center gap-4 flex cursor-pointer"
                      onClick={() => setSelectedEventToRemove(item)}
                    >
                      <div className="justify-start items-center gap-1.5 flex">
                        <div className="w-4 h-4 relative">
                          <img src={deleteIcon} alt="icon" />
                        </div>
                        <div className="text-sky-600 text-sm font-medium font-['Manrope'] leading-normal">Remove</div>
                      </div>
                    </div>
                  </div>
                  {item?.showChallenge && (
                    <div
                      className="w-full h-auto flex flex-col justify-start items-start"
                    >
                      {handleUserEventChallenges(item)}
                    </div>
                  )}
                </>
              ))
              : (
                <div className="w-full flex justify-start items-center py-3 px-2 font-['Manrope']">
                  No Event Assigned Till Now
                </div>
              )}
          </div>
        </div>
      </div>
      {selectedEventToRemove && (
      <ModalPopup>
        <div
          className="w-full h-auto p-8 bg-white rounded-lg shadow flex-col
            justify-start items-start gap-6 inline-flex"
        >
          <div className="w-6 h-6 absolute right-2 top-2 cursor-pointer" onClick={() => setSelectedEventToRemove(null)}>
            <img src={closeIcon} alt="icon" />
          </div>
          <div
            className="text-black text-xl font-bold font-['Manrope'] tracking-tight"
          >
            Are you sure you want to remove event from user?
          </div>
          <div className="justify-start items-start gap-6 inline-flex">
            <Button onClick={() => removeFromEvent(selectedEventToRemove)}>
              <div className="text-white text-base font-bold font-['Manrope']">Remove</div>
            </Button>
            <Button
              variant="primary-outline"
              onClick={() => { setSelectedEventToRemove(null); }}
            >
              <div className="text-indigo-600 text-base font-bold font-['Manrope']">Cancel</div>
            </Button>
          </div>
        </div>
      </ModalPopup>
      )}
      {selectedChallengesToReset && (
      <ModalPopup>
        <div
          className="w-full h-auto p-8 bg-white rounded-lg shadow flex-col
            justify-start items-start gap-6 inline-flex"
        >
          <div
            className="w-6 h-6 absolute right-2 top-2 cursor-pointer"
            onClick={() => setSelectedChallengesToReset(null)}
          >
            <img src={closeIcon} alt="icon" />
          </div>
          <div
            className="text-black text-xl font-bold font-['Manrope'] tracking-tight"
          >
            Are you sure to reset challenge?
          </div>
          <div className="justify-start items-start gap-6 inline-flex">
            <Button onClick={() => onResetChallenge(selectedChallengesToReset)}>
              <div className="text-white text-base font-bold font-['Manrope']">Reset</div>
            </Button>
            <Button
              variant="primary-outline"
              onClick={() => { setSelectedChallengesToReset(null); }}
            >
              <div className="text-indigo-600 text-base font-bold font-['Manrope']">Cancel</div>
            </Button>
          </div>
        </div>
      </ModalPopup>
      )}
    </ModalPopup>
  );
};

export default ViewUserDetails;
