// @ts-nocheck
import ModalPopup from '../../../../components/Modal';
import closeIcon from '../../../../assets/icons/closeIcon.svg';
import Button from '../../../../components/Button';

const MarkAsDonePopup = (props) => {
  const { markedData, setMarkedData, onConfirm } = props;

  return (
    <ModalPopup>
      <div
        className="w-full h-auto p-8 bg-white rounded-lg shadow flex-col
          justify-start items-start gap-6 inline-flex"
      >
        <div className="w-6 h-6 absolute right-2 top-2 cursor-pointer" onClick={() => setMarkedData(null)}>
          <img src={closeIcon} alt="icon" />
        </div>
        <div
          className="text-black text-xl font-bold font-['Manrope'] tracking-tight"
        >
          {`Are you sure to mark as ${markedData?.status === 'pending' ? 'done' : 'undone'}?`}
        </div>
        <div className="justify-start items-start gap-6 inline-flex">
          <Button onClick={() => onConfirm()}>
            <div className="text-white text-base font-bold font-['Manrope']">Yes</div>
          </Button>
          <Button
            variant="primary-outline"
            onClick={() => { setMarkedData(null); }}
          >
            <div className="text-indigo-600 text-base font-bold font-['Manrope']">Cancel</div>
          </Button>
        </div>
      </div>
    </ModalPopup>
  );
};

export default MarkAsDonePopup;
