import HttpService from '../HttpService';
import { isAxiosError } from 'axios';
import { HttpStatus } from '../HttpService/HttpStatus';
import {
  IUserEventEnrollmentRequest, IUser, IUserChangePasswordRequest,
  UserPaymentSettingsRequest, GetAllUserResponse, IAllUserEnrollmentsResponse,
  EditUserEnrollmentRequest, IAddRemoveEventOnUserResponse,
} from './types';

const updatePaymentSettings = async (payload: UserPaymentSettingsRequest, sessionToken?: string): Promise<IUser> => {
  try {
    const {
      data: user,
    } = await HttpService.post<IUser>(
      'user/update-payment-settings',
      payload,
      sessionToken,
    );
    return user;
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === HttpStatus.BAD_REQUEST) {
      throw error.response.data.message;
    }
    throw error;
  }
};

const getUserInfo = async (sessionToken?: string) => {
  try {
    const { data: user } = await HttpService.get<IUser>(
      'user/get-user-info',
      sessionToken,
    );
    return user;
  } catch (error) {
    throw error;
  }
};

const updatePassword = async (payload: IUserChangePasswordRequest, sessionToken?: string): Promise<IUser> => {
  try {
    const {
      data: user,
    } = await HttpService.put<IUser>(
      'user/update',
      payload,
      sessionToken,
    );
    return user;
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === HttpStatus.BAD_REQUEST) {
      throw error.response.data;
    }
    throw error;
  }
};

const createEventEnrollmentRequest = async (payload: IUserEventEnrollmentRequest, sessionToken?: string):
  Promise<{message: string}> => {
  try {
    const {
      data,
    } = await HttpService.post<{message: string}>(
      'user-request/create',
      payload,
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === HttpStatus.BAD_REQUEST) {
      throw error.response.data;
    }
    throw error;
  }
};

const getAllUsers = async (
  queryParams?: {
    page: number, limit: number, userName?: string,
    userEmail?: string, eventId?: string,
  },
  sessionToken?: string) => {
  try {
    const { data } = await HttpService.get<GetAllUserResponse>(
      'user/get-all-users',
      sessionToken,
      queryParams,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error?.response?.data;
    }
    throw error;
  }
};

const getAllUserEnrollmentRequests = async (
  queryParams?: {
    page: number, limit: number, searchTerm?: string,
  },
  sessionToken?: string) => {
  try {
    const { data } = await HttpService.get<IAllUserEnrollmentsResponse>(
      'user-request',
      sessionToken,
      queryParams,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error?.response?.data;
    }
    throw error;
  }
};

const editUserEnrollmentRequest = async (payload: EditUserEnrollmentRequest, sessionToken?: string) => {
  try {
    const { data } = await HttpService.patch<{message: string}>(
      'user-request/edit',
      payload,
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error?.response?.data;
    }
    throw error;
  }
};

const deleteUserEnrollmentRequest = async (enrollmentId: string, sessionToken?: string) => {
  try {
    const { data } = await HttpService.post<{message: string}>(
      'user-request/delete',
      { id: enrollmentId },
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error?.response?.data;
    }
    throw error;
  }
};

const addUserToEvent = async (payload: { userId: string, eventId: string}, sessionToken?: string) => {
  try {
    const { data, status } = await HttpService.post<IAddRemoveEventOnUserResponse>(
      'user/addEvent',
      payload,
      sessionToken,
    );

    if (status === HttpStatus.OK) {
      throw Error('User is already in the specified event');
    }
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error?.response?.data;
    }
    throw error;
  }
};

const generateInviteCodes = async (payload: { codeNumber: number, eventId: string}, sessionToken?: string) => {
  try {
    const { data } = await HttpService.post<string>(
      'user/generate-code',
      payload,
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

const deleteUserOrAdmin = async (userId: string, sessionToken?: string) => {
  try {
    const { data } = await HttpService.post<{message: string}>(
      'user/delete',
      { id: userId },
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

const removeUserFromEvent = async (payload: { userId: string, eventId: string}, sessionToken?: string) => {
  try {
    const { data } = await HttpService.post<IAddRemoveEventOnUserResponse>(
      'user/remove-event',
      payload,
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error?.response?.data;
    }
    throw error;
  }
};

const updateUserProfileSettings = async (payload: { userFullName?: string, hasPublicProfile?: boolean }, sessionToken?: string) => {
  try {
    const { data } = await HttpService.put<{ message: string }>(
      'user//update-user-profile-settings',
      payload,
      sessionToken,
    );
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error?.response?.data;
    }
    throw error;
  }
};

const UserService = {
  updatePaymentSettings,
  getUserInfo,
  updatePassword,
  createEventEnrollmentRequest,
  getAllUsers,
  getAllUserEnrollmentRequests,
  editUserEnrollmentRequest,
  deleteUserEnrollmentRequest,
  addUserToEvent,
  generateInviteCodes,
  deleteUserOrAdmin,
  removeUserFromEvent,
  updateUserProfileSettings,
};

export default UserService;
