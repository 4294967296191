/* eslint-disable max-len */
// @ts-nocheck
export const courseList = [
  {
    id: '62fcc9d8e809e9000d7defe21',
    name: 'Certified Mobile AppSec Expert',
    link: 'https://training.enciphers.com/p/mobile-application-security-android-ios',
    metaData:
      'Learn iOS & Android Application Security & Get Certified. Get access to mobile application security courses, i.e. iOS & Android application security in one bundle, together with the certification exam voucher.',
    eventType: [
      {
        name: 'Get Certified',
        type: 'Get Certified',
      },
      {
        name: 'iOS & Android',
        type: 'iOS & Android',
      },
      {
        name: 'Real World Labs',
        type: 'Real World Labs',
      },
    ],
  },
  {
    id: '62fcc9d8e809e9000d7defe21',
    name: 'iOS Reverse Engineering Guru',
    link: 'https://training.enciphers.com/p/ios-reverse-engineering-guru',
    metaData:
      'Get the most sought after skills in mobile security: iOS application penetration testing and reverse engineering. Become an iOS application security guru.',
    eventType: [
      {
        name: 'iOS Appsec',
        type: 'iOS Appsec',
      },
      {
        name: 'Reverse Engg',
        type: 'Reverse Engg',
      },
      {
        name: 'Amazing Labs',
        type: 'Amazing Labs',
      },
    ],
  },
  {
    id: '62fcc9d8e809e9000d7defe21',
    name: 'Become An AppSec Master',
    link: 'https://training.enciphers.com/p/all-access',
    metaData:
      'A great set of mobile application security & reverse engineering training courses, with certification exam attempt. Start your journey into application security now.',
    eventType: [
      {
        name: 'Android Appsec',
        type: 'Android Appsec',
      },
      {
        name: 'iOS Appsec',
        type: 'iOS Appsec',
      },
      {
        name: 'Reverse Engg',
        type: 'Reverse Engg',
      },
    ],
  },
  {
    id: '62fcc9d8e809e9000d7defe21',
    name: 'iOS Application Security',
    link: 'https://training.enciphers.com/p/ios-application-security',
    metaData:
      'A detailed course on penetration testing of iOS applications. Best for iOS application security enthusiasts, developers and penetration testers.',
    eventType: [
      {
        name: 'Real World Labs',
        type: 'Real World Labs',
      },
      {
        name: 'Awesome Labs',
        type: 'Awesome Labs',
      },
      {
        name: 'Updated Content',
        type: 'Updated Content',
      },
    ],
  },
  {
    id: '62fcc9d8e809e9000d7defe21',
    name: 'Android Application Security',
    link: 'https://training.enciphers.com/p/android-application-security',
    metaData:
      'Learn penetration testing of Android applications, through this hands-on training. Become Android application security expert.',
    eventType: [
      {
        name: 'Advance Content',
        type: 'Advance Content',
      },
      {
        name: 'Chat Platform',
        type: 'Chat Platform',
      },
      {
        name: 'Updated Content',
        type: 'Updated Content',
      },
    ],
  },
];
